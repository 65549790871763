var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showPosturasByGenderChicks.length)?_c('div',{staticStyle:{"height":"calc(90vh - 200px)","overflow-y":"auto","padding":"10px"}},_vm._l((_vm.showPosturasByGenderChicks),function(item,index){return _c('b-card',{key:("madrilla-" + index),staticStyle:{"padding":"10px"},attrs:{"body-class":"p-0"}},[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',{staticClass:"text-center"},[_c('b-th',[_vm._v("Postura")]),_c('b-th',[_vm._v(_vm._s(_vm.partner))]),_c('b-th',[_vm._v("Cintillo")]),_c('b-th',[_vm._v("Cantidad")])],1)],1),_c('b-tbody',[_c('b-tr',{staticClass:"text-center"},[_c('b-td',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.p_code))])]),_c('b-td',[(item.padrillo)?_c('SpecimenPlate',{attrs:{"specimen":item.padrillo}}):_vm._e(),(item.madrilla)?_c('SpecimenPlate',{attrs:{"specimen":item.madrilla}}):_vm._e()],1),_c('b-td',[_c('div',{staticClass:"d-flex align-items-center justify-content-center pl-50"},[_c('div',{staticClass:"d-flex flex-nowrap"},[(item.cintillos)?_c('CombChickenlegsCintillos',{attrs:{"postura":{
											chickenleg_wich_leg: item.cintillos.wich_leg,
											chickenleg_qty_cintillos: item.cintillos.qty_cintillos,
											cintillo_color: item.cintillos.hex_1,
											cintillo_description: item.cintillos.description_1,
											second_color_color: item.cintillos.hex_2,
											second_color_description: item.cintillos.description_2,
											total_minus_infertile_eggs: 1,
										},"chickenleg_wich_leg":1,"is_in_posturas":""}}):_vm._e(),(item.cintillos)?_c('CombChickenlegsCintillos',{attrs:{"postura":{
											chickenleg_wich_leg: item.cintillos.wich_leg,
											chickenleg_qty_cintillos: item.cintillos.qty_cintillos,
											cintillo_color: item.cintillos.hex_1,
											cintillo_description: item.cintillos.description_1,
											second_color_color: item.cintillos.hex_2,
											second_color_description: item.cintillos.description_2,
											total_minus_infertile_eggs: 1,
										},"is_in_posturas":"","chickenleg_wich_leg":2}}):_vm._e()],1)])]),(_vm.is_cintillo_pata)?[_c('b-td',[_c('div',{staticClass:"mr-1 font-weight-bolder"},[_vm._v(" "+_vm._s(item.eggs)+" ")])])]:[(_vm.gender == 'todos')?_c('td',[_c('div',{staticClass:"mr-1 font-weight-bolder"},[(item.eggs > 0)?_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(item.eggs)+" ")]):_vm._e(),(item.chicks)?_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openSons(item)}}},[_vm._v(" "+_vm._s(item.chicks.length)+" ")]):_vm._e()],1)]):(item.chicks)?_c('td',[_c('b-button',{staticClass:"btn-sm",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.openSons(item)}}},[_vm._v(" "+_vm._s(item.chicks.length)+" ")])],1):_vm._e()]],2)],1)],1)],1)}),1):_c('div',[_c('h4',{staticClass:"my-2 text-uppercase text-dark text-center"},[_vm._v("Sin hijos")])]),_c('b-modal',{attrs:{"size":"md","title":("" + (_vm.controllerSons.title)),"hide-footer":""},model:{value:(_vm.controllerSons.show),callback:function ($$v) {_vm.$set(_vm.controllerSons, "show", $$v)},expression:"controllerSons.show"}},[_c('h6',{staticClass:"ml-1 text-uppercase text-primary"},[_vm._v("Cintillos en la ala")]),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.controllerSons.chicks),function(chick,index){return _c('b-col',{key:(index + "-chick"),staticClass:"mb-1",attrs:{"cols":"12","md":"4","Con":"","Placa:":""}},[_c('li',{staticClass:"d-flex align-items-center ml-1"},[_c('FeatherIcon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"12","icon":"ChevronsRightIcon"}}),_c('div',{staticClass:"c-correlative"},[_vm._v(_vm._s(chick.correlative))]),(chick.assigned_plate)?_c('div',{staticClass:"c-plate"},[_vm._v(" "+_vm._s(chick.assigned_plate)+" ")]):_vm._e()],1)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div>
		<div class="d-flex justify-content-between align-items-center">
			<b-form-group :label="specimen_id ? null : 'Agregar placa'" class="">
				<b-form-input
					v-show="!specimen_id"
					@keyup.enter="getSpecimens"
					placeholder="Escribir aqui..."
					v-model="specimen_plate"
					trim
					:disabled="specimen_id != null"
				></b-form-input>
			</b-form-group>
			<slot v-if="!select_specimen_id" name="specimen-plate"></slot>
			<SpecimenPlate
				v-else
				:specimen="{
					id: modalSelectSpecimens.specimen_select.id,
					plate: modalSelectSpecimens.specimen_select.plate,
					alias: modalSelectSpecimens.specimen_select.alias,
					thumb: modalSelectSpecimens.specimen_select.thumb,
					url: modalSelectSpecimens.specimen_select.image,
				}"
			/>
		</div>

		<validation-observer ref="formAddToSale" class="">
			<div class="container-information flex-row">
				<h4>Precio</h4>

				<ValidationProvider rules="required" v-slot="{ errors }" class="mb-0 w-50">
					<b-form-group
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
						label="Tipo de moneda"
						class="mb-0"
					>
						<v-select
							v-model="currency_id"
							label="name"
							:options="typeCurrency"
							:reduce="(opt) => opt.id"
							:clearable="false"
							transition=""
							placeholder="Seleccionar"
							class="select-year bg-white"
						/>
					</b-form-group>
				</ValidationProvider>

				<ValidationProvider rules="required" v-slot="{ errors }" class="mb-0 w-50">
					<b-form-group
						class="mb-0"
						:invalid-feedback="errors[0]"
						:state="errors[0] ? false : null"
						label="Monto"
					>
						<b-form-input
							:disabled="!currency_id"
							v-model.lazy="price"
							v-money="money"
							:class="[errors[0] ? 'is-invalid' : '']"
							type="text"
						></b-form-input>
					</b-form-group>
				</ValidationProvider>
			</div>
		</validation-observer>

		<div class="container-information" style="margin-top: 2.1rem">
			<h4>Imagenes</h4>

			<div class="grid-images">
				<AddSpecimenToSaleImage :image="image" v-for="(image, index) in imagesArray" :key="index" />
			</div>
			<p class="mb-0 text-center font-weight-bold" v-if="imagesArray.length == 0">Sin imagenes por mostrar</p>
		</div>

		<div class="container-information">
			<h4>Videos</h4>

			<div class="grid-videos">
				<div class="container-btns">
					<div class="item-btn" :class="[tabIndexVideo == 0 ? 'active' : '']" @click="tabIndexVideo = 0">
						<p class="mb-0">Topes</p>
						<p class="mb-0 badge badge-primary">
							{{ videosArray.filter((it) => it.category == "tope").length }}
						</p>
					</div>
					<div class="item-btn" :class="[tabIndexVideo == 1 ? 'active' : '']" @click="tabIndexVideo = 1">
						<p class="mb-0">Peleas</p>
						<p class="mb-0 badge badge-primary">
							{{ videosArray.filter((it) => it.category == "pelea").length }}
						</p>
					</div>
					<div class="item-btn" :class="[tabIndexVideo == 2 ? 'active' : '']" @click="tabIndexVideo = 2">
						<p class="mb-0">Torneos</p>
						<p class="mb-0 badge badge-primary">
							{{ videosArray.filter((it) => it.category == "torneo").length }}
						</p>
					</div>
				</div>
			</div>

			<div class="grid-images">
				<AddSpecimenToSaleImage
					v-if="image.visible"
					:image="image"
					v-for="(image, index) in videosArray"
					:key="index"
					:index="index"
					@viewSpecimen="viewSpecimen"
				/>
			</div>

			<p class="mb-0 text-center font-weight-bold" v-if="videosArray.filter((it) => it.visible).length == 0">
				Sin videos por mostrar
			</p>

			<p class="mb-0 font-small-1">
				SELECCIONADOS: TOPE({{ videosArray.filter((it) => it.category == "TOPE" && it.check).length }}),
				PELEAS({{ videosArray.filter((it) => it.category == "PELEA" && it.check).length }}) , TORNEOS({{
					videosArray.filter((it) => it.category == "TORNEO" && it.check).length
				}})
			</p>
		</div>

		<div class="d-flex justify-content-between">
			<div></div>
			<button @click="saveToSale" class="btn btn-primary">Guardar</button>
		</div>

		<b-modal hide-footer v-model="modalSelectSpecimens.show" title="Seleccionar ejemplar" no-close-on-backdrop>
			<div class="container-valid-plate">
				<div v-for="(specimen, index) in modalSelectSpecimens.data" class="container-valid-plate__item">
					<div class="skeletor-specimen-add" @click="setSelectSpecimen(specimen)">
						<div class="skeletor"></div>
						<SpecimenPlate
							:specimen="{
								id: specimen.id,
								plate: specimen.plate,
								alias: specimen.alias,
								thumb: specimen.thumb,
								url: specimen.image,
							}"
						/>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { bus } from "@/main"
import { getImagesBySpecimens } from "../../header-bookmarks/service.header-book"
import { getVideosSpecimen } from "../../videolibrary/service.videolibrary"
import AddSpecimenToSaleImage from "./AddSpecimenToSaleImage.vue"
import specimensService from "@/services/specimens.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import publicWebService from "@/services/publicWeb.service"
import { mapActions } from "vuex"
import { VMoney } from "v-money"
// import createNumberMask from "text-mask-addons/dist/createNumberMask";
// const currencyMask = createNumberMask({
// 	prefix: "$",
// 	allowDecimal: true,
// 	includeThousandsSeparator: true,
// 	allowNegative: false,
// });

export default {
	name: "AddSpecimenToSale",
	directives: { money: VMoney },
	components: {
		AddSpecimenToSaleImage,
		SpecimenPlate,
	},
	props: {
		specimen_name: {
			type: String,
		},
		specimen_currency_id: {
			type: Number,
		},
		specimen_price: {
			type: Number,
		},
		specimen_id: {
			type: Number,
		},
	},
	data() {
		return {
			specimen_plate: null,
			typeCurrency: [
				{
					id: 1,
					name: "USD",
				},
				{
					id: 2,
					name: "PEN",
				},
			],
			currency_id: null,
			price: "",
			imagesArray: [],
			videosArray: [],
			tabIndexVideo: 0,	
			modalSelectSpecimens: {
				show: false,
				data: [],
				specimen_select: {},
			},
			select_specimen_id: null,
			money: {
				decimal: ".",
				thousands: "",
				precision: 2,
				masked: false /* doesn't work with directive */,
			},
		}
	},
	async mounted() {
		this.specimen_plate = this.specimen_name
		this.currency_id = this.specimen_currency_id
		// this.price = this.specimen_price;
		this.price = Number(this.specimen_price).toFixed(2)

		this.setVideoteca(true)

		if (this.specimen_id) {
			this.isPreloading()
			await this.getImages()
			await this.getVideos()
			this.isPreloading(false)
		}
	},
	methods: {
		...mapActions("header_book", [
			"setImagesVideoteca",
			"toggleModal",
			"setIndexImage",
			"setTextImage",
			"setUploadImages",
			"deleteUploadFile",
			"toggleBgUpload",
			"initToggleUpload",
			"toggleModalGallery",
			"toggleUploadGallery",
			"setVideoteca",
		]),
		async getImages(id) {
			const { data } = await getImagesBySpecimens({ id: this.specimen_id || id, typeFiles: 0 })
			this.imagesArray = data.map((it) => ({
				id: it.id,
				thumb: it.preload,
				url: it.url,
				type: "image",
				check: it.public == 1 ? true : it.feature || 0 ? true : false,
				feature: it.feature || 0,
			}))
		},
		async getVideos(id) {
			const {
				videos: { data },
			} = await getVideosSpecimen({
				category_id: null,
				search: null,
				perpage: 100,
				page: 1,
				specimen_id: this.specimen_id || id,
				end_date: null,
				fight_result_id: null,
				group_category: null,
				result_id: null,
				rival_id: null,
				start_date: null,
				tournament_id: null,
			})

			console.log("videos", data)

			this.videosArray = data.map((it) => ({
				id: it.video_id,
				thumb: it.video.thumb,
				url: it.video.url,
				type: "image",
				check: it.video.public == 1 ? true : false,
				fr_id: it.fr_id,
				category: it.category,
				visible: true,
				feature: 0,
				duration: it.video.duration,
			}))

			this.setImagesVideoteca(
				data.map((item) => {
					return {
						...item,
						id: item.fr_id,
						id_specimens: item.local.id,
						plate: item.local.plate,
						name: `${item.local.alias} (${item.local.plate})`,
						preload: item.video.thumb,
						type: "video",
						url: item.video.url,
					}
				})
			)

			this.reloadTab()
		},
		reloadTab() {
			switch (this.tabIndexVideo) {
				case 0:
					this.videosArray = this.videosArray.map((vi) => ({ ...vi, visible: vi.category == "tope" }))
					break
				case 1:
					this.videosArray = this.videosArray.map((vi) => ({ ...vi, visible: vi.category == "pelea" }))
					break

				case 2:
					this.videosArray = this.videosArray.map((vi) => ({ ...vi, visible: vi.category == "torneo" }))
					break

				default:
					break
			}
		},
		async saveToSale() {
			if (!(await this.$refs.formAddToSale.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}

			// console.log("object", this.select_specimen_id, this.specimen_id);

			if (!(this.select_specimen_id || this.specimen_id)) {
				return this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Debe seleccionar un ejemplar."
				)
			}
			this.isPreloading()
			const resp = await publicWebService.storeForSale({
				images: this.imagesArray.map((it) => ({ id: it.id, public: it.check })),
				videos: this.videosArray.map((it) => ({ id: it.id, public: it.check })),
				specimen_id: this.specimen_id || this.select_specimen_id,
				currency_id: this.currency_id,
				price: this.price,
			})
			this.isPreloading(false)
			this.showToast(
				"success",
				"top-right",
				"Ejemplares",
				"CheckIcon",
				`Se activo al ejemplar ${this.specimen_plate || ""} para venta`
			)

			bus.$emit("reload-counter-web")
			this.$emit("refresh")
		},
		async getSpecimens() {
			this.imagesArray = []
			this.videosArray = []
			this.isPreloading()
			const { data } = await specimensService.spGetSpecimensToPublic({
				search: this.specimen_plate,
			})

			if (data.length == 0) {
				this.isPreloading(false)
				return this.showToast(
					"warning",
					"top-right",
					"Para venta",
					"SuccessIcon",
					"No se encontro coincidencias."
				)
			}

			this.modalSelectSpecimens.data = data
			this.modalSelectSpecimens.show = true
			this.isPreloading(false)

			console.log("SP", data)
		},
		async setSelectSpecimen(specimen) {
			this.isPreloading()
			this.select_specimen_id = specimen.id
			await this.getImages(specimen.id)
			await this.getVideos(specimen.id)
			this.modalSelectSpecimens.specimen_select = specimen
			this.modalSelectSpecimens.show = false
			this.specimen_plate = null
			this.isPreloading(false)
		},
		selectVideoToUpdate(item) {
			this.toggleModalUpdateVideo = true
			this.itemSelectToUpdate = item
		},
		viewSpecimen({ item, index }) {
			this.toggleModal()
			this.setTextImage({
				// title: "Text",
				url: `/ejemplares/detalle/${item.id}`,
			})
			this.setIndexImage(index)
		},
		formaterPrice(e) {
			if (typeof this.price == "number") {
				this.price = this.price.toFixed(2)
				return
			}
			let valoresAceptados = /^[0-9].+$/
			if (this.price.match(valoresAceptados)) {
				this.price = Number.parseFloat(this.price).toFixed(2)
			} else {
				this.price = null
			}
		},
	},
	watch: {
		tabIndexVideo() {
			this.reloadTab()
		},
	},
	destroyed() {
		this.setVideoteca(false)
		this.setImagesVideoteca([])
	},
}
</script>

<style lang="scss" scoped>
.container-information {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	position: relative;
	padding: 1.5rem;
	background: #f5f5f5;
	// border: 1px solid rgb(87, 87, 87);
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	margin-bottom: 2rem;
	&:last-child {
		margin-bottom: 0;
	}
	h4 {
		position: absolute;
		top: -14px;
		left: 20px;
		background: white;
		padding: 2px 20px;
		border-radius: 8px;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	}
	.grid-images {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		height: auto;
		gap: 1rem;
	}
	.grid-videos {
		.container-btns {
			display: flex;
			justify-content: flex-start;
			.item-btn {
				padding: 0.1rem 0.8rem;
				display: flex;
				cursor: pointer;
				// border-bottom: 2px solid red;
				padding-bottom: 0.4rem !important;
				.badge {
					margin-left: 0.5rem;
				}
			}
			.active {
				padding-bottom: 0.4rem !important;
				border-bottom: 2px solid var(--primary);
			}
		}
	}
}

.container-valid-plate {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	@media screen and (min-width: 800px) {
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
	&__item {
		// background: red;
		padding: 0.5rem 0;
		box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
		// border: 1px solid #f5f5f5;
		// padding: 1rem;
		border-radius: 12px;
		overflow: hidden;
		// margin-bottom: 1rem;
		cursor: pointer;
		transition: 0.3s all ease-in-out;
		&:hover {
			background: #f5f5f5;
		}

		h3 {
			background: var(--primary);
			color: #fff;
			padding: 0.5rem;
		}
		.skeletor-specimen-add {
			position: relative;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.skeletor {
				position: absolute;
				width: 100%;
				height: 100%;
				background: transparent;
			}
		}
	}
}
</style>

<template>
	<div>
		<div>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
					>
						<div class="mr-2 cross-center gap-x-1" v-if="[1, 2].includes(category_id)">
							<span class="text-primary">Ejemplares</span>
							<v-select
								v-model="yearDead"
								label="season"
								:options="[{ id: null, season: 'Todos', start: null, end: null }, ...years]"
								:reduce="(opt) => opt.id"
								:clearable="false"
								:style="{ maxWidth: '11ch', minWidth: '11ch' }"
							/>
						</div>
						<div class="mr-2 cross-center gap-x-1" v-if="[1, 2].includes(category_id)">
							<span class="text-primary">Hijos</span>
							<v-select
								v-model="year"
								label="season"
								:options="years"
								:reduce="(opt) => opt.id"
								:clearable="false"
								:style="{ maxWidth: '11ch', minWidth: '11ch' }"
							/>
						</div>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="current_page"
							:total-rows="total_data"
							:per-page="perpage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
			<!-- Table Top -->
			<b-row>
				<!-- Per Page -->
				<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
					<label>Mostrar</label>
					<v-select
						v-model="perpage"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
					/>
					<label class="mr-2">registros</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
				</b-col>
				<!-- Search -->
				<b-col cols="12" xl="8" class="mt-1">
					<div class="container-btns-page-specimens">
						<div class="d-flex">
							<b-form-input
								v-model="search_input"
								class="d-inline-block mr-1"
								placeholder="Placa o alias..."
								@keyup.enter="$refs.refParentsDeads.refresh()"
								debounce="500"
							/>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>
		<b-table
			api-url="api/specimens"
			ref="refParentsDeads"
			class="position-relative"
			:items="myProvider"
			:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
			sticky-header="70vh"
			primary-key="id"
			table-class="text-nowrap"
			:fields="visibleFields"
			responsive
			small
			show-empty
			:busy.sync="isBusy"
			empty-filtered-text="No hay registros que coincidan con tu búsqueda."
			empty-text="No hay registros que mostrar."
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:current-page="current_page"
			:per-page="perpage"
		>
			<template #table-busy>
				<div class="text-center text-primary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Cargando...</strong>
				</div>
			</template>

			<template #thead-top="{}">
				<template v-if="[1, 2].includes(category_id)">
					<b-th :colspan="category_id === 1 ? 2 : 1" class="bg-transparent border-0"></b-th>
					<b-th colspan="5" class="bg-info bg-darken-1 text-white text-center">
						<div class="sons-specimens-v2">
							<p class="mb-0">Hijos vivos</p>
						</div>
					</b-th>
				</template>
			</template>

			<template #cell(specimen)="{ item }">
				<SpecimenPlate
					:specimen="{
						plate: item.plate,
						alias: item.alias,
						id: item.id,
						thumb: item.thumb,
						image: item.image,
						ran: item.ran_o == 1 || item.ran_r > 0 ? true : false,
						guilty: item.ran_r > 0 && item.ran_o == 1 ? true : false,
					}"
				/>
			</template>

			<template #cell(video)="data">
				<b-button
					:disabled="data.item.count_movies == 0"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					:variant="data.item.count_movies == 0 ? 'outline-danger' : 'outline-primary'"
					class="btn-icon rounded-circle"
					@click="setViewVideoBySpecimen(data.item)"
				>
					<feather-icon icon="FilmIcon" />
				</b-button>
			</template>

			<template #cell(dob)="{ item }">
				<div class="text-center" style="padding-top: 5px; padding-bottom: 5px">
					<SpecimenAge :age="item.dob" />
					<strong class="d-block text-center" style="padding-top: 3px">
						{{ item.dob ? `(${item.dob})` : "---" }}
					</strong>
				</div>
			</template>

			<template #cell(created_at_date)="data">
				<p v-html="data.item.created_at"></p>
			</template>

			<template #cell(cintillo_patas)="{ item }">
				<div class="sons-specimens-v2">
					<p @click="clickSonsBySpecimenv2(item, 0)" class="cursor-pointer text-primary font-weight-bold">
						{{ item.cintillo_pata || 0 }}
					</p>
				</div>
			</template>

			<template #cell(chicks_machos)="{ item }">
				<div class="sons-specimens-v2">
					<p @click="clickSonsBySpecimenv2(item, 1)" class="cursor-pointer text-primary font-weight-bold">
						{{ item.machos }}
					</p>
				</div>
			</template>

			<template #cell(chicks_hembras)="{ item }">
				<div class="sons-specimens-v2">
					<p @click="clickSonsBySpecimenv2(item, 2)" class="cursor-pointer text-primary font-weight-bold">
						{{ item.hembras }}
					</p>
				</div>
			</template>

			<template #cell(chicks_sa)="{ item }">
				<div class="sons-specimens-v2">
					<p @click="clickSonsBySpecimenv2(item, 3)" class="cursor-pointer text-primary font-weight-bold">
						{{ item.sin_asignar }}
					</p>
				</div>
			</template>

			<template #cell(chicks_total)="{ item }">
				<div class="sons-specimens-v2">
					<p @click="clickSonsBySpecimenv2(item, 4)" class="cursor-pointer text-primary font-weight-bold">
						{{ sumHasIsNumber(item.cintillo_pata, item.hembras, item.machos, item.sin_asignar) }}
					</p>
				</div>
			</template>

			<template #cell(quantity_posturas_padrillos)="{ item }">
				<span
					@click="clickNumberPostura(item)"
					class="cursor-pointer text-primary font-weight-bold padrillos"
					>{{ item.count_posturas }}</span
				>
			</template>

			<template #cell(quantity_posturas_madrillas)="{ item }">
				<span
					@click="clickNumberPostura(item)"
					class="cursor-pointer text-primary font-weight-bold madrillos"
					>{{ item.count_posturas }}</span
				>
			</template>

			<template #cell(tournaments)="data">
				<FeatherIcon
					@click="clickTournamentsBySpecimen(data.item.id, data.item)"
					icon="EyeIcon"
					class="cursor-pointer"
					size="20"
					:color="data.item.tournaments_specimen > 0 ? '#7367f0' : '#9e9e9e'"
				/>
			</template>

			<template #cell(origin)="{ item }">
				<div class="d-flex justify-content-center align-items-center">
					<span>{{ item.origin }}</span>
				</div>
			</template>

			<template #cell(status)="{ item }">
				<div class="d-flex justify-content-center align-items-center">
					<span>{{ item.status }}</span>
					<feather-icon
						icon="Edit2Icon"
						class="text-warning cursor-pointer ml-2"
						@click="openSpecimenStatusChanged(item)"
					/>
				</div>
			</template>

			<template #cell(fertilidad)="{ item }">
				{{ item.count_posturas * 1 > 0 ? parseFloat(item.fertilidad) + "%" : "--" }}
			</template>

			<template #cell(madrilla)="{ item }">
				<SpecimenPlate
					:specimen="{
						plate: item.madrilla.plate,
						alias: item.madrilla.alias,
						id: item.madrilla.id,
						thumb: item.madrilla.thumb,
						image: item.madrilla.image,
					}"
					not_image
				/>
			</template>

			<template #cell(padrillo)="{ item }">
				<SpecimenPlate
					:specimen="{
						plate: item.padrillo.plate,
						alias: item.padrillo.alias,
						id: item.padrillo.id,
						thumb: item.padrillo.thumb,
						image: item.padrillo.image,
					}"
					not_image
				/>
			</template>

			<template #cell(tracking_weight)="{ item }">
				<span class="mr-1" v-if="item.weight > 0">{{ item.weight }}</span>
				<span class="mr-1" v-else>-</span>
				<!-- <feather-icon
					icon="PlusIcon"
					class="cursor-pointer"
					size="20"
					@click="openAddSpecimenWeightModal(item)"
				/> -->
				<feather-icon
					icon="ListIcon"
					class="cursor-pointer"
					@click="openSpecimenWeightListModal(item)"
					v-if="Number(item.weight_counter) > 0"
				/>
			</template>

			<template #cell(actions)="data">
				<!-- <feather-icon
					icon="TrashIcon"
					:id="`delete-tooltip${data.index}`"
					class="cursor-pointer text-danger"
					size="20"
					@click="delete_specimen(data.item.id)"
					v-if="isAdmin"
				/> -->
			</template>

			<template #custom-foot="{}">
				<b-tr v-if="[1, 2].includes(category_id)">
					<b-td :colspan="category_id === 1 ? 2 : 1" class="bg-transparent"></b-td>
					<b-td class="bg-info bg-darken-1 text-white text-center">
						<p class="mb-0">CP</p>
						<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "cintillo_pata") }}</p>
					</b-td>
					<b-td class="bg-info bg-darken-1 text-white text-center">
						<p class="mb-0">M</p>
						<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "machos") }}</p>
					</b-td>
					<b-td class="bg-info bg-darken-1 text-white text-center">
						<p class="mb-0">H</p>
						<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "hembras") }}</p>
					</b-td>
					<b-td class="bg-info bg-darken-1 text-white text-center">
						<p class="mb-0">SA</p>
						<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "sin_asignar") }}</p>
					</b-td>
					<b-td class="bg-info bg-darken-1 text-white text-center">
						<p class="mb-0">Total</p>
						<p class="mb-0 font-weight-bold">
							{{
								sumSons(dataSpecimens, "cintillo_pata") +
								sumSons(dataSpecimens, "sin_asignar") +
								sumSons(dataSpecimens, "machos") +
								sumSons(dataSpecimens, "hembras")
							}}
						</p>
					</b-td>
				</b-tr>
			</template>
		</b-table>

		<b-modal
			v-if="modalViewVideosBySpecimen"
			hide-footer
			v-model="modalViewVideosBySpecimen"
			:title="`Video del ejemplar ${
				modalViewVideosBySpecimenItem.alias ? `${modalViewVideosBySpecimenItem.alias} - ` : ''
			} (${modalViewVideosBySpecimenItem.plate ? modalViewVideosBySpecimenItem.plate : ''})`"
			size="xl"
			lazy
		>
			<ContainerSpecimenListVideos :id_specimen="modalViewVideosBySpecimenItem.id" />
		</b-modal>

		<!-- hijos vivos del ejemplar -->
		<b-modal
			v-model="modalSonsBySpecimen.show"
			:title="modalSonsBySpecimen.title"
			size="lg"
			hide-footer
			@hidden="modalSonsBySpecimen.show = false"
			content-class="m-0 p-0"
		>
			<SonsByIdSpecimen :modalSonsBySpecimen="modalSonsBySpecimen" :category="category_id" :year="year" />
		</b-modal>
	</div>
</template>

<script>
import axios from "@/axios";
import SonsByIdSpecimen from "@/components/specimens/SonsByIdSpecimen.vue";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import SpecimenAge from "@/components/specimens/SpecimenAge.vue";
import ContainerSpecimenListVideos from "../dashboard/ContainerSpecimenListVideos.vue";
import Ripple from "vue-ripple-directive";

export default {
	name: "ParentsDead",
	directives: {
		Ripple,
	},
	components: {
		SonsByIdSpecimen,
		SpecimenPlate,
		SpecimenAge,
		ContainerSpecimenListVideos,
	},
	props: {
		category_id: {
			type: Number,
			default: 1,
			required: true,
		},
	},
	mounted() {
		this.$refs.refParentsDeads.refresh();
	},
	computed: {
		visibleFields() {
			return this.arrayColumns.filter((field) => field.visible);
		},
		sumSons: () => (item, key) => {
			return item.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue[key] * 1) || 0;
			}, 0);
		},
		sumHasIsNumber: () => (cintillo_patas, sons_machos, sons_hembras, sons_sa) => {
			return (
				Number(cintillo_patas || 0 * 1) +
				Number(sons_machos || 0 * 1) +
				Number(sons_hembras || 0 * 1) +
				Number(sons_sa || 0 * 1)
			);
		},
		years() {
			this.year = this.seasons[0].id;
			return this.$store.state.utilities.seasons;
		},
		ammendedYear() {
			return this.seasonFromId(this.year);
		},
		ammendedYearDead() {
			return this.seasonFromId(this.yearDead);
		},
	},
	watch: {
		year(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.$refs.refParentsDeads.refresh();
			}
		},
		yearDead(newVal, oldVal) {
			this.$refs.refParentsDeads.refresh();
		},
	},
	data() {
		return {
			year: null,
			yearDead: null,
			sortBy: "status",
			sortDesc: false,
			isBusy: false,
			perpage: 100,
			next_page: "",
			start_page: "",
			search_input: "",
			perPageOptions: [10, 25, 50, 100],
			current_page: 1,
			dataSpecimens: [],
			modalViewVideosBySpecimen: false,
			total_data: "",
			to_page: "",
			modalSonsBySpecimen: {
				title: "",
				show: false,
				idSpecimen: null,
				plate: null,
				index: 1,
			},
			arrayColumns: [
				{
					key: "specimen",
					label: "Ejemplar",
					class: "text-center",
					visible: true,
				},
				{
					key: "video",
					label: "Videos",
					class: "text-center ",
					visible: true,
					visible: [1, 3, 5].includes(this.category_id) ? true : false,
				},
				{
					key: "cintillo_patas",
					label: "CP",
					class: "text-center ",
					visible: this.category_id == 1 || this.category_id == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_machos",
					label: "M",
					class: "text-center ",
					visible: this.category_id == 1 || this.category_id == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_hembras",
					label: "H",
					class: "text-center ",
					visible: this.category_id == 1 || this.category_id == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_sa",
					label: "SA",
					class: "text-center ",
					visible: this.category_id == 1 || this.category_id == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_total",
					label: "Total",
					class: "text-center ",
					visible: this.category_id == 1 || this.category_id == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "quantity_posturas_padrillos",
					label: "Posturas",
					class: "text-center ",
					visible: this.category_id == 1 ? true : false,
				},
				{
					key: "quantity_posturas_madrillas",
					label: "Posturas",
					class: "text-center ",
					visible: this.category_id == 2 ? true : false,
				},
				{
					key: "fertilidad",
					label: "Fertilidad",
					class: "text-center ",
					visible: this.category_id == 1 || this.category_id == 2 ? true : false,
				},
				{
					key: "madrilla",
					label: "Madrilla",
					class: "text-center ",
					visible: this.category_id != 1 && this.category_id != 2 ? true : false,
				},
				{
					key: "padrillo",
					label: "Padrillo",
					class: "text-center ",
					visible: this.category_id != 1 && this.category_id != 2 ? true : false,
				},
				{
					key: "tournaments",
					label: "Torneos",
					class: "text-center ",
					visible: this.category_id == 3 ? true : false,
				},
				{
					key: "origin",
					label: "Origen",
					class: "text-center ",
					visible: this.category_id != 1 && this.category_id != 2 ? true : false,
				},
				{
					key: "dob",
					label: "Edad",
					class: "text-center ",
					//sortable: true,
					visible: true,
				},
				{
					key: "tracking_weight",
					label: "Peso (Kg)",
					class: "text-center",
					visible: true,
				},
				{
					key: "actions",
					label: "Acciones",
					class: "text-center ",
					visible: true,
				},
			],
		};
	},
	methods: {
		myProvider(ctx) {
			const promise = axios.get(`${ctx.apiUrl}?page=${ctx.currentPage}`, {
				params: {
					orderby: "plate",
					order: "desc",
					campo: this.search_input,
					perpage: ctx.perPage,
					filter_origin: null,
					filter_color: null,
					filter_category: this.category_id,
					filter_status: 3,
					filter_rival: null,
					date_start: this.ammendedYear.start,
					date_end: this.ammendedYear.end,
					dead_date_start: this.ammendedYearDead ? this.ammendedYearDead.start : null,
					dead_date_end: this.ammendedYearDead ? this.ammendedYearDead.end : null,
				},
			});

			// Must return a promise that resolves to an array of items
			return promise.then((data) => {
				const items = data.data.data;
				this.dataSpecimens = data.data.data;
				items.forEach((item) => {
					if (item.photo) {
						this.images.push({
							...item,
							title: item.alias + "  |  " + item.plate,
							src: item.photo,
						});
					}
				});

				this.data = data.data.data;
				this.start_page = data.data.from;
				this.current_page = data.data.current_page;
				this.perpage = data.data.per_page;
				this.next_page = this.start_page + 1;
				this.end_page = data.data.last_page;
				this.total_data = data.data.total;
				this.to_page = data.data.to;
				// Must return an array of items or an empty array if an error occurred
				return items || [];
			});
		},
		resetSearch() {
			this.search_input = "";
			this.$refs.refParentsDeads.refresh();
			this.images = [];
		},
		setViewVideoBySpecimen(specimen) {
			this.modalViewVideosBySpecimen = true;
			this.modalViewVideosBySpecimenItem = specimen;
		},
		clickSonsBySpecimenv2(item, tabIndex) {
			this.modalSonsBySpecimen.title = `Hijos del ejemplar ${item.alias ? item.alias : ""} ${
				item.alias ? "-" : ""
			} (${item.plate ? item.plate : ""})`;
			this.modalSonsBySpecimen.show = !this.modalSonsBySpecimen.show;
			this.modalSonsBySpecimen.idSpecimen = item.id;
			this.modalSonsBySpecimen.index = tabIndex;
		},
		reloadTable() {
			console.log("reload");
			this.$refs.refParentsDeads.refresh();
		},
	},
};
</script>

<style lang="scss" scoped></style>

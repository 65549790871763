<template>
	<b-sidebar
		id="add-awards"
		v-model="visibleChanges"
		footer-class="bg-transparent"
		bg-variant="white"
		shadow
		backdrop
		lazy
		header-class="py-1 pr-0  bg-transparent"
		@hidden="closeSidebar()"
		body-class="p-1"
		width="95%"
		:title="title"
	>
		<h4 v-if="counterSons == 0" class="text-center p-1 font-weight-bolder">SIN HIJOS</h4>
		<b-tabs v-else v-model="indexTab">
			<b-tab v-for="(tab, index) in yearsTab" :key="`${index}-year`">
				<template slot="title">
					{{ tab.year }}
					<b-badge variant="danger" class="ml-1"> {{ tab.counter }} </b-badge>
				</template>
				<div class="border rounded p-1" style="min-height: calc(100vh - 150px)">
					<b-row>
						<!-- CP -->
						<b-col md="4" xl="3">
							<ShowChicksGlobalByEvo type_evo="CP" :sons="posturasCP" />
						</b-col>
						<!-- CA -->
						<b-col md="4" xl="4">
							<ShowChicksGlobalByEvo type_evo="CA" :sons="posturasCA" />
						</b-col>
						<!-- SPEC -->
						<b-col md="4" xl="5">
							<ShowChicksGlobalByEvo type_evo="SPEC" :sons="posturasSpec" />
						</b-col>
					</b-row>
				</div>
			</b-tab>
		</b-tabs>
	</b-sidebar>
</template>

<script>
import specimensService from "@/services/specimens.service";
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos.vue";
import ShowChicksGlobalByEvo from "./ShowChicksGlobalByEvo.vue";
export default {
	name: "SidebarChicksGlobal",
	components: {
		CombChickenlegsCintillos,
		ShowChicksGlobalByEvo,
	},
	props: {
		specimen: {
			type: Object,
			required: true,
		},
		yearSelected: {
			type: String | Number,
		},
	},
	async mounted() {
		this.isPreloading();
		await this.getSons();
		this.visibleChanges = true;
		this.isPreloading(false);
	},
	computed: {
		title() {
			let { alias, plate } = this.specimen;
			return `Hijos global de ${alias ? alias : ""} (${plate})`;
		},
	},
	watch: {
		indexTab(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.refreshData();
			}
		},
	},
	data() {
		return {
			visibleChanges: false,
			yearsTab: [],
			sons: [],
			indexTab: 0,
			posturasPerYear: [],
			posturasCP: [],
			posturasCA: [],
			posturasSpec: [],
			counterSons: 0,
		};
	},
	methods: {
		async getSons() {
			const { status, all_sons } = await specimensService.getSonsGlobal(this.specimen.id);
			if (status && all_sons.length) {
				this.sons = all_sons;
				this.yearsTab = all_sons.map((a) => {
					let counter = a.data.reduce((c, b) => {
						return c + (b.eggs * 1 + b.chicks_all * 1 + b.specimens_all * 1);
					}, 0);

					return {
						year: a.year,
						counter,
					};
				});
				this.yearsTab = this.yearsTab.filter(it => it.counter != 0);

				this.indexTab = this.yearsTab.findIndex((a) => a.year == this.yearSelected);
				if (this.indexTab == -1) {
					this.indexTab = 0;
				}

				this.refreshData();
			}
		},
		refreshData() {
			this.posturasPerYear = [];
			this.posturasCP = [];
			this.posturasCA = [];
			this.posturasSpec = [];


			this.counterSons = this.sons.map((it) => it.data).filter((it) => it.length != 0).length;

			this.sons = this.sons.filter((it) => it.data.length != 0);

			this.posturasPerYear = this.sons.filter((s, index) => index === this.indexTab)[0]?.data;
		

			if (this.sons.length == 0) return;
			this.posturasCP = this.posturasPerYear.length > 0 ? this.posturasPerYear.filter((p) => p.eggs * 1 > 0) : [];
			this.posturasCA =
				this.posturasPerYear.length > 0 ? this.posturasPerYear.filter((p) => p.chicks_all * 1 > 0) : [];
			this.posturasSpec =
				this.posturasPerYear.length > 0 ? this.posturasPerYear.filter((p) => p.specimens_all * 1 > 0) : [];
		},
		closeSidebar() {
			this.visibleChanges = false;
			this.$emit("hidden");
		},
	},
};
</script>

<style lang="scss">
.c-chick {
	border-radius: 5px;
	background: #7367f0;
	color: #ffff;
	padding: 5px 10px;
}
</style>

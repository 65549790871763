<template>
	<b-overlay :show="isLoading">
		<b-row class="gap-y-1">
			<b-col cols="12" md="4">
				<h2 class="text-secondary font-weight-bolder">POR CATEGORIA</h2>
			</b-col>
			<b-col cols="12" md="8" class="d-flex justify-content-end align-items-center gap-x-2"></b-col>
		</b-row>
		<hr />
		<VueApexCharts type="bar" height="300" :options="chartOpts" :series="chartData" />
	</b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { year: Object, info: Array, count: Number },
	data: () => ({
		isLoading: true,
		chartData: [
			{
				name: "Categoria",
				data: new Array(6).fill(3),
			},
		],
		chartOpts: {
			labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"],
			colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
			fill: {
				type: "gradient",
				gradient: {
					shade: "light",
					type: "horizontal",
					shadeIntensity: 0.25,
					gradientToColors: undefined,
					inverseColors: true,
					opacityFrom: 0.85,
					opacityTo: 0.85,
					stops: [50, 0, 100],
				},
			},
		},
	}),
	methods: {
		async getData() {
			if (this.info) {
				this.isLoading = false
			}
			this.chartData = [
				{
					name: "Categoria",
					data: this.info.map((d) => d.counter),
				},
			]
			this.chartOpts = {
				labels: this.info.map((d) => d.category_name),
				colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
			}
		},
	},
	watch: {
		info() {
			this.getData()
		},
		count() {
			this.isLoading = true
		},
	},
}
</script>

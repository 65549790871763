<template>
	<b-overlay :show="isLoading">
		<b-row class="gap-y-1">
			<b-col cols="12" md="4" class="d-flex align-items-center gap-x-2">
				<h2 class="m-0 text-secondary font-weight-bolder">POR MUERTE</h2>
				<span>
					Total:
					<b>{{ total || "---" }}</b>
				</span>
			</b-col>
			<b-col cols="12" md="8" class="d-flex justify-content-end align-items-center gap-x-2">
				<b-input-group size="sm" prepend="Genero" style="max-width: 15rem">
					<b-form-select :options="genderOpts" v-model="gender" @change="changePagination(1, 'select')" />
				</b-input-group>
				<b-button-group size="sm">
					<b-button
						class="btn-icon"
						variant="outline-secondary"
						:disabled="pagination.page <= 1"
						@click="changePagination(-1, 'paginator')"
					>
						<feather-icon icon="ChevronLeftIcon" />
					</b-button>
					<b-button
						class="btn-icon"
						variant="outline-secondary"
						:disabled="pagination.page >= pagination.maxPage"
						@click="changePagination(1, 'paginator')"
					>
						<feather-icon icon="ChevronRightIcon" />
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>
		<hr />
		<VueApexCharts type="bar" height="300" :options="chartOpts" :series="chartData" />
	</b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { year: Object, info: Object, count: Number },
	data: () => ({
		isLoading: true,
		genderOpts: [
			{ value: null, text: "Todos" },
			{ value: 1, text: "Machos" },
			{ value: 2, text: "Hembras" },
		],
		gender: null,
		chartData: [
			{
				name: "Razon",
				data: new Array(6).fill(3),
			},
		],
		chartOpts: {
			labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"],
			colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
			fill: {
				type: "gradient",
				gradient: {
					shade: "light",
					type: "horizontal",
					shadeIntensity: 0.25,
					gradientToColors: undefined,
					inverseColors: true,
					opacityFrom: 0.85,
					opacityTo: 0.85,
					stops: [50, 0, 100],
				},
			},
		},
		pagination: {
			page: 1,
			maxPage: 2,
		},
		total: 0,
	}),
	methods: {
		async getData() {
			if (this.info) {
				this.isLoading = false
			}
			this.pagination.maxPage = this.info.last_page
			this.total = this.info?.data[0]?.total
			this.chartData = [
				{
					name: "Razon",
					data: this.info?.data?.map((d) => d.amount),
				},
			]
			this.chartOpts = {
				labels: this.info?.data?.map((d) => d.reason),
				colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
			}
		},
		changePagination(diff, origin) {
			if (origin === "select") {
				this.pagination.page = diff
			} else {
				this.pagination.page += diff
			}
			this.isLoading = true
			this.$emit("refresh", { page: this.pagination.page, gender: this.gender })
		},
	},
	watch: {
		info() {
			this.getData()
		},
		count() {
			this.isLoading = true
		},
	},
}
</script>

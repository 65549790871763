<template>
	<div>
		<div class="card mb-0">
			<div class="m-2">
				<b-row class="mb-1">
					<b-col>
						<b-tabs class="mx-2" v-model="curTab">
							<b-tab v-for="item in arrayCounters" :key="item.id">
								<template #title>
									<div class="container-counter">
										<p class="mb-0 mr-50">{{ item.name }}</p>
										<b-badge variant="danger">{{ item.count }}</b-badge>
										<!-- <p class="counter">{{ item.count }}</p> -->
									</div>
								</template>
							</b-tab>
							<!-- <b-tab title="Inactivos" />
							<b-tab title="Reservados" />
							<b-tab title="Vendidos" /> -->
						</b-tabs>
					</b-col>
					<b-col class="d-flex justify-content-end align-items-center">
						<button @click="modalAddToSale.show = true" class="btn btn-primary">
							<feather-icon icon="PlusIcon" size="12" /> Agregar
						</button>
					</b-col>
				</b-row>
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
					</b-col>
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
							<div class="xt-search-input__search">
								<div class="search-container-value">
									<b-form-input
										type="search"
										class="search-input"
										@keyup.enter="init"
										v-model="search"
										placeholder="Placa o alias"
										style="width: 200px"
									/>
									<div class="search-btn">
										<button @click="init" class="btn btn-primary w-100">Buscar</button>
									</div>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '60vh' }">
					<b-thead>
						<b-tr class="text-center">
							<b-th>
								<div
									@click="
										order == 'asc' ? (order = 'desc') : (order = 'asc');
										orderBy = 'specimen_plate';
										init();
									"
								>
									Ejemplar
									<FeatherIcon
										:icon="order == 'desc' ? 'ChevronDownIcon' : 'ChevronUpIcon'"
										size="18"
										class="text-primary cursor-pointer ml-1"
									/>
								</div>
							</b-th>
							<b-th>Precio</b-th>
							<b-th>Peso</b-th>
							<b-th>Nacimiento</b-th>
							<b-th>Imagenes</b-th>
							<b-th>Videos</b-th>

							<b-th>
								<div
									@click="
										order == 'asc' ? (order = 'desc') : (order = 'asc');
										orderBy = 'updated_at';
										init();
									"
								>
									{{ updatedHeadText }}
									<FeatherIcon
										:icon="order == 'desc' ? 'ChevronDownIcon' : 'ChevronUpIcon'"
										size="18"
										class="text-primary cursor-pointer ml-1"
									/>
								</div>
							</b-th>

							<b-th>Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in specimens" :key="index">
							<b-td>
								<SpecimenPlate
									:specimen="{
										id: item.specimen.id,
										plate: item.specimen.plate,
										alias: item.specimen.alias,
										thumb: item.specimen.thumb,
										image: item.specimen.url,
									}"
								/>
							</b-td>

							<b-td>
								<span class="font-weight-bolder">{{ item.currency }}</span> {{ item.price.toFixed(2) }}
							</b-td>

							<b-td>
								{{ item.pounds_weight ? `${item.pounds_weight} (Lb)` : "Sin peso" }}
							</b-td>

							<b-td v-if="item.dob">
								{{ item.dob | myGlobalDayShort }}
							</b-td>
							<b-th v-else>---</b-th>

							<b-td>
								{{ item.count_images }}
							</b-td>

							<b-td>
								{{ item.count_videos }}
							</b-td>

							<b-td>
								<template v-if="item.updated_by">
									<p class="mb-0">{{ item.updated_by.name }}</p>
									<p class="font-small-3">{{ item.updated_by.date | myGlobalDayShort }}</p>
								</template>
								<p class="mb-0" v-else>Sin usuario</p>
							</b-td>

							<b-td>
								<ChangeSpecimenToSale
									:hasSell="[0, 2].includes(curTab)"
									:hasReturn="curTab == 2"
									:hasReserve="curTab == 0"
									:hasEdit="![2, 3].includes(curTab)"
									hasTracking
									:specimen="{ ...item.specimen, is_sale: item.status == 'activo' }"
									:currency_type="item.currency == 'USD' ? 1 : 2"
									:price="item.price"
									@refresh="init"
									:hasCheckbox="false"
									:hasChangeStatus="curTab != 3 ? true : false"
									:status_id="item.status_id"
								/>
							</b-td>
						</b-tr>

						<b-tr v-if="specimens.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin specimens encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>

		<b-modal v-model="modalAddToSale.show" :title="modalAddToSale.title" hide-footer>
			<AddSpecimenToSale @refresh="refreshSave" />
		</b-modal>

		<b-modal v-model="modalAddSpecimenTosale.show" title=" VENTA / SELECCIONAR INFORMACION" size="md" hide-footer>
			<AddSpecimenToSale
				v-if="modalAddSpecimenTosale.specimen"
				:specimen_id="modalAddSpecimenTosale.specimen.id"
				:specimen_name="`${
					modalAddSpecimenTosale.specimen.alias ? modalAddSpecimenTosale.specimen.alias : ''
				} (${modalAddSpecimenTosale.specimen.plate})`"
				@refresh="refreshSave"
				:specimen_price="modalAddSpecimenTosale.specimen_price"
				:specimen_currency_id="modalAddSpecimenTosale.specimen_currency_id"
			>
				<template slot="specimen-plate">
					<SpecimenPlate
						:specimen="{
							id: modalAddSpecimenTosale.specimen.id,
							plate: modalAddSpecimenTosale.specimen.plate,
							alias: modalAddSpecimenTosale.specimen.alias,
							thumb: modalAddSpecimenTosale.specimen.thumb,
							image: modalAddSpecimenTosale.specimen.photo,
						}"
					/>
				</template>
			</AddSpecimenToSale>
		</b-modal>
	</div>
</template>

<script>
import AddSpecimenToSale from "./dashboard/AddSpecimenToSale.vue";
import publicWebService from "@/services/publicWeb.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import ChangeSpecimenToSale from "@/views/amg/specimens/ChangeSpecimenToSale.vue";

export default {
	name: "SpecimensToSale",
	components: {
		AddSpecimenToSale,
		SpecimenPlate,
		ChangeSpecimenToSale,
	},
	async mounted() {
		await this.init();
	},
	data() {
		return {
			specimens: [],
			start_page: 1,
			to_page: 1,
			total_data: 0,
			current_page: 1,
			perpage: 30,
			search: null,
			selectCorral: null,
			modalAddToSale: {
				show: false,
				title: "Agregar ejemplar para venta",
			},
			modalAddSpecimenTosale: {
				show: false,
				title: null,
				specimen: null,
				specimen_currency_id: null,
				specimen_price: null,
			},
			//
			curTab: 0,
			arrayCounters: [],
			order: "asc",
			orderBy: "specimen_plate",
		};
	},
	computed: {
		tabToStatus() {
			switch (this.curTab) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				case 3:
					return 4;
			}
			return 0;
		},
		updatedHeadText() {
			switch (this.curTab) {
				case 0:
					return "Activado por";
				case 1:
					return "Inactivado por";
				case 2:
					return "Reservado por";
				case 3:
					return "Vendido por";
			}
			return "Actualizado por";
		},
	},
	methods: {
		async init() {
			this.isPreloading();
			await this.countersTabs();
			const { data } = await publicWebService.indexForSale({
				search: this.search,
				status_id: this.tabToStatus,
				order: this.order,
				orderBy: this.orderBy,
			});
			this.specimens = data;
			this.isPreloading(false);
		},
		editSpecimen(item) {
			this.modalAddSpecimenTosale.show = true;
			this.modalAddSpecimenTosale.specimen = item.specimen;
			this.modalAddSpecimenTosale.specimen_price = item.price;
			this.modalAddSpecimenTosale.specimen_currency_id = item.currency_id;
		},
		async refreshSave() {
			await this.init();
			this.modalAddSpecimenTosale.show = false;
			this.modalAddToSale.show = false;

			this.isPreloading(false);
		},
		async countersTabs() {
			const { data } = await publicWebService.getStatusesSpecimenWeb({
				type: 2,
			});
			this.arrayCounters = data;
			// console.log("resp", data);
		},
	},
	watch: {
		curTab(val) {
			this.search = "";
			this.init();
		},
	},
};
</script>

<style lang="scss">
.xt-search-input__search {
	width: 100%;
	// margin: 1rem 0;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 1400px) {
		flex-direction: row;
		justify-content: flex-end;
	}
	.search-container-type {
		display: flex;
		h4 {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.search-container-value {
		display: flex;
		flex-direction: column;
		// margin-top: 0.5rem;
		@media screen and (min-width: 700px) {
			flex-direction: row;
			// margin-top: .5rem;
			margin-left: 1rem;
		}
		.search-input {
			width: 100% !important;
			@media screen and (min-width: 700px) {
				width: 50% !important;
			}
			@media screen and (min-width: 1000px) {
				width: 50% !important;
			}
		}
		.search-btn {
			margin-top: 0.5rem;
			width: 100% !important;
			@media screen and (min-width: 700px) {
				margin-left: 0.5rem;
				margin-top: 0;
				width: 50% !important;
			}
		}
	}
}

.container-counter {
	position: relative;
	display: flex;
	align-items: center;
}
</style>

import axios from "@/axios";

const url = 'api/specimens/'

class ChildsDeadService{
    async getChildsDead(type, specimen_id){
        const { data } = await axios.get(`${url}deads-childs/cp`,{params:{type, specimen_id}})
        return data
    }
    async getChildsCategoryData(specimen_id){
        const { data } = await axios.get(`${url}deads-childs/${specimen_id}`)
        return data;
    }
}
export default new ChildsDeadService();
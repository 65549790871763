import { render, staticRenderFns } from "./AddSpecimenWeight.vue?vue&type=template&id=0792d28e"
import script from "./AddSpecimenWeight.vue?vue&type=script&lang=js"
export * from "./AddSpecimenWeight.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import axios from "@/axios";
const url = "/api/specimens/public";

class PublicWebService {
	async storeForSale({ specimen_id, currency_id, price, images, videos }) {
		try {
			const { data } = await axios.post(`${url}/store-for-sale`, {
				specimen_id,
				currency_id,
				price,
				images,
				videos,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	// status_id -> {1:activo,2:inactivo.3:pendiente,4:vendido}
	async indexForSale({ search, status_id, order, orderBy }) {
		try {
			const { data } = await axios.get(`${url}/get-specimens-sale`, {
				params: {
					search,
					status_id,
					order, orderby: orderBy
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async storeForWeb({ specimen_id }) {
		try {
			const { data } = await axios.post(`${url}/store-for-web`, {
				specimen_id,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async updateStatus({ specimen_id, status_id, type_id }) {
		try {
			const { data } = await axios.put(`${url}/update-status`, {
				specimen_id,
				status_id,
				type_id,
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getSpecimenForPublicTracking({ specimen_id, type_id }) {
		try {
			const { data } = await axios.get(`${url}/specimen-for-public-tracking`, {
				params: {
					specimen_id,
					type_id,
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getSpecimensToPublic({ category_id = null, search = null, page, perpage, status_id = 1, order, orderBy }) {
		try {
			const { data } = await axios.get(`${url}/get-specimens-to-public`, {
				params: {
					category_id,
					search,
					page,
					perpage,
					status_id,
					order, orderBy
				},
			});
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getStatusesSpecimenWeb({ type }) {
		try {
			const { data } = await axios.get(`${url}/get-statuses/${type}`);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}

	async getGeneralCounter() {
		try {
			const { data } = await axios.get(`${url}/counter-specimens-web`);
			return data;
		} catch (err) {
			return err.response.data;
		}
	}
}
export default new PublicWebService();

<template>
  <div class="container-item-postura">
    <div class="container-postura items-container">
      <p class="code">{{ item.code }}</p>
      <div class="item-specimen-posturas">
        <img :src="item.padrillo.thumb" alt="" class="image-specimen" />
        <div class="skeletor"></div>
        <div  class="name">
          <p @click="clickImageSpecimen(item.padrillo)" class="cursor-pointer">{{ item.padrillo.alias }}</p>
          <p class="alias" @click="linkSpecimen(item.padrillo.id)">{{ item.padrillo.plate }}</p>
        </div>
      </div>

      <div class="item-specimen-posturas">
        <img :src="item.madrilla.thumb" alt="" class="image-specimen" />
        <div class="skeletor"></div>
        <div class="name">
          <p @click="clickImageSpecimen(item.madrilla)" class="cursor-pointer">{{ item.madrilla.alias }}</p>
          <p class="alias" @click="linkSpecimen(item.madrilla.id)">{{ item.madrilla.plate }}</p>
        </div>
      </div>

      <div class="numbers">
        <p>{{ sumEggs(item, "quantity_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_infertile_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_burned_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_born_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_no_born_eggs") }}</p>

         <p>{{ sumEggs(item, "quantity_dead_eggs") }}</p>
        
      </div>

      <p class="status">{{ item.status }}</p>

      <p class="status font-weight-bolder">{{ item.postura_date }}</p>

      <div class="icon-plusv2" @click="show = !show">
        <FeatherIcon icon="ArrowDownCircleIcon" size="20" />
        <p class="mb-0">Ver lotes</p>
      </div>
    </div>
    <div
      class="content-postura-lote border"
      :class="[show ? 'content-postura-lote-active' : '']"
      :style="{ height: `${show ? (item.posturas.length + 1) * (windowWidth < 1500 ? 40 : 35) : 0}px` }"
    >
      <div class="container-postura estado-cintillo-header">
        <div></div>
        <div></div>
        <div></div>
        <div class="eggs-numbers">
          <p class="title-table text-center">Huevos en lote</p>
          <p class="title-table text-center">Infertiles</p>
          <p class="title-table text-center">Quemados</p>
          <p class="title-table text-center">Nacidos</p>
          <p class="title-table text-center">No nacidos</p>
          <p class="title-table text-center">Muertos</p>
        </div>
        <p class="title-table text-center">Estado de cintillo</p>

      </div>
      <div
        class="container-postura"
        v-for="(lote, key) in item.posturas"
        :key="key"
      >
        <div></div>
        <div class="lote-code cursor-pointer text-primary" @click="selectLote(lote)">{{ lote.lote_code }}</div>
        <div class="numbers">
          <p>{{ lote.quantity_eggs }}</p>
          <p>{{ lote.quantity_infertile_eggs }}</p>
          <p>{{ lote.quantity_burned_eggs }}</p>
          <p>{{ lote.quantity_born_eggs }}</p>
          <p>{{ lote.quantity_no_born_eggs }}</p>
          <p>{{ lote.quantity_dead_eggs }}</p>
        </div>
        <p class="mb-0 text-center">{{ lote.cintillo }}</p>
      </div>
    </div>

    <b-modal
      v-model="modalTotalPosturas.show"
      :title="modalTotalPosturas.title"
      size="xl"
      hide-footer
      scrollable
    >
      <LotesDetail :loteId="modalTotalPosturas.idLote"/>
    </b-modal>

    <SlidersImageVideo
      :url="clickImage.url"
      :type="clickImage.type"
      :text="clickImage.text"
      :text_url="clickImage.text_url"
      :open="clickImage.open"
      v-if="clickImage.open"
      @refreshSlider="refresh"
    />

  </div>
</template>

<script>
import PosturasByIdLote from './PosturasByIdLote.vue';
import SlidersImageVideo from '../header-bookmarks/SlidersImageVideo.vue';
import LotesDetail from '../encaste/lotes/LotesDetail.vue';
import { mapState } from 'vuex';

export default {
  name: "PosturasByIdSpecimenItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    PosturasByIdLote,
    SlidersImageVideo,
    LotesDetail
  },
  data() {
    return {
      show: false,
      modalTotalPosturas: {
        title: "",
        content: "",
        show: false,
        idLote: null,
      },
      clickImage: {
        url: null,
        type: 'image',
        text: null,
        text_url: null,
        open: false,
      },
    };
  },
  computed: {
    ...mapState("app", ['windowWidth']),
    sumEggs: () => (item, key) => {
      return item.posturas.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue[key];
      }, 0);
    },
  },
  mounted() {
    // console.log('SUM', this.windowWidth);
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    selectLote(item){
      // console.log('ASD', this.modalTotalPosturas);
      this.modalTotalPosturas.show = true;
      this.modalTotalPosturas.title = `Total de posturas del lote: ${item.lote_code}`;
      this.modalTotalPosturas.idLote = item.id
    },
    clickImageSpecimen(item){
      // console.log('QW', item);
      this.clickImage.open = true;
      this.clickImage.text_url = `detalle/${item.id}`;
      this.clickImage.type = 'image';
      this.clickImage.text = `${item.plate} ${item.alias}`;
      this.clickImage.url = item.image;
    },
    linkSpecimen(id) {
      window.open(`/ejemplares/detalle/${id}`, "_blank");
    },
    refresh() {
      this.clickImage.open = false;
      this.clickImage.text_url = null;
      this.clickImage.type = null;
      this.clickImage.text = item.plate;
      this.clickImage.url = null;
    }
  },
};
</script>

<style lang="scss">
.container-item-postura{
  // border-bottom: 1.5px solid #eff3f5;
  margin-bottom: 1rem;
}
.lote-code {
  grid-column: 2 / 4;
  text-align: center;
  font-weight: bold;
}
.content-postura-lote {
  height: 0;
  min-height: 0;
  overflow: hidden;
  transition: 1s all ease-in-out;
  opacity: 0;
}
.content-postura-lote-active {
  height: 0px;
  // min-height: 200px;
  overflow: visible;
  opacity: 1;
}
.numbers {
  p {
    // color: black;
  }
}
.border{
  border-bottom: 1.5px solid #eff3f5;
  // padding: 6rem 0;
  width: 100% !important;
  min-width: 1400px;
}
.estado-cintillo-header{
  margin-top: .2rem;
}
.icon-plusv2{
  display: flex;
  flex-direction: column;
  p{
    margin-top: .5rem;
  }
}
</style>

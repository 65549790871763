<template>
	<b-overlay :show="isLoading">
		<b-row class="gap-y-1">
			<b-col cols="12" md="4">
				<h2 class="text-secondary text-uppercase font-weight-bolder">Por edad</h2>
			</b-col>
			<b-col cols="12" md="8" class="d-flex justify-content-end align-items-center gap-x-2"></b-col>
		</b-row>
		<hr />
		<VueApexCharts type="bar" height="300" :options="chartOpts" :series="chartData" />
		<div class="d-flex justify-content-end gap-x-2" v-if="false">
			<div class="d-flex align-items-center gap-x-1">
				<div class="p-50 bg-danger rounded">
					<feather-icon class="text-white" icon="SearchIcon" size="18" />
				</div>
				<span class="font-weight-bolder">Text</span>
			</div>
		</div>
	</b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { year: Object, info: Array, count: Number },
	data: () => ({
		isLoading: true,
		chartData: [
			{
				name: "Machos",
				data: [0.4, 0.65, 0.76, 0.88, 1.5],
			},
			{
				name: "Hembras",
				data: [-0.8, -1.05, -1.06, -1.18, -1.4],
			},
		],
		chartOpts: {
			labels: ["85+", "80-84", "75-79", "70-74", "65-69"],
			colors: ["#28c76f", "#ea5455"],
			plotOptions: { bar: { horizontal: true } },
			chart: { stacked: true },
			legend: { show: false },
		},
		dataLabels: { formatter: (v) => Math.abs(v) },
		tooltip: { y: { formatter: (v) => Math.abs(Math.floor(v)) } },
		xaxis: { labels: { formatter: (v) => Math.abs(v) } },
		fill: {
			type: "gradient",
			gradient: {
				shade: "light",
				type: "horizontal",
				shadeIntensity: 0.25,
				gradientToColors: undefined,
				inverseColors: true,
				opacityFrom: 0.85,
				opacityTo: 0.85,
				stops: [50, 0, 100],
			},
		},
	}),
	methods: {
		async getData() {
			if (this.info) {
				this.isLoading = false
			}
			this.chartData = [
				{
					name: "Machos",
					data: this.info.map((d) => d.machos),
				},
				{
					name: "Hembras",
					data: this.info.map((d) => d.hembras * -1),
				},
			]

			this.chartOpts = {
				labels: this.info.map((d) => String(d.num)),
				colors: ["#28c76f", "#ea5455"],
				plotOptions: { bar: { horizontal: true } },
				chart: { stacked: true },
				legend: { show: false },
				dataLabels: { formatter: (v) => Math.abs(v) },
				tooltip: { y: { formatter: (v) => Math.abs(Math.floor(v)) } },
			}
		},
	},
	watch: {
		info() {
			this.getData()
		},
		count() {
			this.isLoading = true
		},
	},
}
</script>

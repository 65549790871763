<template>
	<div class="d-flex justify-content-center container-btns">
		<div class="container-btn-drop" v-if="hasChangeStatus" v-click-outside="closeWindow" :key="i">
			<FeatherIcon
				v-if="hasChange"
				@click="activeDrop = !activeDrop"
				icon="RepeatIcon"
				size="18"
				class="cursor-pointer input-close text-danger"
				v-b-tooltip.hover.top="`Cambiar estado`"
			/>
			<div class="options-drop" :class="[activeDrop ? 'active' : '']">
				<p
					v-for="(item, index) in arrayStatus.filter((it) => it.id != status_id)"
					:key="index"
					@click="changeToPublic"
					class="cursor-pointer"
				>
					{{ item.name }}
				</p>
			</div>
		</div>
		<template v-else>
			<template v-if="is_public">
				<FeatherIcon
					@click="changeToPublic"
					v-if="public_status_id == 1"
					icon="CheckCircleIcon"
					size="20"
					class="text-success cursor-pointer"
				/>

				<FeatherIcon
					@click="changeToPublic"
					v-if="public_status_id == 2"
					icon="CircleIcon"
					size="20"
					class="text-danger cursor-pointer"
				/>
			</template>
			<FeatherIcon
				v-else
				@click="changeToPublic"
				icon="CircleIcon"
				size="20"
				class="text-secondary cursor-pointer"
			/>
		</template>

		<FeatherIcon
			v-if="public_status_id"
			@click="showTracking"
			class="cursor-pointer ml-50"
			icon="InfoIcon"
			size="20"
			v-b-tooltip.hover
			title="Ver tracking"
		/>

		<b-modal
			centered
			hide-footer
			v-model="modalTracking.show"
			:title="modalTracking.title"
			v-if="modalTracking.show"
		>
			<b-table-simple class="table mb-0">
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-50">#</b-th>
						<b-th class="p-50">Registrado por</b-th>
						<b-th class="p-50">Estado</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(row, i) in modalTracking.data" :key="row.value">
						<b-td class="p-50">
							{{ i + 1 }}
						</b-td>
						<b-td class="p-50">
							<p class="mb-0">{{ row.created_at.date | myGlobalDayShort }}</p>
							<p class="mb-0">{{ row.created_at.user }}</p>
						</b-td>
						<b-td
							class="p-50 font-weight-bold"
							:class="[row.status_id == 1 ? 'text-success' : 'text-danger']"
						>
							{{ row.name }}
						</b-td>
					</b-tr>
					<b-tr class="text-center font-weight-bold" v-if="modalTracking.data.length == 0">
						<b-td colspan="4">No hay datos para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
	</div>
</template>

<script>

import { bus } from "@/main";
import publicWebService from "@/services/publicWeb.service";
import vClickOutside from "v-click-outside";

export default {
	name: "ChangeSpecimenToPublic",
	directives: {
		clickOutside: vClickOutside.directive,
	},
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
		tabIndex: {
			type: Number,
		},
		reload: {
			type: Boolean,
			default: true,
		},
		hasChange: {
			type: Boolean,
			default: false,
		},
		hasChangeStatus: {
			type: Boolean,
			default: false,
		},
		status_id: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			modalTracking: {
				show: false,
				title: null,
				data: [],
			},
			updt: 1,
			is_public: false,
			public_status_id: null,
			activeDrop: false,
			arrayStatus: [
				{
					id: 1,
					name: "Activo",
				},
				{
					id: 2,
					name: "Inactivo",
				},
			],
			i: 1,
		};
	},
	mounted() {
		// console.log("SPE", this.specimen.is_public);
		this.is_public = this.specimen.is_public;
		this.public_status_id = this.specimen.public_status_id;
	},
	methods: {
		async changeToPublic() {
			let swalMsg = `Seguro de desactivar al ejemplar ${
				this.specimen.alias || this.specimen.plate
			} para público?`;
			if ([null, 2].includes(this.public_status_id)) {
				swalMsg = `Seguro de activar al ejemplar ${this.specimen.alias || this.specimen.plate} para público?`;
			}
			const { isConfirmed } = await this.showConfirmSwal({ title: swalMsg });
			if (!isConfirmed) {
				this.specimen.is_public = this.tabIndex == 0 ? true : false;
				this.updt++;
				return;
			}
			if (!this.is_public || this.public_status_id == 2) {
				const resp = await publicWebService.storeForWeb({
					specimen_id: this.specimen.id,
				});
				this.showToast(
					"success",
					"top-right",
					"Ejemplares",
					"CheckIcon",
					`Se activo al ejemplar ${this.specimen.alias || this.specimen.plate} para publico`
				);

				if (!this.is_public) {
					this.is_public = true;
				}
				this.public_status_id = 1;
				this.is_public = true;
			} else {
				const resp = await publicWebService.updateStatus({
					specimen_id: this.specimen.id,
					status_id: 2,
					type_id: 1,
				});
				this.showToast(
					"success",
					"top-right",
					"Ejemplares",
					"CheckIcon",
					`Se desactivo al ejemplar ${this.specimen.alias || this.specimen.plate} para publico`
				);

				this.public_status_id = 2;
			}
			this.i = this.i + 1;
			this.activeDrop = false;
			this.$emit("refresh");
			bus.$emit("reload-counter-web");
		},
		async showTracking() {
			this.modalTracking.show = true;
			this.modalTracking.title = `Tracking / ${this.specimen.alias} (${this.specimen.plate})`;
			const { data } = await publicWebService.getSpecimenForPublicTracking({
				specimen_id: this.specimen.id,
				type_id: 1,
			});
			this.modalTracking.data = data;
		},
		closeWindow() {
			this.activeDrop = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.container-btns {
	// background: red !important;
	// height: 20px;
	justify-content: flex-start;
	align-items: flex-start;
	// margin-top: 0px;
}

.container-btn-drop {
	position: relative;
	.options-drop {
		position: absolute;
		bottom: 0%;
		right: 140%;
		z-index: 2000;
		background: white;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
		display: none;
		border-radius: 4px;
		// opacity: 0;
		p {
			margin-bottom: 0;
			color: #000;
			padding: 0.5rem 1.5rem;
			border-bottom: 1px solid #f5f5f5;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	.active {
		display: flex;
		opacity: 1;
		flex-direction: column;
		// background: red;
	}
}
</style>

<template>
  <b-modal
    size="xmd"
    ref="modal-add-specimen-weight"
    @hidden="$emit('hidden')"
    ok-title="Guardar"
    :title="`Agregar peso de  ${specimenObj.plate} ${specimenObj.alias ? '('+specimenObj.alias+')' : ''}`"
    @ok.prevent="saveSpecimenWeight()"
    centered
  >
    <AddSpecimenWightBody
      :specimenWeight="specimenWeight"
      :typeWeightOptions="S_TYPE_WEIGHT_OPTIONS"
      @save-weight="saveSpecimenWeight"
    />
  </b-modal>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import AddSpecimenWightBody from "./AddSpecimenWeightBody.vue";
import modalMixin from "@/mixins/modal.js";
import { showToast } from '@/helpers/toast';
export default {
  name: "AddSpecimenWight",
  components: {
    AddSpecimenWightBody,
  },
  props: {
    specimenObj: Object,
  },
  async mounted() {
    this.isPreloading();
    if (!this.S_TYPE_WEIGHT_OPTIONS.length) {
      await this.A_GET_TYPE_WEIGHT_OPTIONS();
    }
    this.toggleModal("modal-add-specimen-weight");
    this.isPreloading(false);
  },
  data() {
    return {
      specimenWeight: {
        kg_weight: null,
        pounds_weight: 0,
        type_weight: null,
        created_by: null,
        specimen_id: null,
        date_weight: moment().format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    ...mapState("weightSpecimen", ["S_TYPE_WEIGHT_OPTIONS"]),
  },
  methods: {
    ...mapActions("weightSpecimen", [
      "A_GET_TYPE_WEIGHT_OPTIONS",
      "A_SAVE_SPECIMEN_WEIGHT",
    ]),
    async saveSpecimenWeight() {
      if(!this.specimenWeight.kg_weight || !this.specimenWeight.type_weight){
        this.showToast("danger", "top-right", "Campos faltantes", "AlertTriangleIcon", 'Faltan campos por llenar');
        return
      }
      try {
        this.isPreloading();
        this.specimenWeight.specimen_id = this.specimenObj.id;
        this.specimenWeight.created_by = this.currentUser.user_id;
        await this.A_SAVE_SPECIMEN_WEIGHT(this.specimenWeight);
        this.isPreloading(false);
        this.showSuccessToast("El peso del ejemplar fue actualizado con exito");
        this.toggleModal("modal-add-specimen-weight");
        this.$emit(
          "update-specimen-weight",
          this.specimenObj,
          this.specimenWeight
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
  mixins: [modalMixin],
};
</script>

<style>
</style>

<template>
	<div>
		<div
			class="d-flex align-items-center justify-content-center"
			:class="{
				'add-border': incidence.evidence_video.url != null && incidence.evidence_image.url != null,
			}"
			style="border-radius: 3%; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
		>
			<span
				v-if="
					incidence.evidence_video.status == 2 &&
					incidence.evidence_video.url &&
					incidence.evidence_video.id != null
				"
				@click="viewSpecimen(incidence, 0)"
				style="cursor: pointer"
			>
				<b-avatar :src="incidence.evidence_video.thumb" rounded size="150" icon="film">
					<!-- badge
					badge-variant="danger" -->
					<!-- <template #badge>{{ incidence.evidence_video.duration }}</template> -->
				</b-avatar>
			</span>
			<div v-else class="px-50" :class="{ 'add-border': incidence.evidence_image.url != null }">
				<b-badge variant="primary">
					<span style="font-size: 11px">Procesando video</span>
				</b-badge>
			</div>
		</div>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal"
import { mapActions, mapState } from "vuex"
import moment from "moment"

export default {
	mixins: [modalMixin],
	props: {
		incidence: Object,
	},
	computed: {
		...mapState("header_book", [
			"imagesVideoteca",
			"openModal",
			"uploadImages",
			"upload",
			"toggleOpenGallery",
			"indexImage",
		]),
		...mapState("video", ["videos", "uploading"]),
	},
	async mounted() {
		this.setVideoteca(true)
		console.log("ejecutando.....")
		this.setImagesVideoteca([
			{
				...this.incidence,
				id: this.incidence,
				id_specimens: this.incidence.specimen.id,
				plate: this.incidence.specimen.plate,
				name: this.incidence.specimen.alias ? this.incidence.specimen.alias : "",
				preload: this.incidence.evidence_video.thumb,
				type: "video",
				url: this.incidence.evidence_video.url,
				evidence: true,
			},
		])
	},
	methods: {
		...mapActions("header_book", [
			"setImagesVideoteca",
			"toggleModal",
			"setIndexImage",
			"setTextImage",
			"setUploadImages",
			"deleteUploadFile",
			"toggleBgUpload",
			"initToggleUpload",
			"toggleModalGallery",
			"toggleUploadGallery",
			"setVideoteca",
		]),
		// resolveLongText(text) {
		// 	if (text.length > (this.indexTab == 0 ? 40 : 25))
		// 		return `${text.substring(0, this.indexTab == 0 ? 40 : 25)} ....`
		// 	return text
		// },
		viewSpecimen(item, index) {
			this.toggleModal()
			this.setTextImage({
				title: `Incidencia`,
				url: `/ejemplares/detalle/`,
			})
			this.setIndexImage(index)
		},
	},
	destroyed() {
		this.setVideoteca(false)
		this.setImagesVideoteca([])
	},
}
</script>

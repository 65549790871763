<template>
  <div>
    <HeaderMassivelyChanges
      :selectedSpecimens="S_MASSIVELY_PHOTOS"
      @selected="selected"
      :buttonSave="false"
    />
    <transition name="fade">
      <b-card v-if="!S_MASSIVELY_PHOTOS.length"
        ><h3>Aun no se seleccionan Ejemplares!!!</h3>
      </b-card>
    </transition>

    <transition name="fade">
      <div v-if="S_MASSIVELY_PHOTOS.length">
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span
              >Al seleccionar la <strong>imagen,</strong> se guardara
              automaticamente!!!</span
            >
          </div>
        </b-alert>
        <ValidationObserver ref="form">
          <b-row class="mt-2">
            <b-col
              cols="12"
              md="6"
              lg="4"
              xl="3"
              class="align-items-center d-flex mb-1"
              v-for="(specimen, index) in S_MASSIVELY_PHOTOS"
              :key="index"
            >
              <b-card
                class="w-100 h-100 box-shadow-6"
                header-tag="header"
                footer-tag="footer"
                body-class="pb-0"
                header-class="pb-1"
              >
                <template #header>
                  <SpecimenDetails :specimen="specimen" />
                </template>
                <div>
                  <SpecimenPhoto
                    :specimen="specimen"
                    heightImage="20rem"
                    savePhoto
                  />
                </div>
                <template #footer>
                  <div class="d-flex flex-row-reverse">
                    <feather-icon
                      size="20"
                      icon="Trash2Icon"
                      class="text-danger cursor-pointer"
                      @click="M_REMOVE_SPECIMEN_PHOTO(specimen.specimen_id)"
                    />
                  </div>
                </template>
              </b-card>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import HeaderMassivelyChanges from "../components/HeaderMassivelyChanges.vue";
import SpecimenPhoto from "./SpecimenPhoto.vue";
import SpecimenDetails from "../components/SpecimenDetails.vue";
export default {
  name: "PhotosMassively",
  components: {
    HeaderMassivelyChanges,
    SpecimenDetails,
    SpecimenPhoto,
  },
  async mounted() {},
  data() {
    return {};
  },
  computed: {
    ...mapState("photosSpecimen", ["S_MASSIVELY_PHOTOS"]),
  },
  methods: {
    ...mapActions("photosSpecimen", ["A_SAVE_MASSIVELY_PHOTOS"]),
    ...mapMutations("photosSpecimen", [
      "M_ADD_MASSIVELY_PHOTOS",
      "M_CLEAN_MASSIVELY_PHOTOS",
      "M_REMOVE_SPECIMEN_PHOTO",
    ]),
    ...mapMutations("customLightBox", ["M_CLEAN_ARRAY_IMAGES"]),
    selected(selected) {
      this.M_ADD_MASSIVELY_PHOTOS(selected);
    },
  },
  destroyed() {
    this.M_CLEAN_MASSIVELY_PHOTOS();
    this.M_CLEAN_ARRAY_IMAGES();
  },
};
</script>

<style>
</style>
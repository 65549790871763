<template>
	<div>
		<div class="card container-uls-encaste-corrales">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 p-2" role="tabslits">
				<b-nav-item
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					@click="tabIndex = 0"
					:active="tabIndex == 0"
				>
					<span>Publicos</span>
					<b-badge v-if="counterPublicAndSale[0]" pill variant="danger" class="ml-1">
						{{ counterPublicAndSale[0].counter }}
					</b-badge>
				</b-nav-item>
				<b-nav-item
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					@click="tabIndex = 1"
					:active="tabIndex == 1"
				>
					<span>En Venta</span>
					<b-badge v-if="counterPublicAndSale[1]" pill variant="danger" class="ml-1">
						{{ counterPublicAndSale[1].counter }}
					</b-badge>
				</b-nav-item>
			</ul>
		</div>

		<b-tabs
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1  d-none"
			nav-class="align-items-center"
			v-model="tabIndex"
			lazy
		>
			<b-tab>
				<SpecimensToPublic @refresh="$emit('refresh')" v-if="tabIndex == 0" />
			</b-tab>

			<b-tab>
				<SpecimensToSale @refresh="$emit('refresh')" v-if="tabIndex == 1" />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import SpecimensToSale from "./SpecimensToSale.vue";
import SpecimensToPublic from "./SpecimensToPublic.vue";
export default {
	name: "SpecimensToWeb",
	components: { SpecimensToSale, SpecimensToPublic },
	props: {
		counterPublicAndSale: {
			type: Array,
			default: () => {}
		}
	},
	data() {
		return {
			tabIndex: 0,
		};
	},
};
</script>

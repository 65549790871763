<template>
	<b-modal
		ref="modal-many-results"
		@hidden="$emit('hidden')"
		title="Coincidencias"
		hide-footer
		no-close-on-backdrop
		scrollable
		centered
		cancel-title="Cancelar"
		size="lg"
	>
		<div class="accordion" role="tablist">
			<template v-for="(block, idx) in blocks">
				<b-card no-body class="mb-1 border-primary" :key="`block-${idx}`">
					<b-card-header header-tag="header" class="w-100 p-0 p-md-1" role="tab">
						<b-button block class="w-100" variant="primary" @click="changeAccordion(idx)">
							<h2 class="text-white m-0">
								{{ block.category.name }}
								<b-badge class="ml-05" variant="danger" v-if="block.specimens.length != 0">
									{{ block.specimens.length }}
								</b-badge>

							</h2>
						</b-button>
					</b-card-header>
					<b-collapse accordion="my-accordion" role="tabpanel" v-model="block._open">
						<b-card-body style="max-height:50vh;overflow-y:auto">
							<b-row>
								<b-col
									class="mb-1"
									cols="12"
									md="4"
									v-for="specimen in block.specimens"
									:key="specimen.id"
								>
									<div
										class="d-flex align-items-center border-primary p-1 rounded"
										style="height: 8rem"
									>
										<SpecimenPlate :specimen="specimen" :show_name_cate="false" />
									</div>
								</b-col>
							</b-row>
						</b-card-body>
					</b-collapse>
				</b-card>
			</template>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal.js"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
export default {
	name: "ManyResults",
	mixins: [modalMixin],
	components: {
		SpecimenPlate,
	},
	props: {
		results: {
			type: Array,
			required: true,
		},
	},
	data: () => ({
		blocks: [],
	}),
	methods: {
		changeAccordion(idx) {
			this.blocks.forEach((block, i) => {
				if (idx == i) {
					block._open = !block._open
				} else {
					block._open = false
				}
			})
		},
		loadBlocks() {
			this.results.forEach((group) => {
				this.blocks.push({ ...group })
			})
			// if (this.blocks.length == 1) {
			// 	this.blocks[0]._open = true
			// }
		},
	},
	mounted() {
		this.isPreloading()
		this.toggleModal("modal-many-results")
		this.loadBlocks()
		this.isPreloading(false)
	},
}
</script>

<style lang="scss" scoped></style>

<template>
	<div>
		<!-- <b-alert class="px-2 py-1" variant="primary" show>
			Total:
			<strong>{{ counter }}</strong>
		</b-alert> -->
		<b-tabs lazy nav-wrapper-class="card pt-1 px-2 lg-px-1 mb-2" content-class="tt" v-model="selectedTab">
			<b-tab
				v-for="(menuTab, index) in menuTabs"
				:key="`${menuTab.title}-${index}`"
				:active="index == selectedTab"
				title-item-class="sp-nav-item"
				title-link-class="justify-content-between justify-content-lg-start px-1"
				@click="search = ''"
			>
				<template slot="title">
					<div class="m-05">
						{{ menuTab.title }}
						<b-badge v-if="menuTab.counter" :variant="index == selectedTab ? 'primary':'light-primary'">
							{{ menuTab.counter }}
						</b-badge>
					</div>
				</template>
				<SpecimensPage
					v-if="menuTab.category_id"
					:categoryId="menuTab.category_id"
					:idTab="idTab"
					isFromSpecimenPage
					:globalSearch="search"
					ref="specimenPage"
					@clearSearchGlobal="search = ''"
				/>
				<DeadPlateGrid v-else :globalSearch="search" @clearSearchGlobal="search = ''" />
			</b-tab>

			<template slot="tabs-end" v-if="!isInvitado">
				<li
					v-if="selectedTab != 6"
					role="presentation"
					class="nav-item align-self-center mt-1 ml-auto sp-nav-search"
				>
					<b-input-group id="cintillo-ala-input-group">
						<b-form-input placeholder="Busqueda global" v-model="search" @keyup.enter="globalSearch()" />
						<b-input-group-append>
							<b-button variant="outline-primary" @click="globalSearch()">
								<feather-icon class="cursor-pointer" icon="SearchIcon" />
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</li>
			</template>
		</b-tabs>
		<ManyResults v-if="controllerManyResults" :results="results" @hidden="controllerManyResults = false" />
	</div>
</template>

<script>
import SpecimensPage from "../SpecimensPage.vue"
import SpecimenService from "@/services/specimens.service"
import DeadPlateGrid from "@/views/amg/deads/DeadPlateGrid"
import { bus } from "@/main"
import ManyResults from "./ManyResults.vue"
import specimensService from "@/services/specimens.service"
import axios from "@/axios"

export default {
	name: "SpecimensMainPage",
	props: { idTab: Number },
	components: {
		DeadPlateGrid,
		SpecimensPage,
		ManyResults,
	},
	created() {
		bus.$on("RefreshCounters", this.refreshCounters)
		this.getSpecimensCounter()
		
	},
	computed: {
		padrilloCounter() {
			for (const { category, counter } of this.counters) {
				if (category === 1) return counter
			}
			return 0
		},
		madrillaCounter() {
			for (const { category, counter } of this.counters) {
				if (category === 2) return counter
			}
			return 0
		},
		galloCounter() {
			for (const { category, counter } of this.counters) {
				if (category === 3) return counter
			}
			return 0
		},
		gallinaCounter() {
			for (const { category, counter } of this.counters) {
				if (category === 4) return counter
			}
			return 0
		},
		pollonCounter() {
			for (const { category, counter } of this.counters) {
				if (category === 5) return counter
			}
			return 0
		},
		pollaCounter() {
			for (const { category, counter } of this.counters) {
				if (category === 6) return counter
			}
			return 0
		},
		deadsCounter() {
			for (const { category, counter } of this.counters) {
				if (category === 7) return counter
			}
			return 0
		},
		menuTabs() {
			return [
				{
					title: "Padrillos",
					counter: this.padrilloCounter,
					category_id: 1,
				},
				{
					title: "Madrillas",
					counter: this.madrillaCounter,
					category_id: 2,
				},
				{
					title: "Gallos",
					counter: this.galloCounter,
					category_id: 3,
				},
				{
					title: "Gallinas",
					counter: this.gallinaCounter,
					category_id: 4,
				},
				{
					title: "Pollones",
					counter: this.pollonCounter,
					category_id: 5,
				},
				{
					title: "Pollas",
					counter: this.pollaCounter,
					category_id: 6,
				},
				// {
				// 	title: "Muertos",
				// 	counter: this.deadsCounter,
				// 	category: "muertos",
				// },
			]
		},
	},
	data() {
		return {
			counter: 0,
			counters: [],
			selectedTab: 0,
			search: "",
			controllerManyResults: false,
			results: [],
		}
	},
	methods: {
		async globalSearch() {
			if (this.search.length < 2) return
			this.isPreloading()
			const { data } = await SpecimenService.globalSearch(this.search)
			const { results, message } = data
			if (results.length > 1) {
				this.controllerManyResults = true
				this.results = data.results
			} else {
				this.selectedTab = (results[0]?.category.id || 1) - 1
				this.$refs.specimenPage[0].$refs.refUserListTable.refresh()
			}
			this.isPreloading(false)
		},
		refreshCounters() {
			this.getSpecimensCounter()
			// this.getSpecimensSeparatedCounter()
		},
		async getSpecimensCounter() {
			try {
				const { data } = await specimensService.getCountersByCategory(this.idTab == 2 ? 0 : 1)
				this.counters = data
			} catch (error) {
				console.log("error: ", error)
			}
		},
	},
	beforeDestroy() {
		bus.$off("RefreshCounters", this.refreshCounters)
	},
}
</script>

<style lang="scss">
.sp-nav-item {
	width: 48%;
	@media screen and (min-width: 40em) {
		width: 30%;
	}
	@media screen and (min-width: 55em) {
		width: 28%;
	}
	@media screen and(min-width: 60em) {
		width: initial;
	}
}
.sp-nav-search {
	width: 100%;
	@media screen and(min-width: 60em) {
		max-width: 35rem;
	}
}
</style>

<template>
	<b-overlay class="px-2" :show="isLoading">
		<div class="container-tree">
			<template v-for="(chick, i) in tabGeneral == 3 ? datasNephe : tabGeneral == 4 ? dataChildren : datas">
				<div v-if="(!halfBro && !chick.specimens.length == 0) || halfBro && chick.chicks.length > 0">
					<b-card footer-class="d-block text-center h4" style="width: 15rem"
						v-if="![3, 4].includes(tabGeneral)">
						<SpecimenPlate v-if="
							(selectSpecimen == 'madrilla' && !halfBro && !chick.specimens.length == 0) ||
							(selectSpecimen == 'madrilla' && halfBro && chick.chicks.length > 0)
						" :specimen="{
							plate: chick.padrillo.plate,
							alias: chick.padrillo.alias,
							id: chick.padrillo.id,
							thumb: chick.padrillo.thumb,
							image: chick.padrillo.image,
						}" />

						<SpecimenPlate v-if="
							(selectSpecimen == 'padrillo' && !halfBro && !chick.specimens.length == 0) ||
							(selectSpecimen == 'padrillo' && halfBro && chick.chicks.length > 0)
						" :specimen="{
							plate: chick.madrilla.plate,
							alias: chick.madrilla.alias,
							id: chick.madrilla.id,
							thumb: chick.madrilla.thumb,
							image: chick.madrilla.image,
						}" />
					</b-card>
					<b-card footer-class="d-block text-center h4" style="width: 15rem" v-else>
						<div class="d-flex">
							<div>
								<SpecimenPlate :specimen="{
									plate: chick.padrillo.plate,
									alias: chick.padrillo.alias,
									id: chick.padrillo.id,
									thumb: chick.padrillo.thumb,
									image: chick.padrillo.image,
								}" />
							</div>
							<div class="px-4">
								<SpecimenPlate :specimen="{
									plate: chick.madrilla.plate,
									alias: chick.madrilla.alias,
									id: chick.madrilla.id,
									thumb: chick.madrilla.thumb,
									image: chick.madrilla.image,
								}" />
							</div>
						</div>
					</b-card>
					<div class="container-family">
						<div v-if="!halfBro && !chick.specimens.length == 0">
							<ContainerGenderCard title="MACHOS"
								:specimens="chick.specimens.filter((item) => item && item.specimen.gender_id == 1)"
								:padding="true" />
						</div>
						<div v-if="!halfBro && !chick.specimens.length == 0">
							<ContainerGenderCard title="HEMBRAS"
								:specimens="chick.specimens.filter((item) => item && item.specimen.gender_id == 2)"
								:padding="true" />
						</div>
						<div v-if="halfBro && chick.chicks.length > 0" class="container-pollos">
							<ContainerGenderCard title="POLLOS" :chicks="chick.chicks" :correlative="true" />
						</div>
					</div>
				</div>
			</template>
		</div>
		<div class="text-texts" v-if="datas.length == 0 & !nephews">Sin medio hermano</div>
		<div class="text-texts" v-if="datasNephe.length == 0 && nephews">Sin sobrinos</div>
	</b-overlay>
</template>
<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ContainerGenderCard from "@/views/amg/specimens/dashboard/modals/ContainerGenderCard.vue"
import specimenService from "@/services/specimens.service.js"

export default {
	components: {
		SpecimenPlate,
		ContainerGenderCard,
	},
	props: {
		specimen: {
			type: Object,
		},
		selectSpecimen: {
			type: String,
		},
		tab: {
			type: Number,
		},
		halfBro: {
			type: Number,
		},
		tabGeneral: {
			type: Number,
		},
		nephews: {
			type: Number,
		},
	},
	data: () => ({
		// brother: [],
		datas: [],
		datasNephe: [],
		dataChildren: [],
		// chicks: [],
		isLoading: false,
	}),
	created() {
		this.initBrother()
		this.initNephews()
		this.initChildren()
	},
	methods: {
		async initBrother() {
			this.isPreloading()
			const { data } = await specimenService.getBrothers({
				specimen_id: this.specimen.id,
				filter_by: this.selectSpecimen,
			})
			this.datas = data
			this.isPreloading(false)
		},

		async initNephews() {
			this.isPreloading()
			const { data } = await specimenService.getNephews({
				specimen_id: this.specimen.id,
			})
			this.datasNephe = data
			this.isPreloading(false)
		},

		async initChildren() {
			this.isPreloading()
			const { data } = await specimenService.getChildrem({
				specimen_id: this.specimen.id,
			})
			this.dataChildren = data
			this.isPreloading(false)
		},
	},

	watch: {
		tab(newVal, oldVal) {
			this.initBrother()
		},
	},
	watch: {
		selectSpecimen(newVal, oldVal) {
			this.initBrother()
		},
	},
}
</script>

<style scoped>
.container-family {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;

	@media screen and (min-width: 1024px) {
		grid-template-columns: 1fr 1fr;
	}
}

.border-specimen {
	border: 2px solid #f2f2f2;
	padding: 0.5rem;
}

.container-tree {
	padding-top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.text-texts {
	text-align: center;
	font-size: 2rem;
	color: #4b4b4b;
}

.container-pollos {
	width: 70%;
}
</style>

<template #default>
    <b-modal
		centered
		:title="'Ejemplares Inactivos'"
		size="xl"
		:hide-footer="true"
		v-model="showModal"
		@hidden="$emit('closing')"
        >
        <b-tabs lazy v-model="tabIndex">
			<b-tab v-for="(category,i) in counters" :key="i">
				<template slot="title">
					<div class="m-05">
						{{ category.description }}
						<b-badge v-if="category.counter" variant="danger" >
							{{ category.counter }}
						</b-badge>
					</div>
				</template>
                <SpecimensInactivesComponent :categoryId="category.id" />
            </b-tab>
        </b-tabs>
    </b-modal>
</template>

<script>
import SpecimensInactivesComponent from './SpecimensInactives.vue';

export default {
	name: "SpecimensInactivesModal",
	components: {
		SpecimensInactivesComponent,
	},
	props: {
		categoryId: {
			type: Number,
			default: 1,
		},
        show: Boolean,
		counters:{
			type: Array,
			default:[]
		}
	},
	data() {
		return {
			showModal: false,
			tabIndex: null
		};
	},
    watch:{
        show(val){
            if(val){
                this.showModal = true
				this.tabIndex = this.categoryId - 1
            }
        }
    }
};
</script>

<style lang="scss"></style>
<template>
	<b-modal
		centered
		no-close-on-backdrop
		title="Acciones clinicas masivas"
		size="lg"
		v-model="isActive"
		@hidden="handleHidden"
	>
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-form-group label="1. Seleccionar objetivo">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<v-select
							v-model="objective"
							label="text"
							:options="[
								{ value: 1, text: 'Padrillos' },
								{ value: 2, text: 'Madrillas' },
								{ value: 3, text: 'Gallos' },
								{ value: 4, text: 'Gallinas' },
								{ value: 5, text: 'Pollones' },
								{ value: 6, text: 'Pollas' },
								{ value: 7, text: 'Todos los ejemplares' },
								{ value: 8, text: 'Seleccionar ejemplar(es)' },
							]"
							:reduce="(option) => option.value"
							:clearable="false"
							transition=""
							placeholder="Seleccionar"
						/>
						<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
					</ValidationProvider>
				</b-form-group>
				<div class="" v-if="objective == 8">
					<b-form-group label="Buscar ejemplares">
						<b-input-group>
							<b-form-input
								placeholder="Ingrese placa o alias"
								v-model="search"
								@keyup.enter="searchSpecimens"
							/>
							<b-input-group-append>
								<b-button variant="outline-primary" @click="searchSpecimens">
									<feather-icon icon="SearchIcon" />
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<b-form-group label="Ejemplares seleccionados" class="bg-specimen-add">
						<b-row>
							<b-col
								class="text-center my-1 d-flex align-items-center justify-content-center"
								cols="6"
								v-for="specimen in selectedSpecimens"
								:key="specimen.id"
							>
								<div class="position-relative clickable px-3">
									<SpecimenPlate :specimen="specimen" />
									<div class="optAction optAction--remove" @click="removeSelected(specimen)">
										<feather-icon icon="Trash2Icon" size="24" />
									</div>
								</div>
							</b-col>
							<b-col class="text-center" v-if="selectedSpecimens.length == 0">
								No hay ejemplares seleccionados
							</b-col>
						</b-row>
					</b-form-group>
				</div>
				<!--  -->
				<b-form-group label="2. Seleccionar accion">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<b-form-radio-group
							v-model="action"
							:options="[
								{ value: 1, text: 'Desparacitacion' },
								{ value: 2, text: 'Suplementos' },
							]"
						/>
						<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
					</ValidationProvider>
				</b-form-group>
				<template v-if="action == 2">
					<b-form-group label="3. Suplemento">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								label="text"
								:class="{ 'border-danger rounded': errors[0] }"
								:options="vitaminOpts"
								:reduce="(opt) => opt.value"
								v-model="vitamin"
							/>
						</ValidationProvider>
					</b-form-group>
					<b-form-group label="4. Tipo de suplemento">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<b-form-radio-group
								:options="[
									{ value: 1, text: 'Frecuente' },
									{ value: 2, text: 'Ocasional' },
								]"
								v-model="vitaminType"
							/>
							<small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
						</ValidationProvider>
					</b-form-group>
					<b-form-group v-if="vitaminType == 1" label="5. Días">
						<v-select
							v-model="vitaminDays"
							label="text"
							style="width: 100%"
							multiple
							reverse
							:clearable="false"
							:options="days"
							:reduce="(opt) => opt.value"
						/>
					</b-form-group>
				</template>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button @click="applyAction" variant="primary" :disabled="isLoading">Aplicar</b-button>
		</template>
		<b-modal centered hide-footer title="Seleccione un ejemplar" size="lg" v-model="optionsModal.show">
			<b-row>
				<b-col cols="6" v-for="specimen in optionsModal.opts" :key="specimen.id">
					<div class="position-relative clickable mb-1">
						<SpecimenPlate :specimen="specimen" />
						<div class="optAction optAction--add" @click="selectSpecimen(specimen)">
							<feather-icon icon="PlusIcon" size="24" />
						</div>
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</b-modal>
</template>

<script>
import specimenService from "@/services/specimens.service"
import preparacionSettingService from "@/services/preparacion/settings.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import { PxNoteMultiple } from "oh-vue-icons/icons"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		objective: null,
		search: null,
		selectedSpecimens: [],
		action: null,
		vitamin: null,
		vitaminType: null,
		vitaminDays: [],
		vitaminOpts: [],
		optionsModal: { show: false, opts: [] },
		days: [
			{ text: "Lunes", value: 1 },
			{ text: "Martes", value: 2 },
			{ text: "Miercoles", value: 3 },
			{ text: "Jueves", value: 4 },
			{ text: "Viernes", value: 5 },
			{ text: "Sabado", value: 6 },
			{ text: "Domingo", value: 7 },
		],
	}),
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.objective = null
			this.search = null
			this.selectedSpecimens = []
			this.action = null
			this.vitamin = null
			this.vitaminDays = []
			this.vitaminType = null
			this.optionsModal = { show: false, opts: [] }
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await preparacionSettingService.getVitamins({ status: 1 })
			this.vitaminOpts = data.map((opt) => ({
				value: opt.id,
				text: opt.name,
			}))
			this.isLoading = false
		},
		async searchSpecimens() {
			this.isLoading = true
			const { data } = await specimenService.searchSpecimenToClinical({ search: this.search })
			this.search = null
			if (data.length == 0) {
				this.showToast("warning", "top-right", "Sin resultados", "SuccessIcon", "Ejemplar no disponible")
				this.isLoading = false
				return
			}
			if (data.length == 1) {
				if (!this.selectedSpecimens.some((it) => it.id == data[0].id)) {
					this.selectedSpecimens.push(data[0])
					this.showToast("info", "top-right", "Preparación", "InfoIcon", "El ejemplar se agregó con éxito")
				} else {
					this.showToast(
						"warning",
						"top-right",
						"Preparación",
						"InfoIcon",
						"El ejemplar ya fue agregado anteriormente"
					)
				}

				this.isLoading = false
				return
			}
			this.optionsModal.opts = data
			this.optionsModal.show = true
			this.isLoading = false
		},
		selectSpecimen(specimen) {
			let duplicated = this.selectedSpecimens.some((selected) => selected.id == specimen.id)
			if (duplicated) {
				this.showToast(
					"warning",
					"top-right",
					"Duplicado",
					"InfoIcon",
					"El ejemplar ya se encuentra seleccionado"
				)
				return
			}
			this.selectedSpecimens.push(specimen)
			this.optionsModal.opts = []
			this.optionsModal.show = false
		},
		removeSelected(specimen) {
			let idx = this.selectedSpecimens.findIndex((selected) => selected.id == specimen.id)
			if (idx == -1) return
			this.selectedSpecimens.splice(idx, 1)
		},
		async applyAction() {
			let isDaysNull = this.vitaminDays.__ob__.value.length
			if (!isDaysNull && this.vitaminType == 1) {
				this.showToast(
					"warning",
					"top-right",
					"No hay días seleccionados",
					"InfoIcon",
					"Debe marcar al menos un día de la semana"
				)
				return
			}
			if (!(await this.$refs.form.validate())) return
			if (this.selectedSpecimens.length == 0 && this.objective == 8) {
				this.showToast(
					"warning",
					"top-right",
					"Acciones clinicas",
					"InfoIcon",
					"Debes seleccionar al menos 1 ejemplar"
				)
				return
			}
			const specimens = this.selectedSpecimens.map((s) => s.id)
			const numCategory = new Map()
			numCategory.set(1, "PADRILLOS")
			numCategory.set(2, "MADRILLAS")
			numCategory.set(3, "GALLOS")
			numCategory.set(4, "GALLINAS")
			numCategory.set(5, "POLLONES")
			numCategory.set(6, "POLLAS")
			numCategory.set(7, "TODOS")

			let confirmMsg = "Se aplicara "
			if (this.action == 1) confirmMsg += "DESPARACITACION"
			if (this.action == 2) confirmMsg += "SUPLEMENTO"
			confirmMsg += " a "
			if ([1, 2, 3, 4, 5, 6, 7].includes(this.objective)) {
				confirmMsg += `la categoria ${numCategory.get(this.objective)}`
			} else {
				confirmMsg += `${specimens.length} ejemplare(s)`
			}
			const { isConfirmed } = await this.showConfirmSwal({
				title: confirmMsg,
			})
			if (!isConfirmed) return

			this.isLoading = true
			if ([1, 2, 3, 4, 5, 6, 7].includes(this.objective)) {
				// nulo cuando el objetivo es todos los ejemplares
				const fixedObjective = this.objective == 7 ? null : this.objective
				if (this.action == 1) {
					await specimenService.insertMassiveDewornByCategory({ category_id: fixedObjective })
				} else {
					await specimenService.insertMassiveVitaminByCategory({
						category_id: fixedObjective,
						type: this.vitaminType,
						vitamin_id: this.vitamin,
						days: !isDaysNull ? null : this.vitaminDays,
					})
				}
			} else if (this.objective == 8) {
				if (this.action == 1) {
					await specimenService.insertMassiveDeworn({ specimens })
				} else {
					await specimenService.insertMassiveVitamin({
						specimens,
						type: this.vitaminType,
						vitamin_id: this.vitamin,
						days: !isDaysNull ? null : this.vitaminDays,
					})
				}
			}

			let successMsg = `${this.action == 1 ? "Desparacitacion" : "Suplemento"} correctamente aplicada a `
			if ([1, 2, 3, 4, 5, 6, 7].includes(this.objective)) {
				successMsg += `la categoria ${numCategory.get(this.objective)}`
			} else {
				successMsg += `${specimens.length} ejemplare(s)`
			}

			this.showToast("success", "top-right", "Acciones clinicas", "InfoIcon", successMsg)
			this.handleHidden()
			this.isLoading = false
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getOpts()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.optAction {
	position: absolute;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.2rem;
	color: black;
	opacity: 0.8;
	border-radius: 0.2rem;

	&--add {
		background: rgba(0, 255, 0, 0.2);
	}

	&--remove {
		background: rgba(255, 0, 0, 0.2);
	}
}
</style>

<template>
	<div>
		<!-- <pre>tab {{ idTab }}</pre> -->
		<b-row>
			<!-- <b-col cols="12" md="4" class="mb-1">
				<div class="mr-2 cross-center gap-x-1" v-if="[1, 2].includes(categoryId)">
					<span class="text-primary">AÑO</span>
					<v-select
						v-model="year"
						label="season"
						:options="years"
						:clearable="false"
						:reduce="(opt) => opt.id"
						:style="{ maxWidth: '11ch', minWidth: '11ch' }"
					/>
				</div>
			</b-col> -->

			<!-- <b-col cols="12" md="12" class="mb-1">
				<div class="d-flex justify-content-end" style="gap: 15px">
					<b-button variant="secondary" class="position-relative" @click="openSpecimensInactivesModal()">
						INACTIVOS
						<b-badge
							v-if="totalInactives"
							variant="danger"
							style="top: -8px; right: -8px"
							class="position-absolute"
						>
							{{ totalInactives }}
						</b-badge>
					</b-button>
					<b-button
						v-if="[1, 2].includes(categoryId)"
						variant="secondary"
						class="position-relative"
						@click="openParentsDead()"
					>
						MUERTOS
						<b-badge variant="danger" style="top: -8px; right: -8px" class="position-absolute">
							{{ counterParent }}
						</b-badge>
					</b-button>
				</div>
			</b-col> -->
		</b-row>

		<custom-light-box
			:items="images"
			:index="index"
			:effect="'fade'"
			@close="index = null"
			@on-click-info="openInfo"
		/>

		<transition name="fade">
			<filters-component
				:filters="clearFilters"
				v-if="filterController"
				@onChangeFilter="onChangeFilter"
			></filters-component>
		</transition>

		<b-card no-body class="mb-1">
			<div class="m-2">
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
						>
							<span class="text-muted">
								Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>
					<!-- Search -->
					<b-col cols="12" xl="8" class="mt-1">
						<div class="container-btns-page-specimens">
							<div class="d-flex">
								<b-form-input
									v-model="search_input"
									class="d-inline-block mr-1"
									placeholder="Placa o alias..."
									@keyup.enter="$refs.refUserListTable.refresh()"
									debounce="500"
								/>
								<b-button
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									variant="outline-primary"
									class="btn-icon mr-1"
									id="filters"
									@click="filterController = !filterController"
								>
									<feather-icon icon="FilterIcon" />
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12" md="4" class="mt-1">
						<div class="mr-2 cross-center gap-x-1" v-if="[1, 2].includes(categoryId)">
							<label class="" style="font-size: 12px">Temporada de encaste:</label>
							<v-select
								v-model="year"
								label="season"
								:options="years"
								:clearable="false"
								:reduce="(opt) => opt.id"
								:style="{ maxWidth: '11ch', minWidth: '11ch' }"
							/>
						</div>
					</b-col>
				</b-row>
			</div>
			<div class="table-responsive">
				<b-table
					:api-url="isFromSpecimenPage ? '/api/specimens' : '/api/specimens/report-without-posturas'"
					ref="refUserListTable"
					class="position-relative"
					:items="myProvider"
					:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
					sticky-header="75vh"
					:fields="visibleFields"
					primary-key="id"
					table-class="text-nowrap"
					responsive
					small
					show-empty
					:busy.sync="isBusy"
					empty-filtered-text="No hay registros que coincidan con tu búsqueda."
					empty-text="No hay registros que mostrar."
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:current-page="current_page"
					:per-page="perpage"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>

					<template #thead-top="{}">
						<template>
							<b-th :colspan="categoryId === 1 ? 4 : 3" class="bg-transparent border-0"></b-th>
							<b-th
								v-if="[1, 2].includes(categoryId)"
								colspan="5"
								class="bg-info bg-darken-1 text-white text-center"
							>
								<div class="sons-specimens-v2">
									<p class="mb-0">Hijos vivos</p>
								</div>
							</b-th>
							<b-th
								v-else
								:colspan="categoryId == 3 ? 3 : categoryId == 4 ? 1 : categoryId == 5 ? 2 : 1"
								class="bg-transparent border-0"
							></b-th>
							<b-th :colspan="categoryId === 1 ? 5 : 5" class="bg-transparent border-0"></b-th>
							<!-- <b-th colspan="2" class="bg-info bg-darken-1 text-white text-center" v-if="!isInvitado">
								<div class="sons-specimens-v2">
									<p class="mb-0">Web</p>
								</div>
							</b-th> -->
						</template>
					</template>
					<template #cell(specimen)="{ item }">
						<SpecimenPlate
							:specimen="{
								plate: item.plate,
								alias: item.alias,
								id: item.id,
								thumb: item.thumb,
								image: item.image,
								ran: item.ran_o == 1 || item.ran_r > 0 ? true : false,
								guilty: item.ran_r > 0 && item.ran_o == 1 ? true : false,
							}"
						/>

						<div class="d-flex align-items-center justify-content-center mt-05 mb-05">
							<span
								class="text-primary d-flex align-items-center justify-content-center"
								v-if="item.average_cap"
								v-b-tooltip.hover="`Puntaje de preparacion`"
							>
								<span
									style="font-weight: bold; font-size: 16px; height: 16px; margin-top: 3px"
									class="mr-25 d-flex align-items-center"
								>
									{{ Number(item.average_cap).toFixed(2) }}
								</span>
								<feather-icon
									class="cursor-pointer star-score text-warning mt-0"
									size="18"
									icon="StarIcon"
								/>
							</span>

							<i
								v-if="[1, 3].includes(categoryId) && item.counter_victories > 0"
								v-b-tooltip.hover="`${item.counter_victories} Victorias`"
								class="fa-solid fa-flag ml-25"
								style="color: #28c76f; font-size: 1.2rem; padding-right: 0.3rem"
							></i>

							<div
								v-if="(categoryId == 1 || categoryId == 2) && item.childs_behavior.length > 0"
								@click="goIdSpecimen(item.childs_behavior[0].specimen_id)"
								v-b-tooltip.hover="
									`Hijo corrido: ${
										item.childs_behavior[0].alias
											? item.childs_behavior[0].alias
											: item.childs_behavior[0].plate
									}`
								"
								style="padding-left: 0.5rem"
								class="cursor-pointer"
							>
								<img src="@/assets/images/svg/pawchi.svg" class="img-paw" />
							</div>

							<div
								v-else-if="item.behavior == 'rajado'"
								v-b-tooltip.hover="`Corrido`"
								class="cursor-pointer"
							>
								<img src="@/assets/images/svg/paw1.svg" class="img-paw" />
							</div>
							<!-- @click="openModalListSibling(item)" -->
							<div
								@click="goIdSpecimen(item.siblings[0].specimen_id)"
								v-else-if="item.siblings.length > 0"
								v-b-tooltip.hover="
									`Hermano corrido: ${
										item.siblings[0].alias ? item.siblings[0].alias : item.siblings[0].plate
									}`
								"
								style="padding-left: 0.5rem"
							>
								<img src="@/assets/images/svg/paw2.svg" class="img-paw" />
							</div>
							<!-- @click="openModalListSibling(item)" -->
							<div
								v-else-if="item.stepBrother.length > 0"
								@click="goIdSpecimen(item.stepBrother[0].specimen_id)"
								v-b-tooltip.hover="
									`Medio Hermano corrido: ${
										item.stepBrother[0].alias
											? item.stepBrother[0].alias
											: item.stepBrother[0].plate
									}`
								"
								style="padding-left: 0.5rem"
							>
								<img src="@/assets/images/svg/paw3.svg" class="img-paw" />
							</div>

							<div v-else></div>

							<div class="position-relative">
								<feather-icon
									@click="openIncidenceDetail(item)"
									v-b-tooltip.hover="'Ver incidencias'"
									class="text-primary"
									:class="{ 'icon-disabled': item.incidence_counter === 0 }"
									icon="AlertOctagonIcon"
									size="19"
									style="margin-left: 0.35rem; cursor: pointer"
								/>
								<span v-if="item.incidence_counter > 0" class="incidences-counter">
									{{ item.incidence_counter }}
								</span>
							</div>
						</div>
					</template>

					<template #cell(line)="{ item }">
						<div class="d-flex justify-content-center">
							<div
								v-b-tooltip.hover
								:title="textLine(item.percentage_line)"
								style="height: 18px; width: 18px; border-radius: 50%"
								:class="[colorLine(item.percentage_line)]"
							></div>
						</div>
					</template>

					<template #cell(race)="{ item }">
						<div class="d-flex justify-content-center">
							<div
								v-b-tooltip.hover
								:title="textRace(item.races.status)"
								style="height: 18px; width: 18px; border-radius: 50%"
								:class="[colorRace(item.races.status)]"
							></div>
						</div>
					</template>

					<template #cell(video)="data">
						<b-button
							:disabled="data.item.count_movies == 0"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							:variant="data.item.count_movies == 0 ? 'outline-danger' : 'outline-primary'"
							class="btn-icon rounded-circle"
							@click="setViewVideoBySpecimen(data.item)"
						>
							<feather-icon icon="FilmIcon" />
						</b-button>
					</template>

					<template #cell(dob)="{ item }">
						<div v-if="item.dob" class="text-center" style="padding-top: 5px; padding-bottom: 5px">
							<SpecimenAge grid_specimen :age="item.dob" />
							<strong v-if="item.dob" class="d-block text-center" style="padding-top: 3px">
								{{ item.dob | myGlobalDayShort }}
							</strong>
							<p v-else>---</p>
						</div>
						<div v-else>
							<FeatherIcon
								v-b-tooltip.hover
								title="Agregar fecha"
								icon="PlusIcon"
								size="18"
								class="cursor-pointer text-warning"
								@click="addDobSpecimen(item)"
							/>
						</div>
					</template>

					<template #cell(created_at_date)="data">
						<p v-html="data.item.created_at"></p>
					</template>

					<template #cell(cintillo_patas)="{ item }">
						<div class="sons-specimens-v2">
							<p
								@click="clickSonsBySpecimenv2(item, 0)"
								class="cursor-pointer text-primary font-weight-bold"
							>
								{{ item.cintillo_pata || 0 }}
							</p>
						</div>
					</template>

					<template #cell(chicks_machos)="{ item }">
						<div class="sons-specimens-v2">
							<p
								@click="clickSonsBySpecimenv2(item, 1)"
								class="cursor-pointer text-primary font-weight-bold"
							>
								{{ item.machos }}
							</p>
						</div>
					</template>

					<template #cell(chicks_hembras)="{ item }">
						<div class="sons-specimens-v2">
							<p
								@click="clickSonsBySpecimenv2(item, 2)"
								class="cursor-pointer text-primary font-weight-bold"
							>
								{{ item.hembras }}
							</p>
						</div>
					</template>

					<template #cell(chicks_sa)="{ item }">
						<div class="sons-specimens-v2">
							<p
								@click="clickSonsBySpecimenv2(item, 3)"
								class="cursor-pointer text-primary font-weight-bold"
							>
								{{ item.sin_asignar }}
							</p>
						</div>
					</template>

					<template #cell(chicks_total)="{ item }">
						<div class="sons-specimens-v2">
							<p
								@click="clickSonsBySpecimenv2(item, 4)"
								class="cursor-pointer text-primary font-weight-bold"
							>
								{{ sumHasIsNumber(item.cintillo_pata, item.hembras, item.machos, item.sin_asignar) }}
							</p>
						</div>
					</template>

					<template #cell(chicks_global)="{ item }">
						<div class="sons-specimens-v2">
							<p
								@click="openSidebarChicksGlobal(item)"
								class="cursor-pointer text-primary font-weight-bold"
							>
								{{ parseInt(item.cd_global) + parseInt(item.cp_global) }}
							</p>
						</div>
					</template>

					<template #cell(quantity_posturas_padrillos)="{ item }">
						<span
							@click="clickNumberPostura(item)"
							class="cursor-pointer text-primary font-weight-bold padrillos"
						>
							{{ item.count_posturas }}
						</span>
					</template>
					<template #cell(deads_childs_total)="{ item }">
						<!-- @click="clickNumberPostura(item)" -->
						<span
							class="cursor-pointer text-danger font-weight-bold padrillos"
							v-if="
								Number(item.deads_ca || 0) + Number(item.deads_cp || 0) + Number(item.deasd_spe || 0) !=
									0 || !item.deads_childs_total
							"
							@click="
								;(childDeads.info = { specimen: item }),
									(childDeads.show = !childDeads.show),
									(childDeads.id = item.id)
							"
						>
							{{ item.deads_childs_total }}
						</span>
						<span v-else class="text-primary font-weight-bold padrillos">0</span>
					</template>

					<template #cell(quantity_posturas_madrillas)="{ item }">
						<span
							@click="clickNumberPostura(item)"
							class="cursor-pointer text-primary font-weight-bold madrillos"
						>
							{{ item.count_posturas }}
						</span>
					</template>

					<template #cell(tournaments)="data">
						<FeatherIcon
							@click="clickTournamentsBySpecimen(data.item.id, data.item)"
							icon="EyeIcon"
							class="cursor-pointer"
							size="20"
							:color="data.item.tournaments_specimen > 0 ? '#7367f0' : '#9e9e9e'"
						/>
					</template>

					<template #cell(origin)="{ item }">
						<div class="d-flex justify-content-center align-items-center">
							<span>{{ item.origin }}</span>
						</div>
					</template>

					<template #cell(topes)="{ item }">
						<div v-if="item.topes.quantity">
							<b-button
								size="sm"
								variant="flat-secondary"
								@click=";(topeList.info = item), (topeList.show = !topeList.show)"
							>
								{{ item.topes.quantity }}
							</b-button>
							<br />
							<small>{{ item.topes.last_date | myGlobalDayShort }}</small>
						</div>
						<span v-else>---</span>
					</template>

					<template #cell(status)="{ item }">
						<div class="d-flex justify-content-center align-items-center">
							<div class="d-flex flex-column align-items-center justify-content-center">
								<div class="d-flex align-items-center justify-content-center gap-x-1">
									<feather-icon
										size="18"
										v-b-tooltip.hover.top="item.status_main === 1 ? 'Disponible' : 'No disponible'"
										:class="{
											'text-danger': item.status_main === 0,
											'text-success': item.status_main === 1,
										}"
										icon="CheckCircleIcon"
									></feather-icon>
									<span style="font-size: 13px">
										{{ item.status }}
									</span>
								</div>
								<span v-if="item.status_id == 10 && item.training_status != null" class="text-primary">
									{{ `(${item.training_status})` }}
								</span>
							</div>

							<feather-icon
								icon="Edit2Icon"
								class="text-warning cursor-pointer ml-2"
								v-if="!isInvitado"
								@click="openSpecimenStatusChanged(item)"
							/>
						</div>
					</template>

					<template #cell(fertilidad)="{ item }">
						{{ item.fertilidad > 0 ? parseFloat(item.fertilidad) + "%" : "" }}
						<template v-if="item.count_posturas > 0">
							<br />
						</template>
						<small v-if="item.fertilidad_accumulated > 0">
							Acumulada: {{ item.fertilidad_accumulated }}%
						</small>
						<small v-else>---</small>
					</template>

					<template #cell(madrilla)="{ item }">
						<SpecimenPlate
							:specimen="{
								plate: item.madrilla.plate,
								alias: item.madrilla.alias,
								id: item.madrilla.id,
								thumb: item.madrilla.thumb,
								image: item.madrilla.image,
							}"
							not_image
						/>
					</template>

					<template #cell(padrillo)="{ item }">
						<SpecimenPlate
							:specimen="{
								plate: item.padrillo.plate,
								alias: item.padrillo.alias,
								id: item.padrillo.id,
								thumb: item.padrillo.thumb,
								image: item.padrillo.image,
							}"
							not_image
						/>
					</template>

					<template #cell(tracking_weight)="{ item }">
						<div class="mr-1">
							<span v-if="item.weight">
								{{ item.weight }}
								<b>kg</b>
							</span>
							<br />
							<small v-if="item.pounds_weight">
								{{ item.pounds_weight }}
								<b>lb</b>
							</small>
						</div>
						<feather-icon
							icon="PlusIcon"
							class="cursor-pointer"
							size="20"
							v-if="!isInvitado"
							@click="openAddSpecimenWeightModal(item)"
						/>
						<feather-icon
							icon="ListIcon"
							class="cursor-pointer"
							@click="openSpecimenWeightListModal(item)"
							v-if="Number(item.weight_counter) > 0"
						/>
					</template>

					<template #cell(actions)="data">
						<div class="d-flex align-items-center justify-content-center">
							<feather-icon
								icon="TrashIcon"
								:id="`delete-tooltip${data.index}`"
								class="cursor-pointer text-danger"
								size="20"
								@click="delete_specimen(data.item.id)"
								v-if="isAdmin"
							/>
						</div>
					</template>

					<template #cell(web)="data">
						<span v-if="data.item.it_was_sold == 1 || ![1, 9, 10].includes(data.item.status_id)">---</span>
						<ChangeSpecimenToPublic
							v-else
							:specimen="{ ...data.item, is_public: data.item.is_public == 1 ? true : false }"
							:reload="false"
						/>
					</template>

					<template #cell(venta)="data">
						<span v-if="data.item.it_was_sold == 1 || ![1, 9, 10].includes(data.item.status_id)">---</span>
						<!-- <p>ddd</p> -->
						<template v-else-if="!data.item.sale">
							<ChangeSpecimenToSale
								:specimen="{
									...data.item,
									is_sale: data.item.sale ? true : false,
								}"
								@refresh="$refs['refUserListTable'].refresh()"
							/>
						</template>
						<div v-else>
							<ChangeSpecimenToSale
								v-if="[1, 2].includes(data.item.sale.status_id)"
								:hasEdit="false"
								hasTracking
								:specimen="{
									...data.item,
									is_sale: data.item.sale && data.item.sale.status_id == 1 ? true : false,
								}"
								:price="data.item.sale.price"
								:currency_type="data.item.sale.currency_id"
								@refresh="$refs['refUserListTable'].refresh()"
							/>
							<p v-else class="mb-0">RESERVADO</p>
						</div>
					</template>

					<template #cell(mmwbo)="data">
						<ChangeSpecimenEggEater
							:info="data.item"
							:type="'madrilla'"
							@refresh="$refs['refUserListTable'].refresh()"
						/>
					</template>
					<template #cell(pisa)="data">
						<ChangeSpecimenEggEater
							:info="data.item"
							:type="'padrillo'"
							@refresh="$refs['refUserListTable'].refresh()"
						/>
					</template>

					<template #cell(nursing_status_id)="data">
						<div class="d-flex justify-content-center align-items-center gap-x-2">
							<div v-b-tooltip.hover title="Ejemplar en espera" v-if="data.item.nursing_status_id == 1">
								<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
							</div>
							<div
								v-b-tooltip.hover
								title="Ejemplar en proceso"
								v-else-if="data.item.nursing_status_id == 2"
							>
								<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
							</div>
							<b-button
								class="btn-icon"
								size="sm"
								variant="flat-primary"
								@click="
									;(addNursery.info = { specimen: data.item }), (addNursery.show = !addNursery.show)
								"
								v-if="!isInvitado && data.item.nursing_status_id === null"
								v-b-tooltip.hover
								title="ENVIAR A ENFERMERIA"
							>
								<v-icon name="md-healthandsafety-outlined" scale="1.2" class="text-danger" />
							</b-button>
							<b-button
								class="btn-icon"
								size="sm"
								variant="flat-secondary"
								@click="
									;(nurseryTracking.info = { specimen: data.item, _specimen_id: data.item.id }),
										(nurseryTracking.show = !nurseryTracking.show)
								"
							>
								<feather-icon icon="ListIcon" />
							</b-button>
						</div>
					</template>

					<template #cell(reason)="data">
						<div class="d-flex align-items-center justify-content-center">
							<span style="word-wrap: break-word; white-space: normal; width: 190px">
								{{
									data.item.tracking_s.reason
										? data.item.tracking_s.reason
										: data.item.tracking_s.description
										? data.item.tracking_s.description
										: "---"
								}}
							</span>
						</div>
					</template>

					<template #cell(registerby)="data">
						<div class="d-flex flex-column align-items-center justify-content-center">
							<span style="word-wrap: break-word; white-space: normal; width: 190px">
								{{ data.item.tracking_s.user_name }}
							</span>
							<span>{{ data.item.tracking_s.date_status | myGlobalDayShort }}</span>
						</div>
					</template>

					<template #custom-foot="{}">
						<b-tr v-if="[1, 2].includes(categoryId)">
							<b-td :colspan="categoryId === 1 ? 4 : 3" class="bg-transparent"></b-td>
							<b-td class="bg-info bg-darken-1 text-white text-center">
								<p class="mb-0">CP</p>
								<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "cintillo_pata") }}</p>
							</b-td>
							<b-td class="bg-info bg-darken-1 text-white text-center">
								<p class="mb-0">M</p>
								<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "machos") }}</p>
							</b-td>
							<b-td class="bg-info bg-darken-1 text-white text-center">
								<p class="mb-0">H</p>
								<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "hembras") }}</p>
							</b-td>
							<b-td class="bg-info bg-darken-1 text-white text-center">
								<p class="mb-0">SA</p>
								<p class="mb-0 font-weight-bold">{{ sumSons(dataSpecimens, "sin_asignar") }}</p>
							</b-td>
							<b-td class="bg-info bg-darken-1 text-white text-center">
								<p class="mb-0">TOTAL</p>
								<p class="mb-0 font-weight-bold">
									{{
										sumSons(dataSpecimens, "cintillo_pata") +
										sumSons(dataSpecimens, "sin_asignar") +
										sumSons(dataSpecimens, "machos") +
										sumSons(dataSpecimens, "hembras")
									}}
								</p>
							</b-td>
							<b-td class="bg-info bg-darken-1 text-white text-center">
								<p class="mb-0">GLOBAL</p>

								<p class="mb-0 font-weight-bold">
									{{ sumGlobal(dataSpecimens) }}
								</p>
							</b-td>
						</b-tr>
					</template>
				</b-table>

				<div class="ml-2 mt-1 mb-1" v-if="idTab != 2">
					<h5 class="mb-0">Enfermería</h5>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-danger" />
						<small class="mb-0 ml-25">Enviar ejemplar a enfermeria</small>
					</div>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
						<small class="mb-0 ml-25">Ejemplar en espera</small>
					</div>

					<div class="d-flex align-items-center justify-content-start mt-50">
						<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
						<small class="mb-0 ml-25">Ejemplar en proceso</small>
					</div>
				</div>
			</div>
		</b-card>

		<add-specimen-weight
			v-if="specimenWeightController"
			@hidden="specimenWeightController = false"
			@update-specimen-weight="updateSpecimenWeight"
			:specimen-obj="selectedSpecimen"
		></add-specimen-weight>

		<specimen-weight-tracking-modal
			v-if="specimenWeightListController"
			@hidden="specimenWeightListController = false"
			@update-specimen-weight="resetSearch"
			:specimen-obj="selectedSpecimen"
		></specimen-weight-tracking-modal>

		<SpecimenStatusChangedModal
			v-if="specimenStatusController"
			@hidden="specimenStatusController = false"
			:specimen-obj="selectedSpecimen"
			:array-status="arrayStatus"
			@update-status="resetSearch, $refs['refUserListTable'].refresh()"
		></SpecimenStatusChangedModal>

		<b-modal
			v-if="modalViewVideosBySpecimen"
			hide-footer
			v-model="modalViewVideosBySpecimen"
			:title="`Video del ejemplar ${
				modalViewVideosBySpecimenItem.alias ? `${modalViewVideosBySpecimenItem.alias} - ` : ''
			} (${modalViewVideosBySpecimenItem.plate ? modalViewVideosBySpecimenItem.plate : ''})`"
			size="xl"
			lazy
		>
			<ContainerSpecimenListVideos :id_specimen="modalViewVideosBySpecimenItem.id" />
		</b-modal>

		<SidebarMassivelyChanges v-if="sidebarMassivelyChanges" @hidden="closeSidebarMassivelyChanges" />

		<SidebarDeads
			v-if="controllerSideBarDeads"
			@hidden="controllerSideBarDeads = false"
			:category_id="categoryId"
		/>

		<b-modal
			v-model="modalTotalPosturas.show"
			:title="modalTotalPosturas.title"
			size="xl"
			hide-footer
			scrollable
			@hidden="modalTotalPosturas.show = false"
			body-class="p-2 m-0"
		>
			<!-- <PosturasByIdSpecimen :modalTotalPosturas="modalTotalPosturas" :year="year" /> -->
			<!-- <PostutrasListByLote :specimen="{ category: categoryId, id: currentSpecimen.id }"/> -->
			<ViewPostureStatusBySpecimenId
				v-if="modalTotalPosturas.show"
				:isOnlySpecimen="true"
				:specimenId="currentSpecimen.id"
				:categoryId="categoryId"
			/>
			<!-- isOnlySpecimen: [Boolean],
		specimenId: [Number, String],
		categoryId: [Number, String], -->
		</b-modal>

		<b-modal
			v-model="modalTotalTournaments.show"
			:title="`Total de torneos del ejemplar ${modalTotalTournaments.title}`"
			size="xl"
			hide-footer
			scrollable
			@hidden="modalTotalTournaments.show = false"
		>
			<ReportTournamentsComponentVue :resultsNow="true" :plate="modalTotalTournaments.plate" />
		</b-modal>

		<!-- hijos vivos del ejemplar -->
		<b-modal
			v-model="modalSonsBySpecimen.show"
			:title="modalSonsBySpecimen.title"
			size="lg"
			hide-footer
			@hidden="modalSonsBySpecimen.show = false"
			content-class="m-0 p-0"
		>
			<SonsByIdSpecimen :modalSonsBySpecimen="modalSonsBySpecimen" :category="categoryId" :year="year" />
		</b-modal>

		<SidebarChicksGlobal
			v-if="controllerSidebarChicksGlobal"
			@hidden="controllerSidebarChicksGlobal = false"
			:specimen="specimenGlobal"
			:yearSelected="year"
		/>

		<b-modal v-if="modalAddDob.show" hide-footer v-model="modalAddDob.show" :title="modalAddDob.title">
			<b-form-group label="Nacimiento">
				<validation-observer ref="form">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<flat-pickr
							v-model="modalAddDob.dob"
							class="form-control bg-white"
							placeholder="Seleccionar fechas"
							:class="[errors[0] ? 'border-danger' : '']"
						/>
						<small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
					</ValidationProvider>
				</validation-observer>
			</b-form-group>
			<div class="d-flex justify-content-end">
				<button @click="saveDobSpecimen" class="btn btn-primary text-danger">Guardar</button>
			</div>
		</b-modal>

		<b-modal
			size="xl"
			hide-footer
			v-model="modalToSale.show"
			title="Ejemplares en web"
			no-close-on-backdrop
			@hidden="$refs['refUserListTable'].refresh(), webCounter()"
		>
			<SpecimensToWeb
				:counterPublicAndSale="counterPublicAndSale"
				@refresh="onChangeFilter"
				v-if="modalToSale.show"
			/>
		</b-modal>

		<ModalProcessChickPlates
			:show="showModalProcessChickPlates"
			@closing="
				showModalProcessChickPlates = false
				emplacedCounter()
			"
		/>
		<NurseryTracking
			:show="nurseryTracking.show"
			:info="nurseryTracking.info"
			@closing="nurseryTracking.show = false"
		/>
		<AddSpecimensNursery
			:show="addNursery.show"
			:info="addNursery.info"
			@closing="addNursery.show = false"
			@refresh="$refs['refUserListTable'].refresh(), bus.$emit('RefreshCounters')"
		/>
		<DeadChildsModal
			:show="childDeads.show"
			:info="childDeads.info"
			:_id="childDeads.id"
			@closing="childDeads.show = false"
		/>
		<TopeListModal :show="topeList.show" :info="topeList.info" @closing="topeList.show = false" />
		<SpecimensInactivesModal
			:categoryId="categoryId"
			:counters="inactivesCounter"
			:show="controllerSpecimensInactivesModal"
			@closing="controllerSpecimensInactivesModal = !controllerSpecimensInactivesModal"
		/>
		<ModalListSiblings
			:show="showListSiblings"
			@closing="showListSiblings = false"
			:specimen="selectedSpecimen"
			:showSiblings="showSiblings"
		/>
		<SpecimenIncidencesModal
			v-if="incidenceModal.show"
			:show="incidenceModal.show"
			:info="incidenceModal.info"
			@closing="incidenceModal.show = false"
		/>
	</div>
</template>

<script>
import { bus } from "@/main"
import vSelect from "vue-select"
import store from "@/store"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import useUsersList from "../users/useUsersList"
import userStoreModule from "../users/userStoreModule"
import axios from "@/axios"
import Ripple from "vue-ripple-directive"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import AddSpecimenWeight from "./specimens-weight/AddSpecimenWeight.vue"
import SpecimenWeightTrackingModal from "./specimens-weight/SpecimenWeightTrackingModal.vue"
import SpecimenStatusChangedModal from "./components/SpecimenStatusChangedModal.vue"
import specimenService from "@/services/specimens.service"
import CorralService from "@/services/encaste/corral.service"
import publicWebService from "@/services/publicWeb.service"
import { mapState, mapMutations } from "vuex"
import VideoSpecimenListVue from "../videolibrary/VideoSpecimenList.vue"
import SidebarMassivelyChanges from "./components/SidebarMassivelyChanges.vue"
import SidebarDeads from "./components/SidebarDeads.vue"
import SidebarChicksGlobal from "./components/SidebarChicksGlobal.vue"
import PosturasByIdSpecimen from "./PosturasByIdSpecimen.vue"
import ReportTournamentsComponentVue from "../reports/results/tournaments/ReportTournamentsComponent.vue"
import SonsByIdSpecimen from "@/components/specimens/SonsByIdSpecimen.vue"
import ContainerSpecimenListVideos from "./dashboard/ContainerSpecimenListVideos.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import SpecimenAge from "@/components/specimens/SpecimenAge.vue"
import PostutrasListByLote from "@/views/amg/encaste/lotes/modals/PosturasListByLote.vue"
import ViewPostureStatusBySpecimenId from "../encaste/posturas/ViewPostureStatusBySpecimenId.vue"
import SpecimensToSale from "./SpecimensToSale.vue"
import ChangeSpecimenToPublic from "./ChangeSpecimenToPublic.vue"
import ChangeSpecimenToSale from "./ChangeSpecimenToSale.vue"
import SpecimensToWeb from "./SpecimensToWeb.vue"
import ModalProcessChickPlates from "@/views/amg/encaste/chickens/modals/ProcessChickPlates.vue"
import ChangeSpecimenEggEater from "./ChangeSpecimenEggEater.vue"
import NurseryTracking from "@/views/amg/nursery/components/TrackingModal.vue"
import AddSpecimensNursery from "@/views/amg/nursery/components/AddSpecimensNursery.vue"
import TopeListModal from "./components/TopeListModal.vue"
import DeadChildsModal from "@/views/amg/specimens/dashboard/modals/DeadChildsModal.vue"
import SpecimensInactivesModal from "./components/SpecimensInactivesModal.vue"
import specimensService from "@/services/specimens.service"
import raceService from "@/services/race.service"
import ModalListSiblings from "@/views/amg/specimens/dashboard/modals/ModalListSiblings.vue"
import SpecimenIncidencesModal from "@/views/amg/header-bookmarks/incidences/SpecimenIncidencesModal.vue"

export default {
	name: "SpecimensPage",
	directives: {
		Ripple,
	},
	components: {
		vSelect,
		AppCollapse,
		AppCollapseItem,
		AddSpecimenWeight,
		SpecimenWeightTrackingModal,
		SpecimenStatusChangedModal,
		DeadChildsModal,
		VideoSpecimenListVue,
		SidebarMassivelyChanges,
		SidebarDeads,
		PosturasByIdSpecimen,
		ReportTournamentsComponentVue,
		SonsByIdSpecimen,
		ContainerSpecimenListVideos,
		SpecimenPlate,
		SpecimenAge,
		SidebarChicksGlobal,
		PostutrasListByLote,
		ViewPostureStatusBySpecimenId,
		SpecimensToSale,
		ChangeSpecimenToPublic,
		ChangeSpecimenToSale,
		SpecimensToWeb,
		ModalProcessChickPlates,
		ChangeSpecimenEggEater,
		AddSpecimensNursery,
		NurseryTracking,
		TopeListModal,
		SpecimensInactivesModal,
		ModalListSiblings,
		SpecimenIncidencesModal,
	},
	props: {
		categoryId: {
			type: Number,
			default: null,
		},
		isFromSpecimenPage: Boolean,
		globalSearch: {
			type: String,
			default: "",
		},
		idTab: Number,
	},

	computed: {
		visibleFields() {
			return this.arrayColumns.filter((field) => field.visible)
		},

		...mapState("header_book", ["isUpdatingFeature"]),
		sumSons: () => (item, key) => {
			return item.reduce(function (previousValue, currentValue) {
				return previousValue + parseInt(currentValue[key] * 1) || 0
			}, 0)
		},
		sumHasIsNumber: () => (cintillo_patas, sons_machos, sons_hembras, sons_sa) => {
			return (
				Number(cintillo_patas || 0 * 1) +
				Number(sons_machos || 0 * 1) +
				Number(sons_hembras || 0 * 1) +
				Number(sons_sa || 0 * 1)
			)
		},
		years() {
			this.year = this.seasons[0].id
			return this.$store.state.utilities.seasons
		},
		ammendedYear() {
			return this.seasonFromId(this.year)
		},
		clearFilters() {
			switch (this.categoryId) {
				case 1:
					if (this.idTab == 1) {
						return this.filters.filter(
							(it) => !["COME HUEVO", "EDAD(MESES)", "FECHA INICIO", "FECHA FIN"].includes(it.label)
						)
					} else {
						return this.filters.filter(
							(it) =>
								![
									"CANTIDAD DE TOPES",
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"COME HUEVO",
									"EDAD(MESES)",
									"PESO MIN",
									"TIPO INSEMINACION",
									"PESO MAX",
								].includes(it.label)
						)
					}
				case 2:
					if (this.idTab == 1) {
						return this.filters.filter(
							(it) =>
								![
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"EDAD(MESES)",
									"TIPO INSEMINACION",
									"FECHA INICIO",
									"FECHA FIN",
								].includes(it.label)
						)
					} else {
						return this.filters.filter(
							(it) =>
								![
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"EDAD(MESES)",
									"TIPO INSEMINACION",
									"PESO MIN",
									"COME HUEVO",
									"PESO MAX",
								].includes(it.label)
						)
					}
				case 3:
					if (this.idTab == 1) {
						return this.filters.filter(
							(it) =>
								![
									"COME HUEVO",
									"EDAD(MESES)",
									"TIPO INSEMINACION",
									"FECHA INICIO",
									"FECHA FIN",
								].includes(it.label)
						)
					} else {
						return this.filters.filter(
							(it) =>
								![
									"COME HUEVO",
									"EDAD(MESES)",
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"TIPO INSEMINACION",
									"PESO MIN",
									"PESO MAX",
								].includes(it.label)
						)
					}
				case 4:
					if (this.idTab == 1) {
						return this.filters.filter(
							(it) =>
								![
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"EDAD(MESES)",
									"TIPO INSEMINACION",
									"FECHA INICIO",
									"FECHA FIN",
								].includes(it.label)
						)
					} else {
						return this.filters.filter(
							(it) =>
								![
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"EDAD(MESES)",
									"COME HUEVO",
									"TIPO INSEMINACION",
									"PESO MIN",
									"PESO MAX",
								].includes(it.label)
						)
					}
				case 5:
					if (this.idTab == 1) {
						return this.filters.filter(
							(it) =>
								![
									"COME HUEVO",
									"EDAD(AÑOS)",
									"EDAD(AÑOS) MIN",
									"EDAD(AÑOS) MAX",
									"TIPO INSEMINACION",
									"FECHA INICIO",
									"FECHA FIN",
								].includes(it.label)
						)
					} else {
						return this.filters.filter(
							(it) =>
								![
									"COME HUEVO",
									"EDAD(AÑOS)",
									"EDAD(AÑOS) MIN",
									"EDAD(AÑOS) MAX",
									"TIPO INSEMINACION",
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"PESO MIN",
									"PESO MAX",
								].includes(it.label)
						)
					}
				case 6:
					if (this.idTab == 1) {
						return this.filters.filter(
							(it) =>
								![
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"EDAD(AÑOS)",
									"EDAD(AÑOS) MIN",
									"EDAD(AÑOS) MAX",
									"TIPO INSEMINACION",
									"FECHA INICIO",
									"FECHA FIN",
								].includes(it.label)
						)
					} else {
						return this.filters.filter(
							(it) =>
								![
									"CALIFICACION MIN",
									"CALIFICACION MAX",
									"CANTIDAD DE TOPES",
									"EDAD(AÑOS)",
									"EDAD(AÑOS) MIN",
									"EDAD(AÑOS) MAX",
									"COME HUEVO",
									"TIPO INSEMINACION",
									"PESO MIN",
									"PESO MAX",
								].includes(it.label)
						)
					}
			}
			return this.filters.filter((it) => it.label != "COME HUEVO")
		},
	},
	watch: {
		isUpdatingFeature() {
			this.$refs.refUserListTable.refresh()
			this.images = []
			this.$store.commit("header_book/TOGGLE_IS_UPDATING_FEATURE", false)
		},
		year() {
			this.$refs.refUserListTable.refresh()
		},
	},
	data() {
		return {
			sidebarMassivelyChanges: false,
			sortBy: null,
			sortDesc: true,
			//Modals
			specimenWeightController: false,
			specimenWeightListController: false,
			specimenStatusController: false,
			arrayColumns: [
				// sortable se asigna en la respuesta
				{
					key: "specimen",
					label: "Ejemplar",
					class: "text-center",
					visible: true,
				},
				{
					key: "line",
					label: "Linea",
					class: "text-center",
					visible: true,
				},

				{
					key: "video",
					label: "Videos",
					class: "text-center ",
					visible: true,
					visible: [1, 3, 5].includes(this.categoryId) ? true : false,
				},
				{
					key: "cintillo_patas",
					label: "CP",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_machos",
					label: "M",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_hembras",
					label: "H",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_sa",
					label: "SA",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_total",
					label: "Total",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "chicks_global",
					label: "Global",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "deasd_childs_total",
					label: "Muertos",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "quantity_posturas_padrillos",
					label: "Posturas",
					class: "text-center ",
					visible: this.categoryId == 1 ? true : false,
				},
				{
					key: "quantity_posturas_madrillas",
					label: "Posturas",
					class: "text-center ",
					visible: this.categoryId == 2 ? true : false,
				},
				{
					key: "fertilidad",
					label: "Fertilidad",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
				},
				{
					key: "madrilla",
					label: "Madrilla",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 ? true : false,
				},
				{
					key: "padrillo",
					label: "Padrillo",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 ? true : false,
				},
				{
					key: "tournaments",
					label: "Torneos",
					class: "text-center ",
					visible: false,
				},
				{
					key: "origin",
					label: "Origen",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 ? true : false,
				},
				{
					key: "status",
					label: "Estado",
					class: "text-center ",
					visible: true,
				},
				{
					key: "dob",
					label: "Edad",
					class: "text-center ",
					visible: true,
				},
				{
					key: "tracking_weight",
					label: "Peso (Kg)",
					class: "text-center",
					visible: true,
				},

				{
					key: "web",
					label: "Publico",
					class: "text-center ",
					visible: !this.isInvitado,
				},
				{
					key: "venta",
					label: "Venta",
					class: "text-center ",
					visible: !this.isInvitado,
				},
				{
					key: "nursing_status_id",
					label: "Enfermeria",
					class: "text-center ",
					visible: !this.isInvitado,
				},
				{
					key: "actions",
					label: "Acciones",
					class: "text-center ",
					visible: !this.isInvitado,
				},
			],
			data_sessions: [],
			modal_sessions: false,
			data: [],
			edit: [],
			list_users: true,
			create_update: false,
			disable: {
				save: false,
			},
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			inactivesCounter: [],
			filters: [
				{
					label: "FECHA INICIO",
					type: "date",
					placeholder: "Fecha inicio...",
					model: null,
				},
				{
					label: "FECHA FIN",
					type: "date",
					placeholder: "Fecha fin...",
					model: null,
				},
				{
					label: "ORIGEN",
					options: [],
					model: null,
					primaryKey: "id",
					labelSelect: "description",
				},
				{
					label: "COLOR",
					options: [],
					model: null,
					primaryKey: "id",
					labelSelect: "color",
				},
				{
					label: "ESTADO",
					options: [],
					model: "",
					primaryKey: "id",
					labelSelect: "description",
				},
				{
					label: "CRIADOR",
					options: [],
					model: null,
					primaryKey: "id",
					labelSelect: "name",
				},
				{
					label: "CALIFICACION MIN",
					type: "stars",
					model: null,
					step: 0.25,
					top: 5,
				},
				{
					label: "CALIFICACION MAX",
					type: "stars",
					model: null,
					step: 0.25,
					top: 5,
				},

				{
					label: "COME HUEVO",
					primaryKey: "id",
					labelSelect: "label",
					options: [
						{
							id: null,
							label: "Sin filtro",
						},
						{
							id: 1,
							label: "No",
						},
						{
							id: 2,
							label: "Si",
						},
					],
					model: null,
				},

				{
					label: "CANTIDAD DE TOPES",
					options: [
						{
							id: null,
							label: "Sin filtro",
						},
						{
							id: 1,
							label: "1",
						},
						{
							id: 2,
							label: "2",
						},
						{
							id: 3,
							label: "3",
						},
						{
							id: 4,
							label: "4",
						},
						{
							id: 5,
							label: "5",
						},
						{
							id: 6,
							label: ">=6",
						},
					],
					model: null,
					primaryKey: "id",
					labelSelect: "label",
				},
				{
					label: "RAZAS",
					options: [],
					model: [],
					multiselect: true,
					primaryKey: "id",
					labelSelect: "label",
				},
				{
					label: "EDAD(AÑOS) MIN",
					primaryKey: "id",
					labelSelect: "label",
					options: [
						{ id: null, label: "Sin filtro" },
						{ id: 1, label: "1" },
						{ id: 2, label: "2" },
						{ id: 3, label: "3" },
						{ id: 4, label: "4" },
						{ id: 5, label: "5" },
						{ id: 6, label: "6" },
						{ id: 7, label: "7" },
						{ id: 8, label: "8" },
						{ id: 9, label: "9" },
						{ id: 10, label: "10" },
						{ id: 11, label: "11" },
						{ id: 12, label: "12" },
						{ id: 13, label: "13" },
						{ id: 14, label: "14" },
						{ id: 15, label: "15" },
					],
					model: null,
				},
				{
					label: "EDAD(AÑOS) MAX",
					primaryKey: "id",
					labelSelect: "label",
					options: [
						{ id: null, label: "Sin filtro" },
						{ id: 1, label: "1" },
						{ id: 2, label: "2" },
						{ id: 3, label: "3" },
						{ id: 4, label: "4" },
						{ id: 5, label: "5" },
						{ id: 6, label: "6" },
						{ id: 7, label: "7" },
						{ id: 8, label: "8" },
						{ id: 9, label: "9" },
						{ id: 10, label: "10" },
						{ id: 11, label: "11" },
						{ id: 12, label: "12" },
						{ id: 13, label: "13" },
						{ id: 14, label: "14" },
						{ id: 15, label: "15" },
					],
					model: null,
				},
				{
					label: "PESO MIN",
					type: "stars",
					model: null,
					step: 0.5,
					top: 6,
				},
				{
					label: "PESO MAX",
					type: "stars",
					model: null,
					step: 0.5,
					top: 6,
				},
				{
					label: "EDAD(MESES)",
					primaryKey: "id",
					labelSelect: "label",
					options: [
						{ id: null, label: "Sin filtro" },
						{ id: 6, label: "6" },
						{ id: 7, label: "7" },
						{ id: 8, label: "8" },
						{ id: 9, label: "9" },
						{ id: 10, label: "10" },
						{ id: 11, label: "11" },
						{ id: 12, label: "12" },
					],
					model: null,
				},

				{
					label: "TIPO INSEMINACION",
					primaryKey: "id",
					labelSelect: "label",
					options: [
						{
							id: null,
							label: "Sin filtro",
						},
						{
							id: 1,
							label: "Natural",
						},
						{
							id: 2,
							label: "Pisa en mano",
						},
						{
							id: 3,
							label: "Artificial",
						},
					],
					model: null,
				},
			],
			filterController: false,
			modal_image: false,
			index: null,
			images: [],
			selectedSpecimen: {},
			arrayStatus: [],
			modalViewVideosBySpecimen: false,
			modalViewVideosBySpecimenItem: null,
			modalTotalPosturas: {
				title: "",
				content: "",
				show: false,
				idSpecimen: null,
			},
			modalTotalTournaments: {
				title: "",
				content: "",
				show: false,
				idSpecimen: null,
				plate: null,
			},
			modalSonsBySpecimen: {
				title: "",
				show: false,
				idSpecimen: null,
				plate: null,
				index: 1,
			},
			total: {
				total_machos: 0,
				total_hembras: 0,
				total_sa: 0,
				total_total: 0,
			},
			dataSpecimens: [],
			year: null,
			isLoading: true,
			currentSpecimen: {},
			controllerSideBarDeads: false,
			counterParent: 0,
			controllerSidebarChicksGlobal: false,
			specimenGlobal: {},
			modalAddDob: {
				show: false,
				title: null,
				dob: null,
			},
			modalToSale: {
				show: false,
				title: null,
			},
			counterPlatesPending: 0,
			showModalProcessChickPlates: false,
			counterWeb: 0,
			counterPublicAndSale: {},
			nurseryTracking: { show: false, info: {} },
			addNursery: { show: false, info: {} },
			topeList: { show: false, info: {} },
			childDeads: { show: false, info: {}, id: null },
			controllerSpecimensInactivesModal: false,
			totalInactives: 0,
			showListSiblings: false,
			showSiblings: false,
			incidenceModal: {
				show: false,
				info: {},
			},
		}
	},
	methods: {
		...mapMutations("video", [
			"M_SET_INPUT",
			"M_RESET_FORM_VIDEO",
			"M_DATA_FILE",
			"M_SET_TOURNAMENT_SPECIMEN_ARRAY",
		]),
		openIncidenceDetail(data) {
			this.incidenceModal.info = data
			this.incidenceModal.show = true
		},
		goIdSpecimen(id) {
			const url = this.$router.resolve({
				name: "detalle-ejemplar",
				params: { specimen_id: id },
			}).href
			window.open(url, "_blank")
		},
		onChangeFilter() {
			this.$refs.refUserListTable.refresh()
		},
		refreshCounters() {
			this.getInactivesCountersByCategory()
			this.getCounterParentsDead()
		},
		openAddSpecimenWeightModal(specimen) {
			this.selectedSpecimen = specimen
			this.specimenWeightController = true
		},
		openSpecimenWeightListModal(specimen) {
			this.selectedSpecimen = specimen
			this.specimenWeightListController = true
		},
		openModalListSibling(specimen) {
			this.selectedSpecimen = specimen
			this.showListSiblings = true
			this.showSiblings = true
		},
		async getInactivesCountersByCategory() {
			const { data } = await specimensService.getInactivesCountersByCategory()
			this.totalInactives = data.find((index) => index.id == this.categoryId).counter
			this.inactivesCounter = data
		},
		async openSpecimenStatusChanged(specimen) {
			if (this.arrayStatus.length == 0) {
				await this.getSpecimenStatusTrackings()
			}
			this.selectedSpecimen = { ...specimen, category_id: this.categoryId }
			this.specimenStatusController = true
		},
		async getSpecimenStatusTrackings() {
			this.arrayStatus = await specimenService.getAllStatusSpecimens()
			this.arrayStatus = this.arrayStatus.filter((item) => item.id != 3)
		},
		updateSpecimenWeight(specimen) {
			specimen.weight_counter = Number(specimen.weight_counter) + 1
			this.resetSearch()
		},
		async myProvider(ctx) {
			this.isPreloading()
			if (this.search_input.length > 0) this.$emit("clearSearchGlobal")
			const finalUrl = [1, 2].includes(this.categoryId) ? ctx.apiUrl : "api/specimens/select/by-category"
			let sortField = this.keyToSortable(ctx.sortBy)
			let sortDir = ""
			if (sortField == "dob") {
				// para corregir que en esta columna se ordene por edad y no por fecha
				sortDir = ctx.sortDesc ? "asc" : "desc"
			} else {
				sortDir = ctx.sortDesc ? "desc" : "asc"
			}
			const { data } = await axios.get(`${finalUrl}?page=${ctx.currentPage}`, {
				params: {
					orderby: sortField,
					order: sortDir,
					campo:
						this.globalSearch.length > 0 && this.search_input.length == ""
							? this.globalSearch
							: this.search_input,
					perpage: ctx.perPage,
					filter_origin: this.filters[2].model,
					filter_color: this.filters[3].model,
					filter_category: this.categoryId,
					filter_status: this.filters[4].model,
					filter_rival: this.filters[5].model,
					date_start: this.year == null ? this.seasons[0].start : this.ammendedYear.start,
					date_end: this.year == null ? this.seasons[0].end : this.ammendedYear.end,
					start_average_cap: this.filters[6].model != "0.00" ? this.filters[6].model : null,
					end_average_cap: this.filters[7].model != "0.00" ? this.filters[7].model : null,
					check_canival: [2, 4, 6].includes(this.categoryId) ? this.filters[8].model : null,
					count_topes: [1, 3, 5].includes(this.categoryId) ? this.filters[9].model : null,
					races_by_filter: this.filters[10].model.length ? this.filters[10].model.toString() : null,
					// years: [1, 2, 3, 4].includes(this.categoryId) ? this.filters[9].model : null,
					// months: [5, 6].includes(this.categoryId) ? this.filters[10].model : null,
					start_year_min: [1, 2, 3, 4].includes(this.categoryId) ? this.filters[11].model : null,
					end_year_max: [1, 2, 3, 4].includes(this.categoryId) ? this.filters[12].model : null,
					start_weight_min: this.filters[13].model != "0.00" ? this.filters[13].model : null,
					end_weight_max: this.filters[14].model != "0.00" ? this.filters[14].model : null,
					check_foot_hand: [1].includes(this.categoryId) ? this.filters[16].model : null,
					in_galpon: this.idTab === 2 ? 0 : 1,
					filter_start: this.idTab == 2 ? this.filters[0].model : null,
					filter_end: this.idTab == 2 ? this.filters[1].model : null,
				},
			})
			const items = data.data.map((item) => {
				let siblings = []
				let stepBrother = []
				item.pa_ids.forEach((pas) => {
					if (pas.madrilla_id === item.madrilla_id && pas.padrillo_id === item.padrillo_id) {
						siblings.push(pas)
					} else {
						stepBrother.push(pas)
					}
				})

				item.ma_ids.forEach((mas) => {
					if (mas.madrilla_id === item.madrilla_id && mas.padrillo_id === item.padrillo_id) {
						siblings.push(mas)
					} else {
						stepBrother.push(mas)
					}
				})

				return {
					...item,
					siblings: siblings,
					stepBrother: stepBrother,
				}
			})
			this.dataSpecimens = data.data
			items.forEach((item) => {
				if (item.photo) {
					this.images.push({
						...item,
						title: item.alias + "  |  " + item.plate,
						src: item.photo,
					})
				}
			})

			this.data = data.data.map((item) => {
				let siblings = []
				let stepBrother = []
				item.pa_ids.forEach((pas) => {
					if (pas.madrilla_id === item.madrilla_id && pas.padrillo_id === item.padrillo_id) {
						siblings.push(pas)
					} else {
						stepBrother.push(pas)
					}
				})

				item.ma_ids.forEach((mas) => {
					if (mas.madrilla_id === item.madrilla_id && mas.padrillo_id === item.padrillo_id) {
						siblings.push(mas)
					} else {
						stepBrother.push(mas)
					}
				})

				return {
					...item,
					siblings: siblings,
					stepBrother: stepBrother,
				}
			})
			this.start_page = data.from
			this.current_page = data.current_page
			this.perpage = data.per_page
			this.next_page = this.start_page + 1
			this.end_page = data.last_page
			this.total_data = data.total
			this.to_page = data.to
			this.loadHeaders()
			this.isPreloading(false)
			return items || []
		},

		loadHeaders() {
			this.arrayColumns = [
				{
					key: "specimen",
					label: "Ejemplar",
					class: "text-center",
					visible: true,
					sortable: true,
				},
				{
					key: "line",
					label: "Linea",
					class: "text-center",
					visible: true,
				},
				{
					key: "race",
					label: "Raza",
					class: "text-center",
					visible: true,
				},
				{
					key: "video",
					label: "Videos",
					class: "text-center ",
					visible: true,
					visible: [1, 3, 5].includes(this.categoryId) ? true : false,
				},
				{
					key: "cintillo_patas",
					label: "CP",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "chicks_machos",
					label: "M",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "chicks_hembras",
					label: "H",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "chicks_sa",
					label: "SA",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "chicks_total",
					label: "Total",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "chicks_global",
					label: "Global",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "deads_childs_total",
					label: "Muertos",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "quantity_posturas_padrillos",
					label: "Posturas",
					class: "text-center ",
					visible: this.categoryId == 1 ? true : false,
					sortable: true,
				},
				{
					key: "quantity_posturas_madrillas",
					label: "Posturas",
					class: "text-center ",
					visible: this.categoryId == 2 ? true : false,
					sortable: true,
				},
				{
					key: "fertilidad",
					label: "Fertilidad",
					class: "text-center ",
					visible: this.categoryId == 1 || this.categoryId == 2 ? true : false,
					sortable: true,
				},
				{
					key: "madrilla",
					label: "Madrilla",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 && this.isAdmin ? true : false,
					sortable: true,
				},
				{
					key: "padrillo",
					label: "Padrillo",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 && this.isAdmin ? true : false,
					sortable: true,
				},
				{
					key: "tournaments",
					label: "Torneos",
					class: "text-center ",
					visible: false,
				},
				{
					key: "origin",
					label: "Origen",
					class: "text-center ",
					visible: this.categoryId != 1 && this.categoryId != 2 && this.isAdmin ? true : false,
				},
				{
					key: "topes",
					label: "Topes",
					class: "text-center",
					visible: [1, 3, 5].includes(this.categoryId),
				},
				{
					key: "status",
					label: "Estado",
					class: "text-center ",
					visible: true,
					sortable: true,
				},
				{
					key: "dob",
					label: "Edad",
					class: "text-center ",
					visible: true,
					sortable: true,
				},
				{
					key: "tracking_weight",
					label: "Peso",
					class: "text-center",
					visible: true,
					sortable: true,
				},
				{
					key: "web",
					label: "Publico",
					class: "text-center ",
					visible: !this.isInvitado && this.idTab === 1,
				},
				{
					key: "venta",
					label: "Venta",
					class: "text-center ",
					visible: !this.isInvitado && this.idTab === 1,
				},
				{
					key: "mmwbo",
					label: "Come huevo",
					class: "text-center ",
					visible: !this.isInvitado && [2, 4].includes(this.categoryId) && this.idTab === 1,
				},
				{
					key: "pisa",
					label: "INSEMINACION",
					class: "text-center ",
					visible: (this.isAdmin || this.isEncastador) && [1].includes(this.categoryId) && this.idTab === 1,
				},
				{
					key: "nursing_status_id",
					label: "Enfermeria",
					class: "text-center ",
					visible: !this.isInvitado && this.idTab === 1,
				},
				{
					key: "actions",
					label: "Acciones",
					class: "text-center ",
					visible: !this.isInvitado && this.idTab === 1,
				},
				{
					key: "reason",
					label: "Motivo",
					class: "text-center ",
					visible: this.idTab === 2,
				},
				{
					key: "registerby",
					label: "Registrado por",
					class: "text-center ",
					visible: this.idTab === 2,
				},
			]
		},
		async getRaceLabels() {
			const { data } = await raceService.getRaces({ status: 1 })
			this.filters[10].options = data.map((o) => ({ label: o.name, id: o.id }))
		},
		resetSearch() {
			this.search_input = ""
			this.$refs.refUserListTable.refresh()
			this.images = []
		},
		closeModals() {
			this.modal_sessions = false
		},
		formatPercent(e) {
			return [null, undefined].includes(e) ? "---" : `${e}%`
		},
		getcolorspecimen() {
			axios
				.get("/api/specimens/get-colors", {
					params: {
						gender_specimen: null,
						page: 1,
						perpage: 1000,
						search: null,
					},
				})
				.then((response) => {
					this.filters[3].options = response.data.colores.data
				})
		},
		getdataselect() {
			this.getcolorspecimen()
			axios
				.get("/api/specimens/get-data-select-specimens", { params: { categoryId: this.categoryId } })
				.then((response) => {
					if (response.status == 200) {
						this.filters[2].options = JSON.parse(response.data[0].data_origin)
						this.filters[5].options = JSON.parse(response.data[0].rivals)
						this.filters[4].options = JSON.parse(response.data[0].data_statuse).filter(
							(it) => it.in_galpon == (this.idTab == 2 ? 0 : 1)
						)
						// console.log("statuses: ", this.filters[2].options)

						this.filters[4].options = this.filters[4].options.sort((a, b) => {
							if (a.id < b.id || a.in_galpon == 1) return -1
							if (b.in_galpon == 0) return 1
							return 0
						})

						// console.log("status", this.filters[2].options)

						this.filters[4].options = this.filters[4].options.map((it) => ({
							...it,
							selectable: true,
							description: `------ ${it.description}`,
						}))
						if (this.idTab == 1) {
							this.filters[4].options.unshift({
								id: null,
								description: "DISPONIBLES",
								selectable: false,
							})
						}

						const indexInactivos = this.filters[4].options.findIndex((it) => it.in_galpon == 0)

						// console.log("index", indexInactivos)
						if (this.idTab == 2) {
							this.filters[4].options.unshift({
								id: null,
								description: "NO DISPONIBLES",
								selectable: false,
							})
						}

						// this.filters[2].options.unshift()
					}
				})
		},
		openmodal(id) {
			axios
				.post("/api/get-sessions-user", {
					iduser: id,
				})
				.then((response) => {
					if (response.data) {
						this.data_sessions = response.data
						this.modal_sessions = true
					}
				})
		},
		showToast(variant, position, title, icon, text) {
			this.$toast(
				{
					component: ToastificationContent,
					props: {
						title,
						icon,
						text,
						variant,
					},
				},
				{
					position,
				}
			)
		},
		delete_specimen(id) {
			this.$swal({
				title: "Estas seguro?",
				text: "¡No podrás revertir esto!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3490dc",
				cancelButtonColor: "#e3342f",
				confirmButtonText: "Confirmar",
				customClass: {
					cancelButton: "btn btn-outline-danger ml-1",
					confirmButton: "btn btn-primary",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios.delete(`/api/specimens/${id}`).then((response) => {
						if (response.status == 200) {
							this.showToast(
								"success",
								"top-right",
								"Felicidades",
								"CheckIcon",
								"El ejemplar ha sido eliminado!"
							)
							this.$refs.refUserListTable.refresh()
						}
					})
				}
			})
		},
		viewSpecimen(item) {
			//get index of images where item id is equals images id
			let index = this.images.findIndex((x) => x.id == item.id)
			this.index = index
		},
		openInfo(item) {
			this.$router.push({
				name: "detalle-ejemplar",
				params: { specimen_id: item.id },
			})
		},
		setViewVideoBySpecimen(specimen) {
			this.modalViewVideosBySpecimen = true
			this.modalViewVideosBySpecimenItem = specimen
		},
		clickNumberPostura(item) {
			this.modalTotalPosturas.show = !this.modalTotalPosturas.show
			this.modalTotalPosturas.idSpecimen = item.id
			this.currentSpecimen = item
			this.modalTotalPosturas.title = `Total de posturas del ejemplar ${item.alias} ${item.plate ? "-" : ""} ${
				item.plate ? item.plate : ""
			}`
		},
		clickTournamentsBySpecimen(id, item) {
			this.modalTotalTournaments.show = !this.modalTotalTournaments.show
			this.modalTotalTournaments.idSpecimen = id
			this.modalTotalTournaments.plate = item.plate
			this.modalTotalTournaments.title = `${item.alias ? item.alias : ""} ${item.alias ? "-" : ""} (${
				item.plate ? item.plate : ""
			})`
		},
		clickSonsBySpecimenv2(item, tabIndex) {
			this.modalSonsBySpecimen.title = `Hijos del ejemplar ${item.alias ? item.alias : ""} ${
				item.alias ? "-" : ""
			} (${item.plate ? item.plate : ""})`
			this.modalSonsBySpecimen.show = !this.modalSonsBySpecimen.show
			this.modalSonsBySpecimen.idSpecimen = item.id
			this.modalSonsBySpecimen.index = tabIndex
		},
		async getCounterParentsDead() {
			const data = await specimenService.getCounterParentsDead(this.categoryId)
			this.counterParent = data.counter
		},
		async closeSidebarMassivelyChanges() {
			this.sidebarMassivelyChanges = false
			await this.getCounterParentsDead()
		},
		openParentsDead() {
			this.controllerSideBarDeads = true
		},
		openSpecimensInactivesModal() {
			this.controllerSpecimensInactivesModal = true
		},
		openSidebarChicksGlobal(data) {
			this.specimenGlobal = data
			this.controllerSidebarChicksGlobal = true
		},
		sumGlobal(dataSpecimens) {
			return dataSpecimens.reduce((a, b) => {
				return (
					a +
					parseInt(isNaN(b.cd_global * 1) ? 0 : b.cd_global * 1) +
					parseInt(isNaN(b.cp_global * 1) ? 0 : b.cp_global * 1)
				)
			}, 0)
		},
		addDobSpecimen(specimen) {
			this.modalAddDob.show = true
			this.modalAddDob.title = `Agregar nacimiento ${specimen.alias ? specimen.alias : ""} (${specimen.plate})`
			this.modalAddDob.specimen_id = specimen.id
			// this.modalAddDob.specimen_id = 12
		},
		async saveDobSpecimen() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				)
				return
			}

			let body = {
				specimen_id: this.modalAddDob.specimen_id,
				key: "dob",
				value_text: this.modalAddDob.dob,
			}

			this.isPreloading()

			const { message, status } = await specimenService.updateFieldSpecimen(body)

			if (status) {
				this.showToast("success", "top-right", "Corrales", "SuccessIcon", message)

				// const indexSpecimen = this.spe
				this.$refs.refUserListTable.refresh()

				this.modalAddDob = {
					show: false,
					title: null,
					dob: null,
					specimen_id: null,
				}
			}

			this.isPreloading(false)
		},
		async emplacedCounter() {
			let { data } = await CorralService.getCountPlacationRequests()
			this.counterPlatesPending = data.counter
		},
		openModalEmplacados() {
			if (this.counterPlatesPending == 0) {
				return this.showToast("warning", "top-right", "Emplacados", "WarningIcon", "Sin pollos emplacados.")
			}

			if (this.counterPlatesPending > 0) {
				this.showModalProcessChickPlates = !this.showModalProcessChickPlates
			}
		},
		async webCounter() {
			let { data } = await publicWebService.getGeneralCounter()
			this.counterWeb = data.reduce((prev, category) => prev + category.counter, 0)
			this.counterPublicAndSale = data
		},
		keyToSortable(key) {
			switch (key) {
				case "specimen":
					return "plate"
				case "cintillo_patas":
					return "cintillo_pata"
				case "chicks_machos":
					return "machos"
				case "chicks_hembras":
					return "hembras"
				case "chicks_sa":
					return "sin_asignar"
				case "chicks_total":
					return "sum_childs"
				case "chicks_global":
					return "childs_global"
				case "quantity_posturas_padrillos":
					return "count_posturas"
				case "quantity_posturas_madrillas":
					return "count_posturas"
				case "fertilidad":
					return "fertilidad"
				case "madrilla":
					return "madrilla_plate"
				case "padrillo":
					return "padrillo_plate"
				case "status":
					return "status_id"
				case "dob":
					return "dob"
				case "tracking_weight":
					return "weight"
				case "deads_childs_total":
					return "deads_childs_total"
				default:
					return null
			}
		},
		colorLine(number) {
			if (!number) return "bg-secondary"
			if (number >= 100) {
				return "bg-success"
			} else if (number < 100) {
				return "bg-warning"
			}
		},
		colorRace(status) {
			let colorClass
			switch (status) {
				case 1:
					colorClass = "bg-secondary"
					break
				case 2:
					colorClass = "bg-warning"
					break
				case 3:
					colorClass = "bg-success"
					break
				case 4:
					colorClass = "bg-primary"
					break
			}
			return colorClass
		},
		textRace(status) {
			let textStatus
			switch (status) {
				case 1:
					textStatus = "NO DEFINIDO"
					break
				case 2:
					textStatus = "INCOMPLETO"
					break
				case 3:
					textStatus = "COMPLETO"
					break
				case 4:
					textStatus = "APARTIR DE LAS 12:00 AM"
					break
			}
			return textStatus
		},
		textLine(number) {
			if (!number) return "NO DEFINIDO"
			if (number >= 100) {
				return "COMPLETO"
			} else if (number < 100) {
				return "INCOMPLETO"
			}
		},
	},
	created() {
		this.getdataselect()
		this.emplacedCounter()
		this.webCounter()
		bus.$on("reload-counter-web", async () => {
			await this.webCounter()
		})
		bus.$on("RefreshCounters", this.refreshCounters)
		bus.$on("refresh-specimens-table", () => {
			this.$refs["refUserListTable"].refresh()
		})
	},

	async mounted() {
		this.$refs.refUserListTable.refresh()
		this.getRaceLabels()
		await this.getCounterParentsDead()
		this.getInactivesCountersByCategory()
	},
	destroyed() {
		this.M_RESET_FORM_VIDEO({ category_idIn: 1, type_idIn: 1 })
		bus.$off("RefreshCounters", this.refreshCounters)
	},
	setup() {
		const USER_APP_STORE_MODULE_NAME = "app-user"

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
			store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})

		const isAddNewUserSidebarActive = ref(false)

		const roleOptions = [
			{ label: "Admin", value: "admin" },
			{ label: "Author", value: "author" },
			{ label: "Editor", value: "editor" },
			{ label: "Maintainer", value: "maintainer" },
			{ label: "Subscriber", value: "subscriber" },
		]

		const planOptions = [
			{ label: "Basic", value: "basic" },
			{ label: "Company", value: "company" },
			{ label: "Enterprise", value: "enterprise" },
			{ label: "Team", value: "team" },
		]

		const statusOptions = [
			{ label: "Pending", value: "pending" },
			{ label: "Active", value: "active" },
			{ label: "Inactive", value: "inactive" },
		]

		const {
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		} = useUsersList()

		return {
			// Sidebar
			isAddNewUserSidebarActive,

			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,

			// Filter
			avatarText,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			roleOptions,
			planOptions,
			statusOptions,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		}
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

td.div {
	width: 100% !important;
}

@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
</style>

<style lang="scss">
.muda-gray {
	filter: grayscale(2) !important;
}

.media-body {
	position: relative;

	.container-ran {
		position: absolute;
		top: 50%;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transform: TRANSLATEY(-50%);

		img {
			width: 32px;
			height: 32px;
			object-fit: cover;

			@media screen and (min-width: 2000px) {
				width: 40px;
				height: 40px;
			}
		}
	}
}

.container-plate-specimen {
	margin-right: 1rem;
}

.container-btn-update-status {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.container-btns-page-specimens {
	// background: red;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 900px) {
		flex-direction: row;
		justify-content: space-between;
	}

	@media screen and (min-width: 1500px) {
		flex-direction: row;
		justify-content: flex-end;
	}

	.btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-basis: auto;

		@media screen and (min-width: 600px) {
			flex-direction: row;
		}

		.btn {
			width: 100%;
			min-width: 180px;
			margin-top: 1rem;

			@media screen and (min-width: 900px) {
				margin-top: 0;
			}
		}
	}
}

.sons-specimens-v2 {
	p {
		margin-bottom: 0;
	}
}

.sons-specimens-v3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.star-score {
	color: var(--primary) !important;
	// margin-top: -5px;
}

.img-paw {
	width: 28px;
	height: 28px;
	cursor: pointer;
	margin-left: 0.2rem;
}

.incidences-counter {
	position: absolute;
	top: -0.4rem;
	left: 1.5rem;
	height: 1.2rem;
	min-width: 1.2rem;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	background: var(--primary);
	border-radius: 0.2rem;
	font-weight: bolder;
	font-size: 12px;
}

.icon-disabled {
	pointer-events: none;
	opacity: 0.5;
}
</style>

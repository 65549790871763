<template>
	<b-card no-body>
		<h4 class="mb-0 p-1 rounded-top bg-primary text-white">
			{{ title }} <b-badge variant="danger" class="ml-1"> {{ counterSons }} </b-badge>
		</h4>
		<section v-for="(postura, indexTwo) in sons" :key="`${indexTwo}-postura`" cols="12" md="6" xl="4">
			<div style="padding: 15px 0 10px 10px" class="d-flex" :class="[indexTwo % 2 == 1 ? 'bg-posture' : '']">
				<div
					class="d-flex flex-column"
					:class="[type_evo == 'SPEC' ? 'border-spec' : '']"
				>
					<SpecimenPlate style="width: 180px" :specimen="typeof postura.madrilla ==  'string'  ? postura.padrillo : postura.madrilla" />
					<h6 class="text-center mt-1 font-weight-bolder">{{ postura.p_code }}</h6>
				</div>

				<div class="d-flex flex-grow-1">
					<template v-if="type_evo == 'CP'">
						<div class="d-flex align-items-center justify-content-center w-100" style="min-width: 80px">
							<h4>{{ postura.eggs * 1 }}</h4>
						</div>
					</template>
					<template v-else-if="type_evo == 'CA'">
						<ul class="d-flex flex-wrap p-0 mb-0 align-items-center">
							<li
								v-for="(chick, indexThree) in postura.chicks"
								:key="`${indexThree}-postura`"
								class="d-flex align-items-center ml-1 mb-1"
								style="min-width: 100px"
							>
								<FeatherIcon size="12" icon="ChevronsRightIcon" style="margin-right: 5px" />
								<div class="c-chick">{{ chick.correlative }}</div>
							</li>
						</ul>
					</template>
					<template v-else-if="type_evo == 'SPEC'">
						<div class="d-flex flex-wrap">
							<div
								v-for="(spec, indexFour) in postura.sons_specimens"
								:key="`${indexFour}-postura`"
								class="mb-1"
							>
								<SpecimenPlate :specimen="spec" />
							</div>
						</div>
					</template>
				</div>
			</div>
		</section>
	</b-card>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
export default {
	name: "ShowChicksGlobalByEvo",
	components: {
		SpecimenPlate,
	},
	props: {
		type_evo: {
			type: String,
			required: true,
		},
		sons: {
			type: Array,
			required: true,
		},
	},
	mounted() {
		this.make();
	},
	computed: {
		counterSons() {
			let prop = "";
			switch (this.type_evo) {
				case "CP":
					prop = "eggs";
					break;
				case "CA":
					prop = "chicks_all";
					break;
				case "SPEC":
					prop = "specimens_all";
					break;
			}

			if (prop !== "") {
				return this.sons.reduce((a, b) => {
					return a + b[`${prop}`] * 1;
				}, 0);
			}
			return 0;
		},
	},
	data() {
		return {
			title: "",
		};
	},
	methods: {
		make() {
			switch (this.type_evo) {
				case "CP":
					this.title = "CINTILLO EN LA PATA";
					break;
				case "CA":
					this.title = "CINTILLO EN LA ALA";
					break;
				case "SPEC":
					this.title = "HIJOS EJEMPLARES";
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-posture {
	background-color: #7267f017;
}

.border-spec {
	padding-right: 20px;
	margin-right: 15px;
	border-right: 2px solid black;
	align-items: stretch !important;
}
</style>

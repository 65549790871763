<template>
	<div>
		<b-overlay :show="isLoading">
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-row class="mb-1">
				<b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
					<label>Mostrar</label>
					<v-select
						v-model="pagination.perPage"
						:options="perPageOptions"
						:clearable="false"
						class="per-page-selector d-inline-block mx-50"
						style="width: 90px"
					/>
					<label class="mr-2">registros</label>
					<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
				</b-col>
				<b-col
					class="d-flex flex-column flex-xl-row align-items-xl-center justify-content-xl-end gap-x-2"
					cols="12"
					md="6"
					v-if="!(info && info.id && !origin)"
				>
					<b-form-checkbox v-model="dateCheck" class="d-flex align-items-center">
						<span style="white-space: nowrap; font-size: 0.9rem">
							{{ !dateCheck ? "Activar" : "Desactivar" }} filtros
						</span>
					</b-form-checkbox>
					<div class="d-flex align-items-center">
						<feather-icon
							icon="ChevronLeftIcon"
							size="40"
							class="text-primary cursor-pointer"
							:class="{ 'check-disabled': !dateCheck }"
							style="margin-right: 10px"
							@click="previousWeek()"
							:disabled="!dateCheck"
						/>

						<b-form-datepicker
							class="border-primary"
							style="width: 140px"
							locale="en-US"
							placeholder="Desde"
							:disabled="true"
							:date-format-options="{
								month: 'numeric',
								day: 'numeric',
								year: 'numeric',
							}"
							v-model="weekFrom"
						/>
						<b-form-datepicker
							class="border-primary ml-1"
							locale="en-US"
							style="width: 140px"
							placeholder="Hasta"
							:disabled="true"
							:date-format-options="{
								month: 'numeric',
								day: 'numeric',
								year: 'numeric',
							}"
							v-model="weekTo"
						/>

						<feather-icon
							icon="ChevronRightIcon"
							size="40"
							class="text-primary cursor-pointer"
							:class="{ 'check-disabled': !dateCheck }"
							style="margin-left: 10px"
							@click="nextWeek()"
							:disabled="!dateCheck"
						/>
					</div>
				</b-col>
				<b-col cols="12" xl="3" v-if="!(info && info.id && !origin)">
					<div class="container-btns-page-specimens">
						<div class="d-flex">
							<b-form-input
								v-model="search"
								class="d-inline-block mr-1"
								placeholder="Placa o alias..."
								@keyup.enter="getIncidences"
								debounce="500"
							/>
						</div>
					</div>
				</b-col>
			</b-row>

			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header">
					<b-th class="text-center" v-if="!(info && info.id && !origin)">Ejemplar</b-th>
					<b-th class="px-25" :class="{ 'text-center px-0': info && info.id && !origin }">Descripción</b-th>
					<b-th class="text-center">Fecha y hora</b-th>
					<b-th class="text-center">Evidencias</b-th>
					<!-- <b-th v-if="origin == 'by_user'" class="text-center">Revisar</b-th> -->
				</b-thead>

				<b-tbody>
					<template v-for="(incidence, index) in incidences" v-if="incidences.length > 0">
						<b-tr :key="index" :class="{ 'is-seen': origin === 'by_user' && incidence.is_seen === 0 }">
							<b-td class="text-center" v-if="!(info && info.id && !origin)">
								<div style="display: flex; gap: 1rem; align-items: center; justify-content: center">
									<div>
										<SpecimenPlate :specimen="incidence.specimen" />
									</div>
								</div>
							</b-td>

							<b-td class="px-25">
								<div class="d-flex" style="width: 350px">
									<span class="text-left w-100" style="word-wrap: break-word">
										<!-- {{ incidence.description }} -->

										{{
											isExpanded[index]
												? incidence.description.toUpperCase()
												: getReducedDesc(incidence.description).toUpperCase()
										}}

										<a
											v-if="incidence.description.length > 100"
											href="#"
											style="font-weight: 600"
											@click.prevent="toggleExpand(index)"
										>
											{{ isExpanded[index] ? "Ver menos" : "Ver más" }}
										</a>
									</span>
								</div>
							</b-td>

							<b-td class="text-center px-0">
								<div class="d-flex w-100 justify-content-center">
									<div
										class="d-flex flex-column justify-content-center align-items-center"
										style="width: 150px"
									>
										<span v-if="origin !== 'by_user'">{{ incidence.created_by.name }}</span>
										<span>{{ incidence.created_by.date | myGlobalDateTime }}</span>
									</div>
								</div>
							</b-td>

							<b-td class="text-center">
								<div class="d-flex gap-x-3 align-items-center justify-content-center">
									<div v-if="incidence.evidence_image.url != null">
										<ViewImage
											:specimen="{
												id: incidence.evidence_image.id,
												url: incidence.evidence_image.url,
											}"
											:hideText="true"
										/>
									</div>
									<div
										v-if="incidence.evidence_video.id != null"
										class="d-flex align-incidences-center justify-content-center"
										:class="{
											'add-borderr':
												incidence.evidence_video.url != null &&
												incidence.evidence_image.url != null,
										}"
									>
										<div
											v-if="
												incidence.evidence_video.status == 2 &&
												incidence.evidence_video.url &&
												incidence.evidence_video.id != null
											"
											@click="viewSpecimen(incidence, index)"
											style="cursor: pointer; position: relative"
										>
											<b-avatar
												:src="incidence.evidence_video.thumb"
												rounded
												size="50"
												icon="film"
												style="position: relative"
											></b-avatar>
											<span
												style="
													position: absolute;
													bottom: -0.3rem;
													right: -0.3rem;
													background-color: #ea5455;
													border-radius: 5px;
													padding: 0.2rem;
													display: flex;
													align-items: center;
													justify-content: center;
												"
											>
												<feather-icon
													class="cursor-pointer text-white"
													icon="FilmIcon"
													size="16"
												/>
											</span>
										</div>

										<div
											v-else
											class="px-50"
											:class="{ 'add-border': incidence.evidence_image.url != null }"
										>
											<b-badge variant="primary">
												<span style="font-size: 11px">Procesando video</span>
											</b-badge>
										</div>
									</div>
								</div>
								<span
									v-if="
										incidence.evidence_image.url === null && incidence.evidence_video.url === null
									"
								>
									Sin evidencias.
								</span>
							</b-td>
							<!-- <b-td v-if="origin == 'by_user'">
								<div class="d-flex w-100 align-items-center justify-content-center">
									<b-button
										variant="primary"
										class="btn-sm"
										@click="
											showRegister = true
											infoDetail = incidence
										"
									>
										<feather-icon icon="EyeIcon" />
									</b-button>
								</div>
							</b-td> -->
						</b-tr>
					</template>
					<b-tr v-if="incidences.length == 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>

		<RegisterIncidenceModal
			v-if="showRegister"
			:info="infoDetail"
			@closing="showRegister = false"
			@refresh="getIncidences"
			@refresh-viewed="getIncidences()"
		/>
	</div>
</template>

<script>
import modalMixin from "@/mixins/modal"
import IncidencesService from "@/services/incidences.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ViewImage from "@/components/commons/ViewImage.vue"
import TableIncidence from "@/views/amg/header-bookmarks/incidences/TableIncidence.vue"
import RegisterIncidenceModal from "@/views/amg/header-bookmarks/incidences/RegisterIncidenceModal.vue"
import { mapActions, mapState } from "vuex"
import moment from "moment"

export default {
	components: { SpecimenPlate, ViewImage, TableIncidence, RegisterIncidenceModal },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		isGeneral: Boolean,
		origin: {
			required: false,
			type: String,
		},
		refreshCount: Number,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			incidences: [],
			showRegister: false,
			infoDetail: null,
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
			weekFrom: null,
			weekTo: null,
			dateCheck: false,
			search: null,
			isExpanded: {},
		}
	},
	async created() {
		await this.getIncidences()
		this.generateWeekDates()
	},
	computed: {
		...mapState("header_book", [
			"imagesVideoteca",
			"openModal",
			"uploadImages",
			"upload",
			"toggleOpenGallery",
			"indexImage",
		]),
		...mapState("video", ["videos", "uploading"]),
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		...mapActions("header_book", [
			"setImagesVideoteca",
			"toggleModal",
			"setIndexImage",
			"setTextImage",
			"setUploadImages",
			"deleteUploadFile",
			"toggleBgUpload",
			"initToggleUpload",
			"toggleModalGallery",
			"toggleUploadGallery",
			"setVideoteca",
		]),
		getReducedDesc(description) {
			const maxCharacters = 100 // Número máximo de caracteres para la vista reducida
			return description.length > maxCharacters ? description.slice(0, maxCharacters) + "..." : description
		},
		// Alterna entre ver más y ver menos para una incidencia específica
		toggleExpand(index) {
			if (this.isExpanded[index] === undefined) {
				this.$set(this.isExpanded, index, false)
			}
			// Cambia el estado de expansión
			this.isExpanded[index] = !this.isExpanded[index]
		},

		viewSpecimen(item, index) {
			this.toggleModal()
			this.setTextImage({
				title: `Incidencia`,
				url: `/ejemplares/detalle/`,
			})
			this.setIndexImage(index)
		},
		previousWeek() {
			if (!this.dateCheck) return
			const newFrom = new Date(this.weekFrom)
			const newTo = new Date(this.weekTo)
			newFrom.setDate(newFrom.getDate() - 6)
			newTo.setDate(newTo.getDate() - 6)
			this.weekFrom = moment(newFrom).format("YYYY-MM-DD")
			this.weekTo = moment(newTo).format("YYYY-MM-DD")
		},
		nextWeek() {
			if (!this.dateCheck) return
			const newFrom = new Date(this.weekFrom)
			const newTo = new Date(this.weekTo)
			newFrom.setDate(newFrom.getDate() + 8)
			newTo.setDate(newTo.getDate() + 8)
			this.weekFrom = moment(newFrom).format("YYYY-MM-DD")
			this.weekTo = moment(newTo).format("YYYY-MM-DD")
		},
		async generateWeekDates() {
			const currentDate = new Date()
			const weekDays = currentDate.getDay() // Retorna un número del 0 al 6, donde 0 es domingo.

			// Calcular la diferencia entre el día actual y el lunes
			const differenceBetweenDays = weekDays === 0 ? -6 : 1 - weekDays
			const mondayDate = new Date(currentDate)
			mondayDate.setDate(currentDate.getDate() + differenceBetweenDays)

			// Calcular la diferencia entre el día actual y el domingo
			const diasDiferenciaDomingo = weekDays === 0 ? 0 : 7 - weekDays
			const sundayDate = new Date(currentDate)
			sundayDate.setDate(currentDate.getDate() + diasDiferenciaDomingo)

			this.weekFrom = moment(mondayDate).format("YYYY-MM-DD")
			this.weekTo = moment(sundayDate).format("YYYY-MM-DD")
		},
		async refresh() {
			await this.getIncidences()
		},
		async getIncidences() {
			this.incidences = []
			this.isPreloading()
			try {
				const { data } = await IncidencesService.getIncidences({
					is_admin: this.isAdmin ? 1 : 0,
					user_id: this.origin && this.origin == "by_user" ? this.info.id : null,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
					specimen_id:
						this.origin && this.origin == "by_user"
							? null
							: this.info && this.info.id
							? this.info.id
							: null,
					start_date: this.dateCheck ? this.weekFrom : null,
					end_date: this.dateCheck ? this.weekTo : null,
					search: this.search,
				})
				this.incidences = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to

				this.setImagesVideoteca(
					data.data.map((item) => {
						return {
							...item,
							id: item.id,
							id_specimens: item.specimen.id,
							plate: item.specimen.plate,
							name: item.specimen.alias ? item.specimen.alias : "",
							preload: item.evidence_video.thumb,
							type: "video",
							url: item.evidence_video.url,
							evidence: true,
						}
					})
				)
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getIncidences()
		},
		"pagination.perPage": async function () {
			await this.getIncidences()
		},
		refreshCount: async function () {
			await this.getIncidences()
		},
		weekFrom: async function () {
			await this.getIncidences()
		},
		dateCheck: async function () {
			await this.getIncidences()
		},
	},
	async mounted() {
		this.setVideoteca(true)
	},
	destroyed() {
		this.setVideoteca(false)
		this.setImagesVideoteca([])
	},
}
</script>

<style lang="scss" scoped>
.add-border {
	border-left: 1px solid #c6c5d1;
}
.is-seen {
	background-color: rgba(185, 230, 165, 0.3);
}
.check-disabled {
	opacity: 0.5;
	cursor: default;
}
</style>

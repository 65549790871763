<template>
	<b-overlay :show="isLoading">
		<b-row class="gap-y-1">
			<b-col cols="12" md="4">
				<h2 class="text-secondary font-weight-bolder">POR LINEAS</h2>
			</b-col>
			<b-col cols="12" md="8" class="d-flex justify-content-end align-items-center gap-x-2">
				<b-input-group size="sm" prepend="Genero" style="max-width: 15rem">
					<b-form-select
						style="min-width: 6rem"
						:options="genderOpts"
						v-model="gender"
						@change="changePagination(1, 'select')"
					/>
				</b-input-group>
				<b-input-group size="sm" prepend="Rango" style="max-width: 15rem">
					<b-form-select
						style="min-width: 6rem"
						:options="rangeOpts"
						v-model="range"
						@change="changePagination(1, 'select')"
					/>
				</b-input-group>
				<b-button-group size="sm">
					<b-button
						class="btn-icon"
						variant="outline-secondary"
						:disabled="pagination.page <= 1"
						@click="changePagination(-1, 'paginator')"
					>
						<feather-icon icon="ChevronLeftIcon" />
					</b-button>
					<b-button
						class="btn-icon"
						variant="outline-secondary"
						:disabled="pagination.page >= pagination.maxPage"
						@click="changePagination(1, 'paginator')"
					>
						<feather-icon icon="ChevronRightIcon" />
					</b-button>
				</b-button-group>
			</b-col>
		</b-row>
		<hr />
		<VueApexCharts type="bar" height="300" :options="chartOpts" :series="chartData" />
	</b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { year: Object, info: Object, count: Number },
	data: () => ({
		isLoading: true,
		genderOpts: [
			{ value: null, text: "Todos" },
			{ value: 1, text: "Machos" },
			{ value: 2, text: "Hembras" },
		],
		gender: null,
		rangeOpts: [
			// { value: null, text: "Todos" },
			{ value: 13, text: ">= 13%" },
			{ value: 25, text: ">= 25%" },
			{ value: 50, text: ">= 50%" },
			{ value: 75, text: ">= 75%" },
		],
		range: 25,
		chartData: [
			{
				name: "Linea",
				data: new Array(6).fill(3),
			},
		],
		chartOpts: {
			labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"],
			colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
			xaxis: { labels: { trim: true, rotate: 0, hideOverlappingLabels: false } },
			fill: {
				type: "gradient",
				gradient: {
					shade: "light",
					type: "horizontal",
					shadeIntensity: 0.25,
					gradientToColors: undefined,
					inverseColors: true,
					opacityFrom: 0.85,
					opacityTo: 0.85,
					stops: [50, 0, 100],
				},
			},
		},
		pagination: {
			page: 1,
			maxPage: 2,
		},
	}),
	methods: {
		async getData() {
			if (this.info) {
				this.isLoading = false
			}
			this.pagination.maxPage = this.info?.last_page
			this.chartData = [
				{
					name: "Linea",
					data: this.info?.data?.map((d) => d.counter),
				},
			]
			this.chartOpts = {
				labels: this.info?.data?.map((d) => d.name),
				colors: ["#00cfe8", "#28c76f", "#ff9f43", "#ea5455"],
				xaxis: { labels: { trim: true, rotate: 0, hideOverlappingLabels: false } },
			}
		},
		changePagination(diff, origin) {
			if (origin === "select") {
				this.pagination.page = diff
			} else {
				this.pagination.page += diff
			}
			this.isLoading = true
			this.$emit("refresh", { gender: this.gender, percentage: this.range, page: this.pagination.page })
		},
	},
	watch: {
		info() {
			this.getData()
		},
		count() {
			this.isLoading = true
		},
	},
}
</script>

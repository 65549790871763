<template>
	<div v-if="image" class="container-image" v-b-tooltip.hover :title="image.feature == 1 ? 'Imagen principal' : ''">
		<!-- {{ image.feature }} -->
		<img :src="image.thumb" alt="" />

		<div class="container-skeletor" :class="[image.duration ? 'video' : '']">
			<feather-icon
				@click="image.feature == 0 && (image.check = !image.check)"
				class="icon"
				:icon="!image.check ? 'CircleIcon' : 'CheckCircleIcon'"
				size="18"
			/>
			<div class="skeletor-image">
				<FeatherIcon
					v-if="image.duration"
					@click="
						$emit('viewSpecimen', {
							item: image,
							index: index,
						})
					"
					icon="EyeIcon"
					size="24"
					class="text-white cursor-pointer"
				/>
			</div>
			<div class="duration" v-if="image.duration">
				<p class="mb-0">
					{{ image.duration }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AddSpecimenToSaleImage",
	props: {
		image: { type: Object, default: () => {} },
		index: { type: Number, default: null },
	},
	methods: {
		viewSpecimen() {},
	},
};
</script>

<style lang="scss">
.container-image {
	// border: 1px solid #000;
	padding: 0.2rem;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
	position: relative;
	// height: 80px;
	img {
		min-height: 94px;
		border-radius: 5px;
		width: 100%;
		object-fit: cover;
	}
	.icon {
		position: absolute;
		top: 4px;
		right: 4px;
		background: #fff;
		color: var(--primary);
		border-radius: 50%;
		// padding: .1rem;
		cursor: pointer;
	}
	.duration {
		position: absolute;
		bottom: 3px;
		right: 3px;
		border-radius: 5px;
		background: red;
		color: white;
		padding: 0 0.3rem;
		p {
			margin-bottom: 0;
			font-size: 12px;
			font-weight: bold;
		}
	}
	.container-skeletor {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		left: 0;

		display: flex;
		align-items: center;

		justify-content: center;
		.skeletor-image {
			opacity: 0;
			transition: 0.3s ease-in-out all;
			background: rgb(0 0 0 / 20%);
			width: 96%;
			height: 96%;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: auto;
		}
		.duration {
			z-index: 200;
		}
		.icon {
			z-index: 200;
			// cursor: none;
		}
		.active {
			opacity: 1;
		}
	}
	.video {
		.skeletor-image {
			cursor: pointer;
			&:hover {
				opacity: 1;
			}
		}
	}
}
</style>

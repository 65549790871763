<template>
	<b-modal hide-footer centered size="lg" :title="titleModalSpecimen" v-model="isActive" @hidden="handleHidden">
		<!-- <b-overlay :show="isLoading"> -->
		<b-row class="mb-2">
			<b-col cols="12" md="12">
				<b-tabs v-model="tab" :small="widthScreen < 992 ? true : false" @input="getChildsDead">
					<b-tab>
						<template #title>TOTAL: {{ totalDeads }}</template>
					</b-tab>
					<b-tab>
						<template #title>CINTILLO EN PATA ({{ cintilloEnPata }})</template>
					</b-tab>
					<b-tab>
						<template #title>CINTILLO EN ALA ({{ cintilloEnAla }})</template>
					</b-tab>
					<b-tab>
						<template #title>PLACA ({{ deadsWithPlate }})</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" md="12">
				<VueApexCharts type="pie" height="450" :options="pieOpts" :series="pieData" />
			</b-col>
		</b-row>
		<!-- </b-overlay> -->
	</b-modal>
</template>

<style></style>

<script>
import VueApexCharts from "vue-apexcharts"
import ChildsDeadService from "@/services/childsDead.service"

export default {
	components: { VueApexCharts },
	props: {
		show: Boolean,
		info: Object,
		_id: Number,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		tab: null,
		pieData: [],
		pieOpts: {},
		totalDeads: null,
		cintilloEnPata: null,
		cintilloEnAla: null,
		deadsWithPlate: null,
		titleModalSpecimen: null,
		positionLegend: null,
		widthLegend: null,
	}),
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
	},
	mounted() {
		this.positionLegend = this.widthScreen < 992 ? "bottom" : "right"
		this.widthLegend = this.widthScreen < 1600 ? null : 275
	},
	methods: {
		async getChildsCategoryData() {
			let dataChildsDead = this.info.specimen ?? this.info
			const { data } = await ChildsDeadService.getChildsCategoryData(this._id)
			this.titleModalSpecimen = `Hijos muertos de ${dataChildsDead.alias ?? dataChildsDead.plate}`
			this.cintilloEnAla = Number(data.deads_ca ?? 0)
			this.cintilloEnPata = Number(data.deads_cp ?? 0)
			this.deadsWithPlate = Number(data.deasd_spe ?? 0)
			this.totalDeads = this.cintilloEnAla + this.cintilloEnPata + this.deadsWithPlate
		},
		async getChildsDead() {
			this.isPreloading()
			let dataDeads = []
			let dataLabels = []
			const { data } = await ChildsDeadService.getChildsDead(this.tab + 1, this._id)
			data.forEach((index) => {
				if (!Number(index.amount)) {
					return
				}
				dataDeads.push(Number(index.amount))
				dataLabels.push(`${index.reason.toUpperCase()}: (${index.amount})`)
			})
			if (dataDeads.length == 0 && dataLabels.length == 0) {
				;(this.pieData = [1]),
					(this.pieOpts = {
						labels: ["Sin resultados"],
						colors: ["rgb(153, 153, 153)"],
						legend: {
							position: this.positionLegend,
							fontWeight: 600,
							labels: { colors: "rgb(153, 153, 153)" },
						},
						tooltip: {
							enabled: false,
						},
						dataLabels: {
							// valida si hay linea desconocida en el primer index del array de lineas para saber si mostrar etiquetas
							formatter: (val, opts) => {
								return ""
							},
						},
					}),
					this.isPreloading(false)
				return
			}

			this.pieData = dataDeads
			this.pieOpts = {
				labels: dataLabels,
				colors: ["#28c76f", "#ff9f43", "#ea5455", "#6258cc", "#5148a8", "#3f3984", "#2e2960", "#0b0a18"],
				legend: {
					position: this.positionLegend,
					width: this.widthLegend,
				},
				tooltip: {
					enabled: true,
					y: {
						title: {
							formatter: (seriesName) => seriesName.slice(0, -4),
						},
					},
				},
			}
			this.isPreloading(false)
		},
		handleHidden() {
			;(this.isLoading = false),
				(this.tab = null),
				(this.pieData = []),
				(this.pieOpts = {}),
				(this.totalDeads = null),
				(this.cintilloEnPata = null),
				(this.cintilloEnAla = null),
				(this.deadsWithPlate = null),
				(this.titleModalSpecimen = null)
			this.$emit("closing")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getChildsCategoryData()
			}
		},
	},
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","no-close-on-backdrop":"","title":"Acciones clinicas masivas","size":"lg"},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":_vm.applyAction}},[_vm._v("Aplicar")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('ValidationObserver',{ref:"form"},[_c('b-form-group',{attrs:{"label":"1. Seleccionar objetivo"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","options":[
							{ value: 1, text: 'Padrillos' },
							{ value: 2, text: 'Madrillas' },
							{ value: 3, text: 'Gallos' },
							{ value: 4, text: 'Gallinas' },
							{ value: 5, text: 'Pollones' },
							{ value: 6, text: 'Pollas' },
							{ value: 7, text: 'Todos los ejemplares' },
							{ value: 8, text: 'Seleccionar ejemplar(es)' } ],"reduce":function (option) { return option.value; },"clearable":false,"transition":"","placeholder":"Seleccionar"},model:{value:(_vm.objective),callback:function ($$v) {_vm.objective=$$v},expression:"objective"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),(_vm.objective == 8)?_c('div',{},[_c('b-form-group',{attrs:{"label":"Buscar ejemplares"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Ingrese placa o alias"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchSpecimens($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.searchSpecimens}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1)],1),_c('b-form-group',{staticClass:"bg-specimen-add",attrs:{"label":"Ejemplares seleccionados"}},[_c('b-row',[_vm._l((_vm.selectedSpecimens),function(specimen){return _c('b-col',{key:specimen.id,staticClass:"text-center my-1 d-flex align-items-center justify-content-center",attrs:{"cols":"6"}},[_c('div',{staticClass:"position-relative clickable px-3"},[_c('SpecimenPlate',{attrs:{"specimen":specimen}}),_c('div',{staticClass:"optAction optAction--remove",on:{"click":function($event){return _vm.removeSelected(specimen)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"24"}})],1)],1)])}),(_vm.selectedSpecimens.length == 0)?_c('b-col',{staticClass:"text-center"},[_vm._v(" No hay ejemplares seleccionados ")]):_vm._e()],2)],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"2. Seleccionar accion"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":[
							{ value: 1, text: 'Desparacitacion' },
							{ value: 2, text: 'Suplementos' } ]},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),(_vm.action == 2)?[_c('b-form-group',{attrs:{"label":"3. Suplemento"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"label":"text","options":_vm.vitaminOpts,"reduce":function (opt) { return opt.value; }},model:{value:(_vm.vitamin),callback:function ($$v) {_vm.vitamin=$$v},expression:"vitamin"}})]}}],null,false,2528045445)})],1),_c('b-form-group',{attrs:{"label":"4. Tipo de suplemento"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":[
								{ value: 1, text: 'Frecuente' },
								{ value: 2, text: 'Ocasional' } ]},model:{value:(_vm.vitaminType),callback:function ($$v) {_vm.vitaminType=$$v},expression:"vitaminType"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,2611100334)})],1),(_vm.vitaminType == 1)?_c('b-form-group',{attrs:{"label":"5. Días"}},[_c('v-select',{staticStyle:{"width":"100%"},attrs:{"label":"text","multiple":"","reverse":"","clearable":false,"options":_vm.days,"reduce":function (opt) { return opt.value; }},model:{value:(_vm.vitaminDays),callback:function ($$v) {_vm.vitaminDays=$$v},expression:"vitaminDays"}})],1):_vm._e()]:_vm._e()],2)],1),_c('b-modal',{attrs:{"centered":"","hide-footer":"","title":"Seleccione un ejemplar","size":"lg"},model:{value:(_vm.optionsModal.show),callback:function ($$v) {_vm.$set(_vm.optionsModal, "show", $$v)},expression:"optionsModal.show"}},[_c('b-row',_vm._l((_vm.optionsModal.opts),function(specimen){return _c('b-col',{key:specimen.id,attrs:{"cols":"6"}},[_c('div',{staticClass:"position-relative clickable mb-1"},[_c('SpecimenPlate',{attrs:{"specimen":specimen}}),_c('div',{staticClass:"optAction optAction--add",on:{"click":function($event){return _vm.selectSpecimen(specimen)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"24"}})],1)],1)])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<div class="container-tabs mb-1">
		<div class="d-flex justify-content-between align-items-center">
			<div class="cross-center gap-x-1" v-if="[1, 2].includes(category)">
				<span class="text-primary">AÑO</span>
				<v-select
					v-model="currentYear"
					label="season"
					:options="years"
					:reduce="opt=>opt.id"
					transition=""
					:clearable="false"
					:style="{ maxWidth: '11ch', minWidth: '11ch' }"
				/>
			</div>

			<div class="p-1 d-flex" v-show="show">
				<!-- <div class="c-pata mr-1">Cintillo Pata: {{ counters.eggs }}</div> -->
				<div class="c-correlative">Cintillo Ala: {{ quantity_ca }}</div>
				<div class="c-plate">Con Placa: {{ quantity_assigned_plate }}</div>
			</div>
		</div>

		<b-tabs v-model="indexTab">
			<b-tab>
				<template slot="title">
					Cintillo Patas
					<b-badge variant="danger" class="m-05">
						{{ counters.eggs }}
					</b-badge>
				</template>
				<div v-if="indexTab == 0 && posturasWithChicks.length">
					<SonsByIdSpecimenItem
						is_cintillo_pata
						:category="category"
						:posturas-with-chicks="posturasWithChicks"
					/>
				</div>
			</b-tab>
			<b-tab>
				<template slot="title">
					Machos
					<b-badge variant="danger" class="m-05">
						{{ counters.males }}
					</b-badge>
				</template>
				<div v-if="indexTab == 1 && posturasWithChicks.length">
					<SonsByIdSpecimenItem
						:gender="indexTab"
						:category="category"
						:posturas-with-chicks="posturasWithChicks"
					/>
				</div>
			</b-tab>
			<b-tab>
				<template slot="title">
					Hembras
					<b-badge variant="danger" class="m-05">
						{{ counters.females }}
					</b-badge>
				</template>
				<div v-if="indexTab == 2 && posturasWithChicks.length">
					<SonsByIdSpecimenItem
						:gender="indexTab"
						:category="category"
						:posturas-with-chicks="posturasWithChicks"
					/>
				</div>
			</b-tab>
			<b-tab>
				<template slot="title">
					Sin asignar
					<b-badge variant="danger" class="m-05">
						{{ counters.no_assigneds }}
					</b-badge>
				</template>
				<div v-if="indexTab == 3 && posturasWithChicks.length">
					<SonsByIdSpecimenItem
						:gender="indexTab"
						:category="category"
						:posturas-with-chicks="posturasWithChicks"
					/>
				</div>
			</b-tab>
			<b-tab>
				<template slot="title">
					Todos
					<b-badge variant="danger" class="m-05">
						{{ counters.total }}
					</b-badge>
				</template>
				<div v-if="indexTab == 4 && posturasWithChicks.length">
					<SonsByIdSpecimenItem
						gender="todos"
						:category="category"
						:posturas-with-chicks="posturasWithChicks"
					/>
				</div>
			</b-tab>
		</b-tabs>

		<template v-if="counters.total == 0">
			<h2 class="mt-2 text-center">El ejemplar no tiene hijos en este año</h2>
		</template>
	</div>
</template>

<script>
import SonsByIdSpecimenItem from "./SonsByIdSpecimenItem.vue";
import specimenService from "@/services/specimens.service";
export default {
	name: "SonsByIdSpecimen",
	props: {
		modalSonsBySpecimen: {
			type: Object,
			required: true,
		},
		category: {
			type: Number,
			required: true,
		},
		year: {
			default: null,
		},
	},
	components: {
		SonsByIdSpecimenItem,
	},
	created() {
		this.specimenIdIn = this.modalSonsBySpecimen.idSpecimen;
		this.currentYear = this.year;
		this.getSons();
	},
	computed: {
		quantity_ca() {
			let cas = 0;
			for (const postura of this.posturasWithChicks) {
				if (postura.chicks) {
					for (const chick of postura.chicks) {
						// && (this.indexTab + 1 < 4 ? chick.gender == this.indexTab + 1 : true)
						if (chick.correlative) cas++;
					}
				}
			}
			return cas;
		},
		quantity_assigned_plate() {
			let plates = 0;
			for (const postura of this.posturasWithChicks) {
				if (postura.chicks) {
					for (const chick of postura.chicks) {
						if (
							chick.assigned_plate &&
							(this.indexTab < 4 ? chick.gender == this.indexTab : true)
						) {
							plates++;
						}
					}
				}
			}
			return plates;
		},
		years() {
			if(this.currentYear == null) this.currentYear = this.seasons[0].id;
			return this.$store.state.utilities.seasons;
		},
		ammendedYear() {
			return this.seasonFromId(this.currentYear);
		},
	},
	watch: {
		currentYear(newValue, oldValue) {
			if (newValue !== oldValue && oldValue) {
				this.getSons();
			}
		},
	},
	data() {
		return {
			indexTab: this.modalSonsBySpecimen.index,
			genderSpecimen: null,
			specimenIdIn: null,
			currentYear: null,
			posturasWithChicks: [],
			counters: {
				males: 0,
				females: 0,
				no_assigneds: 0,
				total: 0,
				eggs: 0,
			},
			show: false,
		};
	},
	methods: {
		async getSons() {
			this.isPreloading();
			this.show = false;

			this.counters = {
				males: 0,
				females: 0,
				no_assigneds: 0,
				total: 0,
				eggs: 0,
			};

			this.posturasWithChicks = await specimenService.getSonsByIdSpecimenByGender({
				specimen_id: this.specimenIdIn,
				date_start: this.ammendedYear.start,
				date_end: this.ammendedYear.end,
			});
			let eggs = 0;
			for (let postura of this.posturasWithChicks) {
				if (postura.chicks) {
					for (const chick of postura.chicks) {
						if (chick.gender == 1) {
							this.counters.males++;
						}
						if (chick.gender == 2) {
							this.counters.females++;
						}
						if (chick.gender == 3) {
							this.counters.no_assigneds++;
						}
						this.counters.total++;
					}
				}
				eggs += postura.eggs * 1;
			}

			this.counters.eggs = eggs;
			this.counters.total += eggs;

			this.show = true;
			this.isPreloading(false);
		},
	},
};
</script>

<style lang="scss" scoped>
.c-pata {
	border: 1px solid #ccc;
	padding: 2px 10px;
	border-radius: 5px;
}

.c-correlative {
	background: #7367F0;
	color: #ffff;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 5px 10px;
}

.c-plate {
	background: #252528;
	color: #ffff;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 5px 10px;
}
</style>

<template>
	<div class="text-center mx-auto container-btns">
		<div class="d-flex justify-content-center" style="gap: 0.5rem">
			<!-- <b-form-checkbox v-if="hasCheckbox" v-model="specimen.is_sale" @change="toogleCheckbox" :key="updt" /> -->

			<div class="container-btn-drop" v-if="hasChangeStatus" v-click-outside="closeWindow" :key="id">
				<FeatherIcon
					@click="activeDrop = !activeDrop"
					icon="RepeatIcon"
					size="18"
					class="cursor-pointer input-close text-danger"
					v-b-tooltip.hover.top="`Cambiar estado`"
				/>
				<div class="options-drop" :class="[activeDrop ? 'active' : '']">
					<p
						v-for="(item, index) in arrayStatus.filter((it) => it.id != status_id)"
						:key="index"
						@click="changeToStatus(item.id)"
						class="cursor-pointer"
					>
						{{ item.name }}
					</p>
				</div>
			</div>

			<div v-if="hasCheckbox">
				<feather-icon
					class="cursor-pointer text-danger"
					icon="CircleIcon"
					size="20"
					v-if="(specimen.sale && specimen.sale.status_id == 2) || (inModal && !specimen.is_sale)"
					@click="toogleCheckbox(true)"
				/>
				<feather-icon
					class="cursor-pointer text-secondary"
					icon="CircleIcon"
					size="20"
					v-else-if="specimen.sale == null && !specimen.is_sale"
					@click="toogleCheckbox(true)"
				/>
				<feather-icon
					class="cursor-pointer text-success"
					icon="CheckCircleIcon"
					size="20"
					v-else-if="specimen.sale != 1 || specimen.is_sale"
					@click="toogleCheckbox(false)"
				/>
			</div>

			<feather-icon
				class="cursor-pointer text-primary"
				icon="Edit3Icon"
				size="20"
				v-b-tooltip.hover.top="`Editar informacion`"
				v-if="editable && hasEdit"
				@click="modalAddSpecimenTosale.show = true"
			/>
			<feather-icon
				v-if="hasTracking"
				class="cursor-pointer text-secondary"
				icon="InfoIcon"
				size="20"
				v-b-tooltip.hover.top="`Ver tracking`"
				@click="getTracking"
			/>
		</div>

		<div v-if="specimen.sale && specimen.is_sale" class="mt-25">
			<p class="mb-0 font-small-3 price-absolute">
				{{ arrayCurrency[arrayCurrency.findIndex((it) => it.id == specimen.sale.currency_id)].name }}
				{{ specimen.sale.price.toFixed(2) }}
			</p>
		</div>

		<b-modal
			v-model="modalAddSpecimenTosale.show"
			title=" VENTA / SELECCIONAR INFORMACION"
			size="md"
			hide-footer
			@hidden="$emit('refresh')"
		>
			<AddSpecimenToSale
				v-if="modalAddSpecimenTosale.show"
				:specimen_id="specimen.id"
				:specimen_name="`${specimen.alias ? specimen.alias : ''} (${specimen.plate})`"
				:specimen_price="price"
				:specimen_currency_id="currency_type"
				@refresh="modalAddSpecimenTosale.show = false"
			>
				<template slot="specimen-plate">
					<SpecimenPlate
						:specimen="{
							id: specimen.id,
							plate: specimen.plate,
							alias: specimen.alias,
							thumb: specimen.thumb,
							image: specimen.photo,
						}"
					/>
				</template>
			</AddSpecimenToSale>
		</b-modal>
		<b-modal
			v-model="showTracking"
			centered
			hide-footer
			:title="`Tracking / ${this.specimen.alias} (${this.specimen.plate})`"
		>
			<b-table-simple class="table mb-0">
				<!-- {{ status_id }} -->
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-50">{{ "Registrado por" }}</b-th>
						<b-th class="p-50">Estado</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="row in trackingRows" :key="row.value">
						<b-td class="p-50">
							<p class="mb-0">{{ row.created_at.user }}</p>
							<p class="mb-0">{{ row.created_at.date | myGlobalDayShort }}</p>
						</b-td>
						<b-td
							class="p-50 font-weight-bold"
							:class="[
								row.status_id == 1 ? 'text-success' : '',
								row.status_id == 2 ? 'text-danger' : '',
								row.status_id == 3 ? 'text-info' : '',
								row.status_id == 4 ? 'text-warning' : '',
							]"
						>
							{{ row.name }}
						</b-td>
					</b-tr>
					<b-tr class="text-center font-weight-bold" v-if="trackingRows.length == 0">
						<b-td colspan="4">No hay datos para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-modal>
	</div>
</template>

<script>
import { bus } from "@/main";
import PublicWebService from "@/services/publicWeb.service.js";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import AddSpecimenToSale from "./dashboard/AddSpecimenToSale.vue";

import vClickOutside from "v-click-outside";

export default {
	name: "ChangeSpecimenToSale",
	components: { SpecimenPlate, AddSpecimenToSale },
	directives: {
		clickOutside: vClickOutside.directive,
	},
	props: {
		specimen: {
			type: Object,
			default: () => {},
		},
		currency_type: {
			type: Number,
		},
		price: {
			type: Number,
		},
		hasCheckbox: {
			type: Boolean,
			default: true,
		},
		hasEdit: {
			type: Boolean,
			default: true,
		},
		hasSell: {
			type: Boolean,
			default: false,
		},
		hasReturn: {
			type: Boolean,
			default: false,
		},
		hasReserve: {
			type: Boolean,
			default: false,
		},
		hasTracking: {
			type: Boolean,
			default: false,
		},
		hasChangeStatus: {
			type: Boolean,
			default: false,
		},
		status_id: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			modalAddSpecimenTosale: {
				show: false,
				title: null,
			},
			arrayCurrency: [
				{
					id: 1,
					name: "USD",
				},
				{
					id: 2,
					name: "PEN",
				},
			],
			showTracking: false,
			trackingRows: [],
			updt: 1, // to update the checkbox
			activeDrop: false,
			arrayStatus: [
				{
					id: 1,
					name: "Activo",
				},
				{
					id: 2,
					name: "Inactivo",
				},
				{
					id: 3,
					name: "Reservado",
				},
				{
					id: 4,
					name: "Vendido",
				},
			],
			id: 1,
		};
	},
	computed: {
		editable() {
			if (this.specimen.sale || this.specimen.is_sale || this.inModal) {
				return true;
			}
			return false;
		},
		inModal() {
			return !!this.price;
		},
	},
	methods: {
		async toogleCheckbox(e) {
			if (e) {
				const { isConfirmed } = await this.showConfirmSwal({
					title: `Seguro de activar al ejemplar ${this.specimen.alias || this.specimen.plate} para venta?`,
				});
				if (!isConfirmed) {
					this.specimen.is_sale = false;
					this.updt++;
					return;
				}
				this.modalAddSpecimenTosale.show = true;
				return;
			}
			// markAsDeactivated
			const { isConfirmed } = await this.showConfirmSwal({
				// title: `A punto de desactivar venta del especimen ${this.specimen.alias || this.specimen.plate}`,
				title: `Seguro de desactivar al ejemplar ${this.specimen.alias || this.specimen.plate} para venta?`,
			});
			if (!isConfirmed) {
				this.specimen.is_sale = true;
				this.updt++;
				return;
			}
			this.isPreloading();
			await PublicWebService.updateStatus({
				specimen_id: this.specimen.id,
				status_id: 2,
				type_id: 2,
			});
			this.showToast(
				"success",
				"top-right",
				"Ejemplares",
				"CheckIcon",
				`Se desactivo al ejemplar ${this.specimen.alias || this.specimen.plate}`
			);
			this.isPreloading(false);
			this.$emit("refresh");
			bus.$emit("reload-counter-web");
		},
		async getTracking() {
			this.isPreloading();
			const { data } = await PublicWebService.getSpecimenForPublicTracking({
				specimen_id: this.specimen.id,
				type_id: 2,
			});
			this.trackingRows = data;
			this.showTracking = true;
			this.isPreloading(false);
		},
		async changeToStatus(status_id) {
			let swalMsg = `Seguro de modificar al ejemplar ${this.specimen.alias || this.specimen.plate}?`;
			switch (status_id) {
				case 2:
					swalMsg = `Seguro de desactivar al ejemplar ${
						this.specimen.alias || this.specimen.plate
					} para venta?`;
					break;
				case 3:
					swalMsg = `Seguro de reservar al ejemplar ${this.specimen.alias || this.specimen.plate}?`;
					break;
				case 4:
					swalMsg = `Seguro de vender al ejemplar ${this.specimen.alias || this.specimen.plate}?`;
					break;
				case 1:
					swalMsg = `Seguro de activar al ejemplar ${this.specimen.alias || this.specimen.plate} para venta?`;
					break;
			}

			const { isConfirmed } = await this.showConfirmSwal({ title: swalMsg });
			if (!isConfirmed) return;
			this.isPreloading();
			await PublicWebService.updateStatus({
				specimen_id: this.specimen.id,
				status_id: status_id,
				type_id: 2,
			});
			this.activeDrop = false;
			this.isPreloading(false);

			let messa = "";
			switch (status_id) {
				case 1:
					messa = `Se activo al ejemplar ${this.specimen.alias || this.specimen.plate} para venta`;
					break;
				case 2:
					messa = `Se desactivo al ejemplar ${this.specimen.alias || this.specimen.plate} para venta`;
					break;
				case 3:
					messa = `Se reservo al ejemplar ${this.specimen.alias || this.specimen.plate}`;
					break;
				case 4:
					messa = `Se vendio al ejemplar ${this.specimen.alias || this.specimen.plate}`;

					break;
			}

			bus.$emit("reload-counter-web");

			this.showToast("success", "top-right", "Ejemplares", "CheckIcon", messa);
			this.$emit("refresh");
		},
		closeWindow() {
			this.activeDrop = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.container-btns {
	// background: red !important;s
	// height: 70px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// margin-top: 20px;
	position: relative;
	.price-absolute {
		position: absolute;
		// bottom: 0;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
}

.container-btn-drop {
	position: relative;
	.options-drop {
		position: absolute;
		bottom: 0%;
		right: 140%;
		z-index: 2000;
		background: white;
		box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
		display: none;
		border-radius: 4px;
		// opacity: 0;
		p {
			margin-bottom: 0;
			color: #000;
			padding: 0.5rem 1.5rem;
			border-bottom: 1px solid #f5f5f5;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	.active {
		display: flex;
		opacity: 1;
		flex-direction: column;
		// background: red;
	}
}
</style>

<template>
	<b-sidebar size="xmd" width="90%" centered hide-footer :title="fmtTitle" v-model="isActive" @hidden="handleHidden"
		body-class="p-0 p-md-2" header-class="border-top-2">
		<b-overlay class="px-2" :show="isLoading">
			<b-row
				v-if="isValidCondition && (padrilloSpecimen || specimen.padrillo_id || madrillaSpecimen || specimen.madrilla_id != null)"
				class="mb-2">
				<b-col sm="6" lg="3" v-if="padrilloSpecimen || specimen.padrillo_id">
					<b-card footer-class="d-block text-center h4 p-1" footer="Padrillo">
						<SpecimenPlate v-if="padrilloSpecimen" :specimen="padrilloSpecimen" />
						<SpecimenPlate v-else :specimen="{
							plate: specimen.padrillo_plate,
							alias: specimen.padrillo_alias,
							id: specimen.padrillo_id,
							thumb: specimen.padrillo_image.photo,
							image: specimen.padrillo_image.thumb,
						}" />

						<div v-if="
							rows &&
							rows.length > 0 &&
							rows[0] &&
							rows[0].padrillo &&
							rows[0].padrillo.behavior === 'rajado'
						" v-b-tooltip.hover="`Corrido`" class="container-paw">
							<img src="@/assets/images/svg/paw1.svg" class="img-paw" />
						</div>
					</b-card>
				</b-col>
				<b-col sm="6" lg="3" v-if="madrillaSpecimen || specimen.madrilla_id != null">
					<b-card footer-class="d-block text-center h4 p-1" footer="Madrilla">
						<SpecimenPlate v-if="madrillaSpecimen" :specimen="madrillaSpecimen" />
						<SpecimenPlate v-else :specimen="{
							plate: specimen.madrilla_plate,
							alias: specimen.madrilla_alias,
							id: specimen.madrilla_id,
							thumb: specimen.madrilla_image.photo,
							image: specimen.madrilla_image.thumb,
						}" />
					</b-card>
				</b-col>

				<b-col sm="12" lg="6" xl="6" class="d-flex justify-content-end mb-2">
					<!-- ASD -->
					<TableGenderBrothersBySpecimen class="container-table-genders-children" v-if="countersGender"
						:countersGender="countersGender" />
				</b-col>
			</b-row>
			
			<div class="bg-white pb-1" style="border-radius: 8px">
				<div class="bg-transparent align-item-center p-1 flex-lg-row justify-content-md-between">
					<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="tabGeneral == 1" @click="tabGeneral = 1" :disabled="counterBrothers == 0">
							<span>{{ "HERMANOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="counterBrothers > 0">
								{{ counterBrothers }}
							</b-badge>
						</b-nav-item>
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="tabGeneral == 2" @click="tabGeneral = 2" :disabled="countHalfTotal == 0">
							<span>{{ "MEDIO HERMANO" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countHalfTotal">
								{{ countHalfTotal }}
							</b-badge>
						</b-nav-item>
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="tabGeneral == 3" @click="tabGeneral = 3" :disabled="nephewsTotal == 0">
							<span>{{ "SOBRINOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="nephewsTotal > 0">
								{{ nephewsTotal }}
							</b-badge>
						</b-nav-item>

						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="tabGeneral == 4" @click="tabGeneral = 4" :disabled="childsCountTotal == 0">
							<span>{{ "HIJOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="childsCountTotal > 0">
								{{ childsCountTotal }}
							</b-badge>
						</b-nav-item>
					</ul>
				</div>
			</div>

			<div v-if="tabGeneral == 1" class="bg-white pb-1" style="border-radius: 8px">
				<div class="card bg-transparent align-item-center p-1 flex-lg-row justify-content-md-between">
					<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="tab == 1" :disabled="specimens.length == 0" @click="tab = 1">
							<span>{{ "EJEMPLARES" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="specimens.length > 0">
								{{ specimens.length }}
							</b-badge>
						</b-nav-item>
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="tab == 2" :disabled="countChicks == 0" @click="tab = 2">
							<span>{{ "POLLOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countChicks > 0">
								{{ countChicks }}
							</b-badge>
						</b-nav-item>
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="tab == 3" :disabled="countCintillos == 0" @click="tab = 3">
							<span>{{ "CINTILLOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countCintillos > 0">
								{{ countCintillos }}
							</b-badge>
						</b-nav-item>
					</ul>
				</div>

				<div v-if="tab == 1" class="px-1 containers-genders">
					<ContainerGenderCard :specimens="specimens.filter((it) => it.specimen.gender_id == 1)"
						title="MACHOS" />

					<ContainerGenderCard :specimens="specimens.filter((it) => it.specimen.gender_id == 2)"
						title="HEMBRAS" />
				</div>

				<b-row v-if="tab == 2" class="px-1">
					<b-col cols="12" lg="4" class="mb-1" sm="6" v-for="(group, i) in chicks" :key="i">
						<b-card class="border-light rounded" header-bg-variant="light"
							header-class="d-block font-weight-bolder p-1 text-center" :header="group.name"
							body-class="container-chicks-corrales">
							<!-- <SpecimenPlate
								v-for="(chick, i) in group.chicks"
								:key="i"
								:specimen="rowToObject(chick)"
								:is_specimen="chick.specimen != null"
							/> -->

							<div class="chick-container" v-for="(chick, i) in group.chicks" :key="i" :class="[
								chick.chick.gender_id == 1 ? 'bg-primary text-white' : '',
								chick.chick.gender_id == 2 ? 'bg-info' : '',
								chick.chick.gender_id == 3 ? 'bg-secondary text-white' : '',
							]">
								{{ chick.chick.correlative }}
							</div>
						</b-card>
					</b-col>
				</b-row>
				<b-row v-if="tab == 3" class="px-1">
					<b-col cols="12" lg="4" class="mb-1" sm="6" v-for="(lote, i) in cintillos" :key="i">
						<b-card class="border-light rounded" header-bg-variant="light"
							header-class="d-block font-weight-bolder p-1 text-center" :header="lote.lote_code"
							body-class="container-chicks-corrales">
							<b-table-simple>
								<b-tr class="text-center bg-white">
									<b-th>Cantidad</b-th>
									<b-th>Postura</b-th>
									<b-th>Colores</b-th>
								</b-tr>
								<b-tr class="text-center bg-white">
									<b-td>{{ lote.bal }}</b-td>
									<b-td>{{ lote.postura_code }}</b-td>
									<b-td>
										<CombChickenlegsCintillos :postura="{
											chickenleg_wich_leg: lote.cintillos.wich_leg,
											chickenleg_qty_cintillos: lote.cintillos.qty_cintillos,
											cintillo_color: lote.cintillos.hex_1,
											cintillo_description: lote.cintillos.description_1,
											second_color_color: lote.cintillos.hex_2,
											second_color_description: lote.cintillos.description_2,
											total_minus_infertile_eggs: 1,
										}" :chickenleg_wich_leg="1" />
									</b-td>
								</b-tr>
							</b-table-simple>
						</b-card>
					</b-col>
				</b-row>
			</div>

			<div v-if="tabGeneral == 2" class="bg-white pb-1" style="border-radius: 8px">
				<div class="card bg-transparent align-item-center p-1 flex-lg-row justify-content-md-between">
					<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="halfBro == 1" @click="halfBro = 1" :disabled="countHalf.specimens == 0">
							<span>{{ "EJEMPLARES" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countHalf.specimens > 0">
								{{ countHalf.specimens }}
							</b-badge>
						</b-nav-item>
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="halfBro == 2" @click="halfBro = 2" :disabled="countHalf.chicks == 0">
							<span>{{ "POLLOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countHalf.chicks > 0">
								{{ countHalf.chicks }}
							</b-badge>
						</b-nav-item>
					</ul>
				</div>

				<div v-if="tabGeneral == 2" class="px-1">
					<v-select :options="arraystatusspecimen" :reduce="(opt) => opt.id" label="name"
						class="border-primary rounded bg-white" style="width: 250px" placeholder="Seleccionar specimen"
						v-model="selectSpecimen" :clearable="false" />
					<div v-if="halfBro == 1">
						<ContainerChildrem :selectSpecimen="selectSpecimen" :specimen="specimen" :tab="tab" />
					</div>
					<div v-if="halfBro == 2">
						<ContainerChildrem :selectSpecimen="selectSpecimen" :specimen="specimen" :tab="tab"
							:halfBro="halfBro" />
					</div>
				</div>
			</div>

			<div v-if="tabGeneral == 3" class="bg-white pb-1" style="border-radius: 8px">
				<div class="card bg-transparent align-item-center p-1 flex-lg-row justify-content-md-between">
					<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="nephews == 1" @click="nephews = 1" :disabled="nephewsCounter.specimens == 0">
							<span>{{ "EJEMPLARES" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="nephewsCounter.specimens > 0">
								{{ nephewsCounter.specimens }}
							</b-badge>
						</b-nav-item>
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="nephews == 2" @click="nephews = 2" :disabled="nephewsCounter.chicks == 0">
							<span>{{ "POLLOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="nephewsCounter.chicks > 0">
								{{ nephewsCounter.chicks }}
							</b-badge>
						</b-nav-item>
					</ul>
				</div>

				<div v-if="tabGeneral == 3" class="px-1">
					<div v-if="nephews == 1">
						<ContainerChildrem :selectSpecimen="selectSpecimen" :specimen="specimen" :tab="tab"
							:tabGeneral="tabGeneral" :nephews="nephews" />
					</div>
					<div v-if="nephews == 2">
						<ContainerChildrem :selectSpecimen="selectSpecimen" :specimen="specimen" :tab="tab"
							:halfBro="halfBro" :tabGeneral="tabGeneral" :nephews="nephews" />
					</div>
				</div>
			</div>

			<div v-if="tabGeneral == 4" class="bg-white pb-1" style="border-radius: 8px">
				<div class="card bg-transparent align-item-center p-1 flex-lg-row justify-content-md-between">
					<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="childs == 1" @click="childs = 1" :disabled="countChilds.specimens == 0">
							<span>{{ "EJEMPLARES" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countChilds.specimens > 0">
								{{ countChilds.specimens }}
							</b-badge>
						</b-nav-item>
						<b-nav-item link-classes="px-1 justify-content-between w-100" class="x-tab-width"
							:active="childs == 2" @click="childs = 2" :disabled="countChilds.chicks == 0">
							<span>{{ "POLLOS" }}</span>
							<b-badge pill variant="danger" class="ml-1" v-if="countChilds.chicks > 0">
								{{ countChilds.chicks }}
							</b-badge>
						</b-nav-item>
					</ul>
				</div>

				<div v-if="tabGeneral == 4" class="px-1">
					<div v-if="childs == 1">
						<ContainerChildrem :selectSpecimen="selectSpecimen" :specimen="specimen" :tab="tab"
							:tabGeneral="tabGeneral" :nephews="nephews" />
					</div>
					<div v-if="childs == 2">
						<ContainerChildrem :selectSpecimen="selectSpecimen" :specimen="specimen" :tab="tab"
							:halfBro="halfBro" :tabGeneral="tabGeneral" :nephews="nephews" />
					</div>
				</div>
			</div>
		</b-overlay>
	</b-sidebar>
</template>

<script>
import specimenService from "@/services/specimens.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import cardSpecimen from "./CardSpecimen.vue"
import TableGenderBrothersBySpecimen from "./TableGenderBrothersBySpecimen.vue"
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos.vue"
import ContainerGenderCard from "./ContainerGenderCard.vue"
import ContainerChildrem from "@/views/amg/specimens/dashboard/modals/ContainerChildrem.vue"
export default {
	props: {
		show: Boolean,
		specimen: Object,
		showSiblings: Boolean,
	},
	components: {
		SpecimenPlate,
		cardSpecimen,
		TableGenderBrothersBySpecimen,
		CombChickenlegsCintillos,
		ContainerGenderCard,
		ContainerChildrem,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		fields: ["Especimen", "Estado", "Postura"],
		rows: [],
		specimens: [],
		tab: 0,
		tabGeneral: 0,
		halfBro: 1,
		nephews: 1,
		childs: 1,
		chicks: [],
		cintillos: [],
		countChicks: 0,
		countCintillos: 0,
		countHalfTotal: 0,
		counterBrothers: 0,
		childsCountTotal: 0,
		countChilds: {},
		countHalf: {},
		countersGender: null,
		selectSpecimen: "madrilla",
		nephewsCounter: {},
		nephewsTotal: 0,
		childrens: 0,
		arraystatusspecimen: [
			{
				id: "madrilla",
				name: "Madrilla",
			},
			{
				id: "padrillo",
				name: "Padrillo",
			},
		],
	}),
	computed: {
		isValidCondition() {
			return (
				(this.rows && this.rows.length > 0) ||
				(this.specimen && this.specimen.pa_ids && this.specimen.pa_ids.length > 0) ||
				this.nephews
			)
		},
		fmtTitle() {
			let name = ""
			if (this.specimen.alias) {
				name = `${this.specimen.alias}(${this.specimen.plate})`
			} else {
				name = this.specimen.plate
			}
			return `Hermanos / ${name}`
		},

		padrilloSpecimen() {
			if (this.rows && this.rows.length > 0 && this.rows[0] && this.rows[0].padrillo) {
				return {
					plate: this.rows[0].padrillo.plate || "",
					alias: this.rows[0].padrillo.alias || "",
					id: this.rows[0].padrillo.id || "",
					thumb: this.rows[0].padrillo.thumb || "",
					image: this.rows[0].padrillo.image || "",
				}
			}
			return null
		},

		madrillaSpecimen() {
			if (this.rows && this.rows.length > 0 && this.rows[0] && this.rows[0].madrilla) {
				return {
					plate: this.rows[0].madrilla.plate || "",
					alias: this.rows[0].madrilla.alias || "",
					id: this.rows[0].madrilla.id || "",
					thumb: this.rows[0].madrilla.thumb || "",
					image: this.rows[0].madrilla.image || "",
				}
			}
			return null
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.$emit("closing")
			this.tab = 0
			this.tabGeneral = 0
		},
		async getRows() {
			this.isLoading = true
			this.isPreloading()
			await this.getCountersGenderBrothers()
			let data = await specimenService.getSiblings({ specimen_id: this.specimen.id })
			this.rows = [...data.data.specimens_chicks]
			// if (this.rows.length < 1) {
			// 	this.$emit("closing")
			// 	this.isPreloading(false)
			// 	return this.showToast("warning", "top-right", "Hermanos", "InfoIcon", "No se encontraron registros")
			// }
			this.specimens = data.data.specimens_chicks.filter((sibling) => sibling.specimen != null)


			this.tabGeneral = this.specimens.length > 0 ? 1 : this.countHalfTotal > 0 ? 2 : this.nephewsTotal > 0 ? 3 : this.childsCountTotal > 0 ? 4 : 0
			this.tab = this.showSiblings ? 4 : this.specimens.length > 0 ? 1 : 2
			this.chicks = data.data.specimens_chicks.filter((sibling) => sibling.specimen == null)
			this.countChicks = [...this.chicks].length
			let corrales = [null]
			// group cicks by corral
			this.chicks.forEach((chick) => {
				console.log("object", chick.chick)
				if (!corrales.includes(chick.chick.corral)) {
					corrales.push(chick.chick.corral)
				}
			})
			this.chicks = corrales.map((id) => {
				let chicks = []
				this.chicks.forEach((item) => {
					if (item.chick.corral == id) {
						chicks.push(item)
					}
				})
				return {
					name: id == null ? "SIN CORRAL" : id,
					chicks,
				}
			})

			this.cintillos = data.data.cintillos_pata
			this.countCintillos = data.data.cintillos_pata.reduce((acc, val) => acc + Number(val.bal), 0)
			// Limpiar grupos que no tengan chicks
			this.chicks = this.chicks.filter((group) => group.chicks.length != 0)
			this.isLoading = false
			this.isActive = true
			this.isPreloading(false)
		},
		rowToObject(row) {
			if (typeof row.chick == "object") {
				return {
					plate: row.chick.correlative,
					id: row.chick.id,
				}
			}
			return {
				plate: row.specimen.plate,
				alias: row.specimen.alias,
				id: row.specimen.id,
				thumb: row.specimen.thumb,
				image: row.specimen.image,
			}
		},
		async getCountersGenderBrothers() {
			const data = await specimenService.getCounterBrothersAndGender({ specimen_id: this.specimen.id })

			const sumObjectValues = (obj) => {
				return Object.values(obj).reduce((sum, value) => sum + value, 0)
			}
			this.countersGender = data.brothers
			this.countHalfTotal = data.half_brothers.chicks + data.half_brothers.specimens
			this.childsCountTotal = data.childs.chicks + data.childs.specimens
			this.countChilds = data.childs
			this.countHalf = data.half_brothers
			this.nephewsCounter = data.nephews
			this.nephewsTotal = data.nephews.chicks + data.nephews.specimens
			this.counterBrothers =
				sumObjectValues(data.brothers.chicks) +
				sumObjectValues(data.brothers.specimens) +
				sumObjectValues(data.brothers.patas)
		},
	},
	mounted() {
		// this.getCountersGenderBrothers();
	},
	watch: {
		async show(val) {
			if (val) {
				await this.getRows()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.container-chicks-corrales {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 1rem !important;
	background: #fafafa;
}

.chick-container {
	border-radius: 12px;
	padding: 0.2rem 0.5rem;
	margin-right: 1rem;
	font-weight: bolder;
	margin-bottom: 1rem;
}

.container-table-genders-children {
	width: 100%;

	@media screen and (min-width: 900px) {
		width: 80%;
		text-align: right;
	}

	@media screen and (min-width: 1400px) {
		width: 60%;
		text-align: right;
	}
}

.containers-genders {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	padding: 2rem;
	padding: 2rem;

	@media screen and (min-width: 1400px) {
		grid-template-columns: 1fr 1fr;
	}

	.container-gender-cards {
		border-radius: 12px;

		// overflow: hidden;
		h4 {
			border-top-right-radius: 12px;
			border-top-left-radius: 12px;
			background: #f5f5f5;
			font-weight: bold;
			// background: red;
			padding: 1rem 2rem;
			margin-bottom: 0;
			text-align: center;
			font-size: 20px;
		}

		// padding: 2rem 4rem;
		.specimens {
			border: 2px solid #f2f2f2;
			background: white;
			display: grid;
			grid-template-columns: 1fr;
			gap: 2rem;
			padding: 2rem;

			@media screen and (min-width: 700px) {
				grid-template-columns: 1fr 1fr;
			}

			@media screen and (min-width: 1000px) {
				grid-template-columns: 1fr 1fr 1fr;
			}

			@media screen and (min-width: 1400px) {
				grid-template-columns: 1fr 1fr;
			}
		}

		.found {
			border: 2px solid #f2f2f2;
			background: white;
		}
	}
}

.iconHalf {
	position: absolute;
	bottom: 1.5rem;
	right: 1.5rem;
}
</style>

<template>
	<b-modal
		ref="specimen-incidences-modal"
		:title="getTitle"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xsmd"
		hide-footer
	>
		<TableIncidence :info="info" />
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ViewImage from "@/components/commons/ViewImage.vue"
import TableIncidence from "@/views/amg/header-bookmarks/incidences/TableIncidence.vue"

export default {
	components: { SpecimenPlate, ViewImage, TableIncidence },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			incidences: [],
		}
	},
	async created() {},
	computed: {
		getTitle() {
			return `Incidencias del ejemplar ${this.info.alias ? this.info.alias : ""} ${this.info.alias ? "/" : ""} (${
				this.info.plate ? this.info.plate : ""
			})`
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
	},
	watch: {},
	async mounted() {
		this.toggleModal("specimen-incidences-modal")
	},
}
</script>

<style lang="scss" scoped></style>

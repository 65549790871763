<template>
	<div>
		<div class="card container-uls-encaste-corrales">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 p-2" role="tabslits">
				<b-nav-item
					v-for="item in arrayCounters"
					:key="item.id"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					@click="tabIndex = item.id - 1"
					:active="tabIndex == item.id - 1"
				>
					<span>{{ item.name }}</span>
					<b-badge pill variant="danger" class="ml-1">
						{{ item.count }}
					</b-badge>
				</b-nav-item>
			</ul>

			<div>
				<div class="mx-2 m-2 position-relative">
					<b-row>
						<b-col
							cols="12"
							md="12"
							lg="4"
							class="d-flex align-items-start justify-content-start justify-content-sm-start flex-column"
						>
							<b-form-group label="Seleccione categoria">
								<v-select
									class="mb-0 w-100"
									v-model="category_id"
									:options="[
										{ id: null, description: 'TODOS', geder: 'TODOS', evolution_order: 0 },
										...S_CATEGORIES_SPECIMENS,
									]"
									label="description"
									:reduce="(option) => option.id"
									:clearable="false"
									transition=""
									placeholder="Seleccione"
									@input="init"
								/>
							</b-form-group>

							<span class="text-muted mt-1">
								Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
							</span>
							<!-- <button @click="modalAddToSale.show = true" class="btn btn-primary">Agregar</button> -->
						</b-col>
						<b-col cols="12" md="12" lg="8" class="flex-nowrap">
							<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
								<div class="xt-search-input__paginate">
									<b-pagination
										v-model="current_page"
										:total-rows="total_data"
										:per-page="perpage"
										first-number
										last-number
										prev-class="prev-item"
										next-class="next-item"
									>
										<template #prev-text>
											<feather-icon icon="ChevronLeftIcon" size="18" />
										</template>
										<template #next-text>
											<feather-icon icon="ChevronRightIcon" size="18" />
										</template>
									</b-pagination>
								</div>
								<div class="xt-search-input__search">
									<div class="search-container-value">
										<b-form-input
											type="search"
											class="search-input"
											@keyup.enter="init"
											v-model="search"
											placeholder="Placa o alias"
											style="width: 200px"
										/>
										<div class="search-btn">
											<button @click="init" class="btn btn-primary w-100">Buscar</button>
										</div>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>

				<div class="table-responsive mb-0">
					<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '50vh' }">
						<b-thead>
							<b-tr class="">
								<b-th v-if="title.visible" v-for="(title, key) in headTable" :key="key">
									<div
										v-if="!['activation', 'specimen_plate', 'updated_at'].includes(title.key)"
										class="d-flex align-items-center justify-content-center"
									>
										<p class="mb-0 mr-1">{{ title.title }}</p>
									</div>
									<div
										@click="
											order == 'asc' ? (order = 'desc') : (order = 'asc');
											title.key == 'specimen_plate'
												? (orderBy = 'specimen_plate')
												: (orderBy = 'updated_at');
											init();
										"
										v-else
										class="d-flex align-items-center justify-content-center cursor-pointer"
									>
										<p class="mb-0 mr-1">{{ title.title }}</p>
										<FeatherIcon
											:icon="order == 'desc' ? 'ChevronDownIcon' : 'ChevronUpIcon'"
											size="18"
											class="text-primary cursor-pointer ml-1"
										/>
									</div>
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<b-tr
								v-if="specimens.length > 0"
								class="text-center"
								v-for="(item, index) in specimens"
								:key="index"
							>
								<!-- <b-td class="" v-if="tabIndex == 0">
									<p class="mb-0 font-weight-bold">{{ item.updated_by.user }}</p>
									<p class="mb-0 font-small-3">{{ item.updated_by.date | myGlobalDayShort }}</p>
								</b-td> -->

								<b-td>
									<SpecimenPlate
										:specimen="{
											id: item.specimen.id,
											plate: item.specimen.plate,
											alias: item.specimen.alias,
											thumb: item.specimen.thumb,
											image: item.specimen.image,
										}"
									/>
								</b-td>

								<b-td>
									{{ item.category_name }}
								</b-td>

								<b-td>
									{{ item.pounds_weight ? `${item.pounds_weight} (Lb)` : "Sin peso" }}
								</b-td>

								<b-td v-if="item.dob">
									{{ item.dob | myGlobalDayShort }}
								</b-td>
								<b-th v-else>---</b-th>

								<b-td class="">
									<p class="mb-0 font-weight-bold">{{ item.updated_by.user }}</p>
									<p class="mb-0 font-small-3">{{ item.updated_by.date | myGlobalDayShort }}</p>
								</b-td>

								<b-td>
									<ChangeSpecimenToPublic
										:key="index"
										@refresh="init"
										:specimen="{
											...item.specimen,
											is_public: item.is_public == 1 ? true : false,
											public_status_id: item.public_status_id,
										}"
										:tabIndex="tabIndex"
										hasChange
										hasChangeStatus
										:status_id="item.public_status_id"
									/>
								</b-td>
							</b-tr>

							<b-tr v-if="specimens.length == 0">
								<b-td colspan="7" class="text-center">
									<p class="mb-0 font-weight-bold">Sin specimens encontrados</p>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import publicWebService from "@/services/publicWeb.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import ChangeSpecimenToPublic from "./ChangeSpecimenToPublic.vue";
import { mapState, mapActions } from "vuex";

export default {
	name: "SpecimensToPublic",
	components: { SpecimenPlate, ChangeSpecimenToPublic },
	data() {
		return {
			headTable: [],
			specimens: [],
			start_page: 1,
			to_page: 1,
			total_data: 0,
			current_page: 1,
			perpage: 50,
			search: null,
			category_id: null,
			tabIndex: 0,
			arrayCounters: [],
			order: "asc",
			orderBy: "specimen_plate",
		};
	},
	async mounted() {
		// await this.countersTabs();
		await this.A_GET_CATEGORIES_SPECIMENS({
			specimen_id: null,
		});
		this.init();
	},
	computed: {
		...mapState("createEditSpecimen", ["S_CATEGORIES_SPECIMENS"]),
	},
	methods: {
		...mapActions("createEditSpecimen", ["A_GET_CATEGORIES_SPECIMENS"]),
		async init() {
			this.reloadHeadersTable();

			this.isPreloading();
			await this.countersTabs();

			const { data, from, last_page, to, total } = await publicWebService.getSpecimensToPublic({
				category_id: this.category_id,
				search: this.search,
				page: this.current_page,
				perpage: this.perpage,
				status_id: this.tabIndex == 0 ? 1 : 2,
				order: this.order,
				orderBy: this.orderBy,
			});

			this.specimens = data;
			this.start_page = from;
			this.to_page = to;
			this.total_data = total;

			this.isPreloading(false);
			console.log("data", data);
		},
		async countersTabs() {
			const { data } = await publicWebService.getStatusesSpecimenWeb({
				type: 1,
			});
			this.arrayCounters = data;
			// console.log("resp", data);
		},
		reloadHeadersTable() {
			this.headTable = [
				{ key: "specimen_plate", title: "Ejemplar", visible: true },
				{ key: "category_id", title: "Categoria", visible: true },
				{ key: "peso", title: "Peso", visible: true },
				{ key: "dob", title: "Nacimiento", visible: true },
				{ key: "updated_at", title: this.tabIndex == 0 ? "Activado por" : "Inactivado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			];
		},
	},
	watch: {
		async current_page() {
			await this.init();
		},
		async tabIndex() {
			await this.init();
		},
	},
};
</script>

<template>
	<b-sidebar
		id="add-awards"
		v-model="visibleChanges"
		footer-class="bg-transparent"
		bg-variant="white"
		shadow
		backdrop
		lazy
		header-class="py-1 pr-0  bg-transparent"
		@hidden="closeSidebar()"
		body-class="p-1"
		width="95%"
		title="Muertos"
	>
		<!-- BODY -->
		<template #default>
			<b-tabs lazy v-model="index">
				<b-tab title="Padrillos">
					<ParentsDead :category_id="1" />
				</b-tab>
				<b-tab title="Madrillas">
					<ParentsDead :category_id="2" />
				</b-tab>
			</b-tabs>
		</template>
	</b-sidebar>
</template>

<script>
import ParentsDead from "./ParentsDead.vue";
export default {
	name: "SidebarDeads",
	components: {
		ParentsDead,
	},
	props: {
		category_id: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			visibleChanges: false,
			index: this.category_id - 1,
		};
	},
	async mounted() {
		console.log('init controllerSideBarDeads');
		this.isPreloading();
		this.visibleChanges = true;
		this.isPreloading(false);
	},
	methods: {
		closeSidebar() {
			this.visibleChanges = false;
			this.$emit("hidden");
		},
	},
};
</script>

<style lang="scss"></style>

<template>
	<b-sidebar
		id="add-awards"
		v-model="visibleChanges"
		footer-class="bg-transparent"
		bg-variant="white"
		shadow
		backdrop
		lazy
		header-class="py-1 pr-0  bg-transparent"
		@hidden="closeSidebar('')"
		body-class="p-1"
		width="95%"
	>
		<!-- HEADER -->
		<template #header="{ hide }">
			<div class="w-100">
				<b-button-close class="text-dark float-right x-close-sidebar" @click="hide" />
				<b-row>
					<b-col cols="12" md="6"><h1>ACCIONES MASIVAS</h1></b-col>
				</b-row>
			</div>
		</template>

		<!-- BODY -->
		<template #default>
			<b-tabs pills lazy>
				<b-tab title="ESTADOS" active><MainStatus @hidden="closeSidebar" /></b-tab>
				<b-tab title="PESOS"><WeightsMassively @hidden="closeSidebar" /></b-tab>
				<b-tab title="IMAGENES"><PhotosMassively @hidden="closeSidebar" /></b-tab>
			</b-tabs>
			<custom-light-box
				:items="S_ARRAY_IMAGES"
				:index="S_INDEX_IMAGES"
				:effect="'fade'"
				@close="M_SET_INDEX_IMAGES(null)"
			/>
		</template>

		<!-- FOOTER -->
	</b-sidebar>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import MainStatus from "../specimens-changes-status-massively/MainStatus.vue"
import SelectSpecimens from "./SelectSpecimens.vue"
import PhotosMassively from "../specimens-photos/PhotosMassively.vue"
import WeightsMassively from "../specimens-weight/WeightsMassively.vue"
export default {
	components: {
		MainStatus,
		WeightsMassively,
		SelectSpecimens,
		PhotosMassively,
	},
	data() {
		return {
			visibleChanges: false,
		}
	},
	created() {},
	async mounted() {
		this.isPreloading()
		this.visibleChanges = true
		this.isPreloading(false)
	},
	computed: {
		...mapState("customLightBox", ["S_ARRAY_IMAGES", "S_INDEX_IMAGES"]),
	},
	methods: {
		...mapMutations("customLightBox", ["M_SET_INDEX_IMAGES"]),
		closeSidebar(status) {
			this.visibleChanges = false
			this.$emit("hidden", status)
		},
	},
}
</script>

<style lang="scss"></style>

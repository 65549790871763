var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"px-2",attrs:{"show":_vm.isLoading}},[_c('div',{staticClass:"container-tree"},[_vm._l((_vm.tabGeneral == 3 ? _vm.datasNephe : _vm.tabGeneral == 4 ? _vm.dataChildren : _vm.datas),function(chick,i){return [((!_vm.halfBro && !chick.specimens.length == 0) || _vm.halfBro && chick.chicks.length > 0)?_c('div',[(![3, 4].includes(_vm.tabGeneral))?_c('b-card',{staticStyle:{"width":"15rem"},attrs:{"footer-class":"d-block text-center h4"}},[(
						(_vm.selectSpecimen == 'madrilla' && !_vm.halfBro && !chick.specimens.length == 0) ||
						(_vm.selectSpecimen == 'madrilla' && _vm.halfBro && chick.chicks.length > 0)
					)?_c('SpecimenPlate',{attrs:{"specimen":{
						plate: chick.padrillo.plate,
						alias: chick.padrillo.alias,
						id: chick.padrillo.id,
						thumb: chick.padrillo.thumb,
						image: chick.padrillo.image,
					}}}):_vm._e(),(
						(_vm.selectSpecimen == 'padrillo' && !_vm.halfBro && !chick.specimens.length == 0) ||
						(_vm.selectSpecimen == 'padrillo' && _vm.halfBro && chick.chicks.length > 0)
					)?_c('SpecimenPlate',{attrs:{"specimen":{
						plate: chick.madrilla.plate,
						alias: chick.madrilla.alias,
						id: chick.madrilla.id,
						thumb: chick.madrilla.thumb,
						image: chick.madrilla.image,
					}}}):_vm._e()],1):_c('b-card',{staticStyle:{"width":"15rem"},attrs:{"footer-class":"d-block text-center h4"}},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('SpecimenPlate',{attrs:{"specimen":{
								plate: chick.padrillo.plate,
								alias: chick.padrillo.alias,
								id: chick.padrillo.id,
								thumb: chick.padrillo.thumb,
								image: chick.padrillo.image,
							}}})],1),_c('div',{staticClass:"px-4"},[_c('SpecimenPlate',{attrs:{"specimen":{
								plate: chick.madrilla.plate,
								alias: chick.madrilla.alias,
								id: chick.madrilla.id,
								thumb: chick.madrilla.thumb,
								image: chick.madrilla.image,
							}}})],1)])]),_c('div',{staticClass:"container-family"},[(!_vm.halfBro && !chick.specimens.length == 0)?_c('div',[_c('ContainerGenderCard',{attrs:{"title":"MACHOS","specimens":chick.specimens.filter(function (item) { return item && item.specimen.gender_id == 1; }),"padding":true}})],1):_vm._e(),(!_vm.halfBro && !chick.specimens.length == 0)?_c('div',[_c('ContainerGenderCard',{attrs:{"title":"HEMBRAS","specimens":chick.specimens.filter(function (item) { return item && item.specimen.gender_id == 2; }),"padding":true}})],1):_vm._e(),(_vm.halfBro && chick.chicks.length > 0)?_c('div',{staticClass:"container-pollos"},[_c('ContainerGenderCard',{attrs:{"title":"POLLOS","chicks":chick.chicks,"correlative":true}})],1):_vm._e()])],1):_vm._e()]})],2),(_vm.datas.length == 0 & !_vm.nephews)?_c('div',{staticClass:"text-texts"},[_vm._v("Sin medio hermano")]):_vm._e(),(_vm.datasNephe.length == 0 && _vm.nephews)?_c('div',{staticClass:"text-texts"},[_vm._v("Sin sobrinos")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
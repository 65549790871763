<template>
	<div>
		<div
			v-if="showPosturasByGenderChicks.length"
			style="height: calc(90vh - 200px); overflow-y: auto; padding: 10px"
		>
			<b-card
				body-class="p-0"
				style="padding: 10px"
				v-for="(item, index) in showPosturasByGenderChicks"
				:key="`madrilla-${index}`"
			>
				<b-table-simple responsive>
					<b-thead>
						<b-tr class="text-center">
							<b-th>Postura</b-th>
							<b-th>{{ partner }}</b-th>
							<b-th>Cintillo</b-th>
							<b-th>Cantidad</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center">
							<b-td>
								<p class="mb-0">{{ item.p_code }}</p>
							</b-td>
							<b-td>
								<SpecimenPlate v-if="item.padrillo" :specimen="item.padrillo" />
								<SpecimenPlate v-if="item.madrilla" :specimen="item.madrilla" />
							</b-td>
							<b-td>
								<div class="d-flex align-items-center justify-content-center pl-50">
									<div class="d-flex flex-nowrap">
										<CombChickenlegsCintillos
											v-if="item.cintillos"
											:postura="{
												chickenleg_wich_leg: item.cintillos.wich_leg,
												chickenleg_qty_cintillos: item.cintillos.qty_cintillos,
												cintillo_color: item.cintillos.hex_1,
												cintillo_description: item.cintillos.description_1,
												second_color_color: item.cintillos.hex_2,
												second_color_description: item.cintillos.description_2,
												total_minus_infertile_eggs: 1,
											}"
											:chickenleg_wich_leg="1"
											is_in_posturas
										/>
										<CombChickenlegsCintillos
											v-if="item.cintillos"
											:postura="{
												chickenleg_wich_leg: item.cintillos.wich_leg,
												chickenleg_qty_cintillos: item.cintillos.qty_cintillos,
												cintillo_color: item.cintillos.hex_1,
												cintillo_description: item.cintillos.description_1,
												second_color_color: item.cintillos.hex_2,
												second_color_description: item.cintillos.description_2,
												total_minus_infertile_eggs: 1,
											}"
											is_in_posturas
											:chickenleg_wich_leg="2"
										/>
									</div>
								</div>
							</b-td>

							<template v-if="is_cintillo_pata">
								<b-td>
									<div class="mr-1 font-weight-bolder">
										{{ item.eggs }}
									</div>
								</b-td>
							</template>

							<template v-else>
								<td v-if="gender == 'todos'">
									<div class="mr-1 font-weight-bolder">
										<span v-if="item.eggs > 0" style="margin-right: 10px">
											{{ item.eggs }}
										</span>
										<b-button
											v-if="item.chicks"
											variant="outline-primary"
											class="btn-sm"
											@click="openSons(item)"
										>
											{{ item.chicks.length }}
										</b-button>
									</div>
								</td>

								<td v-else-if="item.chicks">
									<b-button variant="outline-primary" class="btn-sm" @click="openSons(item)">
										{{ item.chicks.length }}
									</b-button>
								</td>
							</template>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<!-- <template v-else-if="item.eggs < 0">
					<h4 class="ml-1 text-uppercase text-warning">Sin hijos</h4>
				</template> -->
			</b-card>
		</div>
		<div v-else>
			<h4 class="my-2 text-uppercase text-dark text-center">Sin hijos</h4>
		</div>

		<b-modal size="md" :title="`${controllerSons.title}`" hide-footer v-model="controllerSons.show">
			<h6 class="ml-1 text-uppercase text-primary">Cintillos en la ala</h6>
			<b-row class="mt-1">
				<b-col
					cols="12"
					md="4"
					class="mb-1"
					v-for="(chick, index) in controllerSons.chicks"
					:key="`${index}-chick`"Con Placa:
				>
					<li class="d-flex align-items-center ml-1">
						<FeatherIcon size="12" icon="ChevronsRightIcon" style="margin-right: 5px" />
						<div class="c-correlative">{{ chick.correlative }}</div>
						<div class="c-plate" v-if="chick.assigned_plate">
							{{ chick.assigned_plate }}
						</div>
					</li>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";
import CombChickenlegsCintillos from "@/views/amg/encaste/lotes/components/CombChickenlegsCintillos.vue";
import SlidersImageVideoVue from "@/views/amg/header-bookmarks/SlidersImageVideo.vue";

export default {
	name: "SonsByIdSpecimenItem",
	components: {
		SlidersImageVideoVue,
		CombChickenlegsCintillos,
		SpecimenPlate,
	},
	props: {
		is_cintillo_pata: {
			type: Boolean,
			default: false,
		},
		gender: {
			type: [Number, String],
			// required: true,
		},
		category: {
			type: Number,
			// required: true,
		},
		posturasWithChicks: {
			type: Array,
			required: true,
		},
	},
	created() {
		this.make();
	},
	computed: {
		partner() {
			return this.category == 1 ? "MADRILLA" : "PADRILLO";
		},
		showPosturasByGenderChicks() {
			if (this.gender == "todos") {
				return this.posturasByGenderChicks.filter((p) => p.eggs > 0 || p.chicks_all > 0);
			}
			if (!this.is_cintillo_pata) {
				return this.posturasByGenderChicks.filter((p) => {
					if (p.chicks && p.chicks.length) {
						return p;
					}
				});
			}
			return this.posturasByGenderChicks.filter((p) =>
				this.is_cintillo_pata ? p.eggs > 0 : p.eggs > 0 || p.chicks_all > 0
			);
		},
	},
	data() {
		return {
			controllerSons: {
				title: "",
				chicks: [],
				cintillos: [],
				show: false,
			},
			posturasByGenderChicksClone: [],
			posturasByGenderChicks: [],
			clickImage: {
				url: null,
				type: "image",
				text: null,
				text_url: null,
				open: false,
			},
		};
	},
	methods: {
		make() {
			this.posturasByGenderChicksClone = _.cloneDeep(this.posturasWithChicks);
			this.posturasByGenderChicks = this.posturasByGenderChicksClone.filter((p) => {
				if (p.chicks && p.chicks.length) {
					p.chicks = p.chicks.filter((c) => {
						if (this.gender == "todos") return c;
						if (c.gender == this.gender) return c;
					});
				}
				return p;
			});
		},
		clickImageSpecimen(item) {
			this.clickImage.open = true;
			this.clickImage.text_url = `detalle/${item.id}`;
			this.clickImage.type = "image";
			this.clickImage.text = `${item.plate} ${item.alias ? "-" : ""} ${item.alias ? item.alias : ""}`;
			this.clickImage.url = item.image;
		},
		refresh() {
			this.clickImage.open = false;
			this.clickImage.text_url = null;
			this.clickImage.type = null;
			this.clickImage.text = item.plate;
			this.clickImage.url = null;
			this.clickImage.idSpecimen = null;
		},
		linkSpecimen(id) {
			window.open(`/ejemplares/detalle/${id}`, "_blank");
		},
		openSons(item) {
			const { p_code, chicks, cintillos } = item;
			const parent = item.padrillo ? item.padrillo : item.madrilla;
			// const parent_name = item.padrillo ? "Padrillo:" : "Madrilla: ";

			this.controllerSons.title = `${p_code} - ${parent.plate.toString()} ${
				parent.alias ? "/ " + parent.alias : ""
			}`;

			this.controllerSons.chicks = chicks;
			this.controllerSons.show = true;
		},
	},
	watch: {
		async year() {
			await this.init();
		},
		posturasWithChicks(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.posturasByGenderChicks = [];
				this.make();
			}
		},
	},
};
</script>

<style lang="scss">
.plate {
	margin-top: 0.5rem;
	background: rgba(0, 0, 0, 0.7);
	color: white;
	font-weight: bold;
	padding: 0.08rem 0.5rem !important;
	border-radius: 6px;
	width: auto;
	font-size: 12px;

	@media screen and (min-width: 700px) {
		font-size: 14px;
		padding: 0.1rem 0.8rem !important;
		border-radius: 8px;
	}
}

.code {
	font-size: 10px;

	@media screen and (min-width: 700px) {
		font-size: 14px;
	}
}

.c-correlative {
	background: #7367f0;
	color: #ffff;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	padding: 5px 10px;
}

.c-plate {
	background: #252528;
	color: #ffff;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 5px 10px;
}
</style>

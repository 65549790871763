<template>
	<div>
		<div class="card mt-2 mb-2 cross-center gap-x-1 flex-row p-2" v-if="[1, 2].includes(categoryId)">
			<div class="d-flex align-items-center justify-content-start">
				<span class="text-primary mb-0 mr-1">AÑO</span>
				<v-select
					v-model="season"
					:options="seasons"
					transition=""
					:clearable="false"
					:reduce="(option) => option.id"
					:style="{ maxWidth: '11ch', minWidth: '11ch' }"
					@input="setYearMain($event)"
					label="season"
				/>
			</div>
		</div>
		<div class="card align-item-center p-2 flex-lg-row justify-content-md-between">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" role="tabslits">
				<b-nav-item
					v-for="(tab, index) in tabArray"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:key="`${index}-index`"
					:active="tabIndex == index"
					@click="tabIndex = index"
				>
					<span>{{ tab.name }}</span>
					<b-badge pill variant="danger" class="ml-1">
						{{ tab.counter }}
					</b-badge>
				</b-nav-item>
			</ul>
		</div>

		<b-tabs
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center"
			v-model="tabIndex"
			lazy
			v-if="tabArray.length > 0"
		>
			<b-tab v-for="(tab, index) in tabArray" :key="`posturas-${index}`">
				<PosturasListByStatus
					v-if="ready && $store.state.utilities.seasons.length > 0"
					:statusFromModal="tab.status"
					isOnlySpecimen
					:specimen-id="specimenId"
					:category-id="categoryId"
				/>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import PosturasListByStatus from "./PosturasListByStatus.vue"
import posturasService from "@/services/posturas.service"
import { mapMutations, mapState } from "vuex"

export default {
	name: "ViewPostureStatusBySpecimenId",
	props: {
		isOnlySpecimen: [Boolean],
		specimenId: [Number, String],
		categoryId: [Number, String],
	},
	components: {
		PosturasListByStatus,
	},
	computed: {
		...mapState("encastePosturas", ["start_date", "end_date"]),
	},
	data() {
		return {
			tabProcessingActive: 0,
			tabArray: [
				{ id: 1, name: "PENDIENTES", status: 1, counter: 0 },
				{ id: 2, name: "ACTIVAS", status: 2, counter: 0 },
				{ id: 3, name: "POR FINALIZAR", status: 3, counter: 0 },
				{ id: 4, name: "FINALIZADAS", status: 4, counter: 0 },
				{ id: 5, name: "ANULADAS", status: 5, counter: 0 },
			],
			tabIndex: 1,
			year_id: String(new Date().getFullYear()),
			ready: false,
			season: null,
		}
	},
	async mounted() {
		this.season = this.seasons[0].id
		this.setYearMain(this.season)
		this.initCounters()
	},
	methods: {
		...mapMutations("encastePosturas", ["SET_SEASON_MAIN"]),
		async initCounters() {
			// Cambiar de tab segun el anio, 2022(activas), menores a 2022(finalizadas)
			if (parseInt(this.year) == new Date().getFullYear()) {
				this.tabIndex = 1
			} else if (parseInt(this.year) < new Date().getFullYear()) {
				this.tabIndex = 3
			}
			this.tabArray = await posturasService.getCountersPosturas({
				start_date: this.start_date,
				end_date: this.end_date,
				specimen_id: this.specimenId,
			})
			this.ready = true
		},
		setYearMain(tr) {
			const { start, end } = this.seasons.find((season) => season.id == tr)
			this.SET_SEASON_MAIN({ start_date: start, end_date: end })
		},
	},
	watch: {
		async season(newValue, oldValue) {
			if (oldValue != newValue && oldValue != null) {
				// console.log('gh', oldValue, newValue, this.year);
				await this.initCounters({ year: this.year })
				this.$root.$emit("reloader-posturas-list")
			}
		},
	},
}
</script>

<style scoped lang="scss">
.x-tab-width {
	width: 100%;
	@media screen and (min-width: 600px) {
		width: auto;
	}
}
</style>

<template>
	<b-overlay :show="isLoading">
		<b-row class="gap-y-1">
			<b-col cols="12" md="6">
				<h2 class="text-secondary font-weight-bolder">POR ESTADO</h2>
			</b-col>
			<b-col cols="12" md="6" class="d-flex justify-content-end align-items-center gap-x-2"></b-col>
		</b-row>
		<hr />
		<VueApexCharts type="pie" height="300" :options="chartOpts" :series="chartData" />
	</b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
	components: { VueApexCharts },
	props: { year: Object, info: Array, count: Number },
	data: () => ({
		isLoading: true,
		chartData: [44, 55, 13, 43, 22],
		chartOpts: {
			labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
			colors: [
				"#001219",
				"#005F73",
				"#0A9396",
				"#94D2BD",
				"#E9D8A6",
				"#E9D8A6",
				"#CA6702",
				"#BB3E03",
				"#AE2012",
				"#9B2226",
			],
		},
	}),
	methods: {
		async getData() {
			if (this.info) {
				this.isLoading = false
			}
			this.chartData = this.info.map((d) => d.counter)
			this.chartOpts = {
				labels: this.info.map((d) => d.status_name),
				colors: [
					"#001219",
					"#005F73",
					"#0A9396",
					"#94D2BD",
					"#E9D8A6",
					"#E9D8A6",
					"#CA6702",
					"#BB3E03",
					"#AE2012",
					"#9B2226",
				],
			}
		},
	},
	watch: {
		info() {
			this.getData()
		},
		count() {
			this.isLoading = true
		},
	},
}
</script>

<template>
  <b-overlay class="text-center" :show="showOverlay" rounded="sm">
    <b-avatar
      ref="previewEl"
      icon="camera-fill"
      button
      :src="specimen.new_photo"
      @click="$refs.fileInput.click()"
      square
      :size="heightImage"
      class="cursor-pointer"
      style="font-size: 6rem"
    />
    <b-row>
      <b-col cols="6">
        <transition name="fade" mode="out-in">
          <span
            v-if="specimen.success_save"
            class="font-weight-bolder text-primary font-small-2"
            >Imagen Guardada!!!</span
          >
        </transition>
      </b-col>
      <b-col cols="6"
        ><div class="d-flex justify-content-end">
          <span>
            <feather-icon
              size="22"
              icon="XOctagonIcon"
              class="cursor-pointer text-danger mr-25"
              @click="deletePhoto()"
            />
            <feather-icon
              size="22"
              icon="UploadIcon"
              class="cursor-pointer text-primary"
              @click="$refs.fileInput.click()"
            />
          </span></div
      ></b-col>
    </b-row>

    <input
      ref="fileInput"
      type="file"
      accept=".jpeg,.jpg,.png,.heif,.heic"
      @input="pickFile"
      class="d-none"
    />

    <template #overlay>
      <div class="text-center text-primary">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
        <p class="font-weight-bolder mt-1">Guardando Imagen...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import heic2any from "heic2any";
import { mapActions } from "vuex";
export default {
  name: "SpecimenPhoto",
  props: {
    specimen: {
      type: Object,
      default: () => ({}),
    },
    heightImage: {
      type: String,
      default: "30rem",
    },
    savePhoto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOverlay: false,
      showSuccesText: false,
    };
  },
  methods: {
    ...mapActions("photosSpecimen", ["A_SAVE_PHOTO", "A_DELETE_PHOTO"]),
    async pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      const typeOfFile =
        file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1];
      if (typeOfFile === "heic" || typeOfFile === "heif") {
        this.showOverlay = true;
        this.showToast(
          "warning",
          "top-right",
          "Cargando imagen, espere un momento",
          "CheckIcon",
          "El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
        );
        await heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then(
          async (newImage) => {
            const result = await this.getHeic2any(newImage);
            this.specimen.file = result.newFile;
            this.specimen.new_photo = result.url;
          }
        );
      } else {
        const result = await this.getBase64(file);
        this.specimen.file = result.newFile;
      }
      if (this.savePhoto) {
        this.uploadPhoto();
      }
    },
    async getHeic2any(newImage) {
      return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(newImage);
        let newFile = new File([newImage], "heic" + ".jpg", {
          type: "image/jpeg",
          lastModified: new Date().getTime(),
        });
        resolve({ newFile, url });
        reject(error);
      });
    },
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        if (file && file[0]) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.specimen.new_photo = e.target.result;
          };
          reader.readAsDataURL(file[0]);
          const newFile = file[0];
          resolve({ newFile });
          reject(error);
        }
      });
    },
    async uploadPhoto() {
      this.showOverlay = true;
      try {
        const response = await this.A_SAVE_PHOTO(this.specimen);
        this.specimen.new_photo_id = response[0].photo_id;
        this.specimen.success_save = true;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.showOverlay = false;
      }
    },

    async deletePhoto() {
      const confirm = await this.showConfirmSwal({
        text: "Eliminaras esta imagen",
      });
      if (confirm.value) {
        this.specimen.new_photo = null;
        this.specimen.file = null;
        this.specimen.success_save = false;
        this.A_DELETE_PHOTO(this.specimen);
      }
    },
  },
};
</script>

<style>
</style>
<template>
	<b-modal centered hide-footer size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple class="text-center" sticky-header="60vh">
				<b-thead>
					<b-tr>
						<b-th>Rival</b-th>
						<b-th>Fecha</b-th>
						<b-th>Calificacion</b-th>
						<b-th>Cualidades</b-th>
						<b-th>Video</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr v-for="(row, idx) in rows" :key="idx">
						<b-td>
							<template v-if="row.rival">
								<SpecimenPlate
									:specimen="getOpponent(row)"
									v-if="getOpponent(row).type == 'specimen'"
								/>
								<b-badge variant="primary" v-else>
									{{ row.rival.correlative || row.rival.name }}
								</b-badge>
							</template>
							<p v-else class="mb-0">Sin rival</p>
						</b-td>
						<b-td>
							{{ row.date | myGlobalDayShort }}
						</b-td>
						<b-td>
							<span class="text-primary" v-if="row.score_average">{{ row.score_average }}</span>
							<span v-else>---</span>
						</b-td>
						<b-td>
							<div
								class="d-flex flex-column align-items-center gap-y-1"
								v-if="row.qualities && row.qualities.length > 0"
							>
								<b-badge variant="primary" v-for="quality in row.qualities" :key="quality.id">
									{{ quality.name }}: {{ quality.count }}
								</b-badge>
							</div>
							<span v-else>---</span>
						</b-td>
						<b-td>
							<div v-if="row.video && row.video.status == 2">
								<span @click="showVideo(row)" style="cursor: pointer">
									<b-avatar
										:src="row.video.thumb"
										rounded
										size="80"
										icon="film"
										:badge="row.video.duration"
										badge-variant="danger"
									/>
								</span>
							</div>
							<span v-else>---</span>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-td colspan="5">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import { mapActions } from "vuex"
import specimenService from "@/services/specimens.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [],
	}),
	methods: {
		...mapActions("header_book", ["toggleModal", "setIndexImage", "setImages"]),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await specimenService.getTopesList({ specimen_id: this.info.id })
			this.rows = data
			this.isLoading = false
		},
		showVideo(row) {
			const opponent = this.getOpponent(row)
			const local = !!this.info.alias ? `${this.info.alias} (${this.info.plate})` : `${this.info.plate}`
			this.setImages([
				{
					...row.video,
					type: "video",
					url: row.video.url,
					text: `${local} VS ${opponent ? (opponent.alias || opponent.plate || opponent.cintillo || opponent.name) : 'Sin rival'}`,
					specimen: { id: this.info.id },
				},
			])
			this.setIndexImage(0)
			this.toggleModal()
		},
		getOpponent(row) {
			const local = row.local

			// la consulta puede traer al local como un especimen diferente al que hizo la busqueda
			// porque el id de la consulta seria el oponente, como en la grilla de videos
			if (local.id != this.info.id) {
				return local
			}
			return row.rival
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.title = `Lista de topes / ${this.info.alias || this.info.plate}`
				this.isActive = val
				this.getRows()
			}
		},
	},
}
</script>

<template>
	<div class="container-scroll">
		<div class="container-select-date">
			<p>Año:</p>
			<v-select
				:options="options"
				v-model="date"
				transition=""
				:clearable="false"
				:reduce="(option) => option.year"
				:style="{ maxWidth: '11ch', minWidth: '11ch' }"
			/>
		</div>
		<div class="container-postura header-container-postura">
			<p class="text-center title-table">Codigo</p>
			<p class="text-center title-table">Padrillo</p>
			<p class="text-center title-table">Madrilla</p>
			<div class="eggs-numbers">
				<p class="text-center title-table">Huevos en lote</p>
				<p class="text-center title-table">Infertiles</p>
				<p class="text-center title-table">Quemados</p>
				<p class="text-center title-table">Nacidos</p>
				<p class="text-center title-table">No nacidos</p>
				<p class="text-center title-table">Muertos</p>
			</div>

			<p class="text-center title-table">Estado</p>
			<p class="text-center title-table">Fecha</p>
			<p class="text-center title-table">Acciones</p>
		</div>
		<PosturasByIdSpecimenItem v-for="(item, key) in data" :key="key" :item="item" />
		<div class="not-sons" v-if="data.length == 0">
			<p>Sin datos</p>
		</div>
	</div>
</template>

<script>
import specimenService from "@/services/specimens.service";
import PosturasByIdSpecimenItem from "./PosturasByIdSpecimenItem";
import { genYears } from "@/helpers/functions";

export default {
	name: "PosturasByIdSpecimen",
	components: {
		PosturasByIdSpecimenItem,
	},
	props: {
		modalTotalPosturas: {
			type: [Object, Array],
			default: () => [],
		},
		year: {
			type: [String, Number],
			defaul: null,
		},
	},
	data: () => ({
		data: [],
		options: [],
		date: null,
	}),
	async created() {
		this.options = genYears();
		this.date = this.year;
		await this.init();
	},
	methods: {
		async init() {
			this.data = await specimenService.getPosturasByIdSpecimen({
				dateIn: this.date,
				idSpecimenIn: this.modalTotalPosturas.idSpecimen,
			});
		},
	},
	watch: {
		async date(newVal, oldVal) {
			await this.init();
		},
	},
};
</script>

<style lang="scss">
.container-scroll {
	overflow-y: hidden;
	overflow-x: scroll;
	padding-bottom: 2rem;
}

.container-scroll::-webkit-scrollbar {
	width: 3px !important;
	background: #d5d1fb !important;
	height: 4px !important;
}

.container-scroll::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	height: 4px !important;
	border-radius: 2px !important;
}
.header-container-postura {
	border-bottom: 1px solid #eff3f5;
	padding-bottom: 0.5rem;
}
.item-specimen-posturas {
	// width: 100px;
	text-align: center;
	position: relative;
	.image-specimen {
		width: 100%;
		height: 130px;
		// padding: .5rem;
		// width: 70px;
		// height: 70px;
		object-fit: cover;
		border-radius: 8px;
	}
	.skeletor {
		border-radius: 8px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// padding: .5rem;
		background-color: rgba(30, 30, 30, 0.5);
	}
	.name {
		// margin-top: 10px;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 100;
		p {
			font-weight: bold;
			margin: 0 3px;
			color: #fff;
		}
		.alias {
			margin-top: 0.2rem;
			cursor: pointer;
			background: #fff;
			color: #000;
			padding: 0.1rem 0.5rem;
			border-radius: 8px;
		}
	}
}
.container-postura {
	display: grid;
	grid-template-columns: 1.2fr 1fr 1fr 6fr 1.5fr 1fr 1fr;
	margin-bottom: 1rem;
	gap: 1rem;
	min-width: 1400px;
	// overflow: scroll;
	&:last-child {
		margin-bottom: 0;
	}
	.code {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}
	.numbers {
		// flex: 1;
		// background: red;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		align-items: center;
		p {
			text-align: center;
			margin-bottom: 0;
			font-size: 18px;
		}
	}
	.status {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-bottom: 0;
	}
	.eggs-numbers {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}
	.icon-plusv2 {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: #7367f0;
	}
}

.title-table {
	font-weight: bold;
	margin-bottom: 0;
}
.items-container {
	margin-bottom: 1rem !important;
}
//content-postura-lote
.container-select-date {
	margin: 1rem 0;
	display: flex;
	align-items: center;
	margin-left: 2rem;
	@media screen and (min-width: 900px) {
		margin-left: 4rem;
	}
	p {
		margin-right: 1rem;
		margin-bottom: 0;
		font-weight: bold;
	}
	input {
		width: 100px;
	}
}
.not-sons {
	margin: 3rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	// background: red;
	p {
		font-size: 20px;
		margin-bottom: 0;
		font-weight: bold;
	}
}
</style>

<template>
	<b-modal
		ref="register-incidence-modal"
		:title="getTitle"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		:hide-header-close="uploadingVideo"
		size="md"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div>
			<ValidationObserver ref="form">
				<b-col>
					<b-row>
						<ValidationProvider v-if="!isDetail" class="w-100" rules="required" v-slot="{ errors }">
							<b-form-group id="specimen" label-for="specimen" class="w-100" label="Buscar ejemplar">
								<b-input-group class="w-100">
									<v-select
										v-model="specimen"
										:options="specimens"
										:clearable="false"
										label="name"
										:reduce="(option) => option"
										transition=""
										placeholder="Buscar por placa o alias"
										@search="(search) => searchSpecimens(search)"
										class="w-100 select-incidence"
										:class="{ 'border-danger rounded': !!errors[0] }"
										:disabled="uploadingVideo"
									>
										<template slot="no-options">Sin ejemplares encontradas</template>

										<template slot="option" slot-scope="option">
											<div class="d-center">
												{{ option.plate }} {{ option.alias ? `(${option.alias})` : "" }}
											</div>
										</template>
										<template slot="selected-option" slot-scope="option">
											<div class="selected d-center">
												{{ option.plate }} {{ option.alias ? `(${option.alias})` : "" }}
											</div>
										</template>
									</v-select>
								</b-input-group>
							</b-form-group>
							<!-- <small class="text-input-error" v-if="errors[0]">{{ errors[0] }}</small> -->
						</ValidationProvider>
						<div v-else class="pb-1">
							<SpecimenPlate :specimen="info.specimen" />
						</div>
					</b-row>

					<b-row class="">
						<ValidationProvider class="w-100" rules="required" v-slot="{ errors }">
							<b-form-group id="description" label="Descripción" label-for="observation" class="w-full">
								<b-form-textarea
									v-if="!isDetail"
									id="description"
									rows="3"
									v-model="description"
									style="resize: none; text-transform: uppercase"
									:disabled="uploadingVideo"
									:class="{ 'border-danger rounded': !!errors[0] }"
								/>
								<b-form-textarea
									v-else
									id="description"
									rows="3"
									v-model="info.description"
									style="resize: none; text-transform: uppercase"
									:disabled="true"
									:class="{ 'border-danger rounded': !!errors[0] }"
								/>
							</b-form-group>
						</ValidationProvider>
					</b-row>

					<b-row>
						<b-col md="12" lg="6" class="pl-0">
							<b-form-group id="evidence" label="Foto" label-for="evidence" class="w-full">
								<div
									v-if="!isDetail"
									class="container-imagev2"
									:class="{ 'add-opacity': uploadingVideo }"
								>
									<div class="skeletor-image-content">
										<img class="image" v-if="dataFile" :src="dataFile.base" alt="" />
										<div
											class="d-flex h-100 flex-column align-items-center justify-content-center"
											v-else
										>
											<b-button
												@click="fileChange"
												class="font-weight-bolder btn-sm px-1"
												variant="primary"
												:disabled="uploadingVideo"
											>
												<feather-icon class="" icon="UploadIcon" size="15" />
											</b-button>
											<span class="text-primary pt-25" style="font-size: 12px; font-weight: bold">
												Cargar Foto
											</span>
										</div>
										<input
											ref="fileInput"
											type="file"
											@input="fileChange"
											class="d-none"
											accept=".jpeg,.jpg,.png,.heif,.heic"
										/>
										<b-button
											v-if="dataFile != null && !uploadingVideo"
											variant="danger"
											@click="dataFile = null"
											class="btn-trash btn-sm p-25"
											style="width: 25px"
										>
											<feather-icon class="" icon="Trash2Icon" />
										</b-button>
									</div>
								</div>
								<div v-else>
									<div
										v-if="info.evidence_image.url != null"
										style="
											height: 150px !important;
											width: 100% !important;
											border-radius: 3%;
											box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
										"
									>
										<ViewImage
											:isDetail="true"
											:specimen="{ url: info.evidence_image.url, id: info.id }"
										/>
									</div>
									<div
										v-else
										class="d-flex w-100 align-items-center justify-content-center container-imagev2"
									>
										<span>Sin evidencia</span>
									</div>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="12" lg="6" class="pr-0">
							<b-form-group id="evidence" label="Video" label-for="evidence" class="w-full">
								<div class="grid-video">
									<div v-if="!uploadVideo.uid" class="container-video" style="height: 100%">
										<img
											class="image-mov"
											v-if="
												file &&
												['mp4', 'avi', 'mkv', 'flv', 'mov', 'MOV'].includes(file.extension)
											"
											src="https://cliply.co/wp-content/uploads/2019/07/371907120_YOUTUBE_ICON_TRANSPARENT_400.gif"
											alt=""
										/>
										<video
											class=""
											v-else
											:src="fileVideo ? fileVideo.base : null"
											autoplay
											muted
										></video>
										<div class="skeletor-video-content" :class="{ 'add-opacity': uploadingVideo }">
											<div
												class="d-flex flex-column align-items-center justify-content-center h-100"
												v-if="fileVideo.name"
											>
												<video
													v-if="
														fileVideo &&
														['mp4', 'avi', 'mkv', 'flv', 'mov', 'MOV'].includes(
															fileVideo.extension
														)
													"
													:src="videoURL"
													controls
													style="width: 100%; height: 100%"
													:disabled="uploadingVideo"
												></video>
											</div>
											<div
												class="d-flex h-100 flex-column align-items-center justify-content-center"
												v-else
											>
												<b-button
													v-if="!isDetail"
													@click="fileChangeVideo"
													class="font-weight-bolder btn-sm px-1"
													variant="primary"
												>
													<feather-icon class="" icon="UploadIcon" size="15" />
												</b-button>
												<span
													class="text-primary pt-25"
													style="font-size: 12px; font-weight: bold"
												>
													{{ !isDetail ? "Cargar Video" : "Sin video." }}
												</span>
											</div>
											<b-button
												v-if="fileVideo.name && !uploadingVideo"
												@click="resetVideo()"
												class="font-weight-bolder btn-trash btn-sm px-25 py-25"
												variant="danger"
											>
												<feather-icon icon="Trash2Icon"></feather-icon>
											</b-button>
										</div>
										<input
											ref="fileInputVideo"
											type="file"
											@input="fileChangeVideo"
											class="d-none"
											accept=".mp4,.avi,.mkv,.flv,.mov,.MOV"
										/>
									</div>
									<div v-else class="w-100 content-video" style="position: relative; height: 100%">
										<VideoIncidence :incidence="info" />
									</div>
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<b-row v-if="videoCounterUpload > 0" class="mt-1">
						<b-col>
							<p class="mb-0">
								Cargando: {{ ((videoCounterUploadCheck / videoCounterUpload) * 100).toFixed(2) }}%
							</p>
							<div class="barra">
								<div
									class="back"
									:style="{ width: `${(videoCounterUploadCheck / videoCounterUpload) * 100}%` }"
								></div>
							</div>
						</b-col>
					</b-row>
				</b-col>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<b-button
				:disabled="uploadingVideo"
				variant="secondary"
				:variant="'danger'"
				@click="
					$emit('closing')
					$emit('refresh-viewed')
				"
			>
				{{ !isDetail ? "Cancelar" : "Cerrar" }}
			</b-button>
			<b-button v-if="!isDetail && !uploadingVideo" variant="primary" @click="save">Guardar</b-button>
			<b-button v-if="!isDetail && uploadingVideo" variant="primary" disabled>
				<b-spinner small />
				Cargando...
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import IncidencesService from "@/services/incidences.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import { mapState, mapMutations } from "vuex"
import ViewImage from "@/components/commons/ViewImage.vue"
import * as icons from "vue-feather-icons"
import VideoIncidence from "@/views/amg/header-bookmarks/incidences/VideoIncidence.vue"
import { ValidationProvider } from "vee-validate"

export default {
	components: { SpecimenPlate, ViewImage, VideoIncidence },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			specimen: null,
			specimens: [],
			description: null,
			dataFile: null,
			fileURL: null,
			videoURL: null,
			uploadVideo: {
				uid: null,
			},
			fileVideo: {
				name: null,
				data: null,
				type: null,
				base: null,
				progress: 0,
				uploadFinished: false,
				extension: null,
			},
			uploadingVideo: false,
			videoCounterUploadCheck: 0,
			videoCounterUpload: 0,
		}
	},
	async created() {
		if (this.info && this.info.id && this.isAdmin) {
			this.uploadVideo.uid = this.info.evidence_video.url
		}
	},
	computed: {
		...mapState("video", ["file"]),
		getTitle() {
			if (this.info && this.info.id) {
				return `Detalle de incidencia | ${this.info.specimen.alias ?? this.info.specimen.plate}`
			}
			return `Registrar nueva incidencia`
		},
		isDetail() {
			return this.info && this.info.id
		},
	},
	methods: {
		...mapMutations("video", ["M_DATA_FILE"]),
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
			this.$emit("refresh-viewed")
		},

		resetVideo() {
			this.fileVideo = {
				name: null,
				data: null,
				type: null,
				base: null,
				progress: 0,
				uploadFinished: false,
				extension: null,
			}
		},

		fileChangeVideo() {
			if (this.uploadingVideo) return
			this.$refs.fileInputVideo.click()
			let input = this.$refs.fileInputVideo
			let file = input.files
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			this.setVideo(file, "video")
		},
		setVideo(file, type) {
			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			if (file && file[0]) {
				this.fileVideo = {
					name: file[0].name,
					data: file[0],
					type,
					base: null,
					progress: 0,
					uploadFinished: false,
					extension: typeOfFile,
				}
				if (this.fileVideo && this.fileVideo.data) {
					this.videoURL = URL.createObjectURL(this.fileVideo.data)
				}
			}
		},
		fileChange() {
			if (this.uploadingVideo) return
			if (!this.loading) {
				this.loading = true
				this.$refs.fileInput.click()
				let input = this.$refs.fileInput
				let file = input.files
				const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]

				if (typeOfFile === "heic" || typeOfFile === "heif") {
					this.isPreloading()
					this.showToast(
						"warning",
						"top-right",
						"Cargando imagen, espere un momento",
						"CheckIcon",
						"El formato HEIF o HEIC no es soportado, por ello puede tardar un momento."
					)
					heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
						const url = URL.createObjectURL(newImage)
						let newFile = new File([newImage], "heic" + ".jpg", {
							type: "image/jpeg",
							lastModified: new Date().getTime(),
						})
						this.dataFile = {
							name: "name",
							data: newFile,
							type: "image",
							base: url,
							progress: 0,
							uploadFinished: false,
							extension: typeOfFile,
						}
						this.isPreloading(false)
					})
				} else {
					if (file && file[0]) {
						let reader = new FileReader()
						reader.onload = (e) => {
							this.dataFile = {
								name: file[0].name,
								data: file[0],
								type: "image",
								base: e.target.result,
								progress: 0,
								uploadFinished: false,
								extension: typeOfFile,
							}
						}
						reader.readAsDataURL(file[0])
					}
				}
				/* this.$refs.fileInput.value = null;
        // this.loading = false; */
				setTimeout(() => {
					this.loading = false
				}, 500)
			}
		},
		configReload() {
			this.videoCounterUploadCheck++
		},

		async searchSpecimens(search) {
			// this.isPreloading()

			try {
				if (!search || search.length === 0) {
					this.specimens = []
					return // Sale de la función para no hacer la solicitud
				}

				if (search.length >= 2) {
					const { data } = await IncidencesService.searchSpecimens({
						search: search,
					})
					this.specimens = data.data
				}
			} catch (error) {
				console.log(error)
			} finally {
				// this.isPreloading(false)
			}
		},
		async save() {
			if (!(await this.$refs.form.validate())) return

			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se creará una nueva incidencia.`,
			})
			if (!isConfirmed) return

			try {
				let dataS3 = null
				if (this.fileVideo.name != null) {
					this.uploadingVideo = true
					let lengthVideo = 0
					let offset = 0
					let chunkSize = 10000000
					if (this.fileVideo.data) {
						while (offset < this.fileVideo.data.size) {
							offset += chunkSize
							lengthVideo++
						}
						this.videoCounterUpload = this.videoCounterUpload + lengthVideo
					}

					const response = await this.uploadVideoLaravel(this.fileVideo.data, this.configReload)
					if (response.statusResponse == 500) {
						this.showToast(
							"danger",
							"top-right",
							"ERROR AL SUBIR VIDEO",
							"AlertCircleIcon",
							"ALGO SALIO MAL, VUELVA A INTENTAR POR FAVOR"
						)
						this.videoCounterUpload = 0
						this.videoCounterUploadCheck = 0
					}
					dataS3 = {
						url: response.url,
						fileName: response.fileName,
					}
				}
				const formData = new FormData()
				formData.append("specimen_id", this.specimen.specimen_id)
				formData.append("plate", this.specimen.plate)
				formData.append("description", this.description.toUpperCase())
				formData.append("evidence_image", this.dataFile != null ? this.dataFile.data : null)
				if (dataS3 != null) {
					formData.append("video_name", dataS3.fileName)
					formData.append("video_s3", dataS3.url)
				}
				this.isPreloading()
				const res = await IncidencesService.registerIncidence(formData)
				this.showSuccessToast("Incidencia registrado con exito.")
				this.$emit("refresh")
				this.handleHidden()
			} catch (error) {
				console.error(error)
			} finally {
				this.isPreloading(false)
			}
		},
		async setChunks() {},
	},
	watch: {},
	async mounted() {
		this.toggleModal("register-incidence-modal")
	},
}
</script>

<style lang="scss" scoped>
.select-incidence {
	::v-deep .vs__dropdown-toggle {
		height: 2.5rem !important;
	}
}

.container-video {
	height: 150px;
	width: 100%;
	position: relative;
	cursor: pointer;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		border-radius: 4px;
	}
	// border: 2px dashed var(--primary);
	border-radius: 0.5rem;

	.skeletor-video-content {
		border-radius: 0.5rem;
		border: 2px dashed var(--primary);
		background: #8095ff54;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.image-mov {
	width: 100% !important;
	height: 100% !important;
	object-fit: contain !important;
	object-position: center;
}
.grid-video {
	height: 150px;
}
.container-imagev2 {
	height: 150px !important;
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	.skeletor-image-content {
		cursor: pointer;
		position: absolute;
		border: 2px dashed var(--primary);
		height: 100%;
		width: 100%;
		background: #8095ff54;
		border-radius: 0.5rem;

		// display: flex;
		// align-items: center;
		// justify-content: center;
	}

	.image {
		// z-index: 100;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}

.btn-trash {
	position: absolute;
	top: 5px;
	right: 5px;
}

.btn-upload {
	position: absolute;
	top: 122px;
	right: 5px;
}
.btn-upload-video {
	position: absolute;
	top: 5px;
	right: 5px;
}
.barra {
	// background: #f50d0d;
	border-radius: 8px;
	position: relative;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	height: 24px;
	margin-bottom: 2rem;
	overflow: hidden;

	.back {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		// width: 40%;
		background: #7367f0;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		transition: 0.3 all ease-in-out;
	}
}

.add-opacity {
	opacity: 0.5;
}
</style>

<template>
	<div>
		<div v-if="hasTopes || hasPeleas || hasTorneos" class="card align-item-center p-1 flex-lg-row justify-content-md-between">
			<ul class="nav nav-tabs align-items-center gap-x-1" role="tabslits" style="margin-bottom: 0">
				<b-nav-item
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:active="tabIndex == 0"
					@click="tabIndex = 0"
					v-if="hasTopes"
				>
					<template>
						<span>TOPES</span>
						<b-badge
							pill
							variant="danger"
							class="ml-1"
							style="
								height: 24px;
								width: 24px;
								font-weight: bolder;
								display: flex;
								align-items: center;
								justify-content: center;
							"
						>
							{{ counter.topes }}
						</b-badge>
					</template>
				</b-nav-item>
				<b-nav-item
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:active="tabIndex == 1"
					@click="tabIndex = 1"
					v-if="hasPeleas"
				>
					<template>
						<span>PELEAS</span>
						<b-badge
							pill
							variant="danger"
							class="ml-1"
							style="
								height: 24px;
								width: 24px;
								font-weight: bolder;
								display: flex;
								align-items: center;
								justify-content: center;
							"
						>
							{{ counter.peleas }}
						</b-badge>
					</template>
				</b-nav-item>

				<b-nav-item
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:active="tabIndex == 2"
					@click="tabIndex = 2"
					v-if="hasTorneos"
				>
					<template>
						<span>TORNEOS</span>
						<b-badge
							pill
							variant="danger"
							class="ml-1"
							style="
								height: 24px;
								width: 24px;
								font-weight: bolder;
								display: flex;
								align-items: center;
								justify-content: center;
							"
						>
							{{ counter.torneos }}
						</b-badge>
					</template>
				</b-nav-item>
			</ul>
		</div>

		<b-tabs
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center"
			v-model="tabIndex"
			lazy
		>
			<b-tab>
				<VideoSpecimenList
					v-if="hasTopes && tabIndex == 0"
					:id_specimen="id_specimen"
					:tabCategory="1"
					@refresh="$emit('refresh'), getCounters()"
				/>
			</b-tab>
			<b-tab>
				<VideoSpecimenList
					v-if="hasPeleas && tabIndex == 1"
					:id_specimen="id_specimen"
					:tabCategory="2"
					@refresh="$emit('refresh'), getCounters()"
				/>
			</b-tab>
			<b-tab>
				<VideoSpecimenList
					v-if="hasTorneos && tabIndex == 2"
					:id_specimen="id_specimen"
					:tabCategory="3"
					@refresh="$emit('refresh'), getCounters()"
				/>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import VideosService from "@/services/videos.service"
import VideoSpecimenList from "@/views/amg/videolibrary/VideoSpecimenList.vue"
export default {
	name: "ContainerSpecimenListVideos",
	components: {
		VideoSpecimenList,
	},
	props: {
		id_specimen: {
			type: Number,
			required: true,
		},
	},
	created() {
		this.getCounters()
	},
	methods: {
		async getCounters() {
			const data = await VideosService.counterVideosSpecimen({
				specimen_id: this.id_specimen,
			})
			this.counter = data.counter
			if(this.counter.topes > 0 )this.tabIndex = 0
			else if(this.counter.peleas > 0 )this.tabIndex = 1
			else if(this.counter.torneos > 0 )this.tabIndex = 2
		},
	},
	computed: {
		hasTopes() {
			return this.counter && this.counter.topes > 0
		},
		hasPeleas() {
			return this.counter && this.counter.peleas > 0
		},
		hasTorneos() {
			return this.counter && this.counter.torneos > 0
		},
	},
	data() {
		return {
			counter: {},
			tabIndex: 0,
		}
	},
}
</script>

<template>
	<div>
		<BtnsSpecimensPage v-if="!isInvitado" />
		<b-tabs lazy @input="changeTab">
			<!-- <b-tab title="Listado">
				<SpecimensGrid />
			</b-tab> -->
			<b-tab>
				<template slot="title">
					<div class="m-05">
						Disponibles
						<b-badge v-if="numTab.available" :variant="tab == 0 ? 'primary' : 'light-primary'">
							{{ numTab.available }}
						</b-badge>
					</div>
				</template>
				<SpecimensGrid :idTab="1" />
			</b-tab>
			<b-tab>
				<template slot="title">
					<div class="m-05">
						No disponibles
						<b-badge v-if="numTab.not_available" :variant="tab == 1 ? 'primary' : 'light-primary'">
							{{ numTab.not_available }}
						</b-badge>
					</div>
				</template>
				<SpecimensGrid :idTab="2" />
			</b-tab>
			<b-tab title="Dashboard">
				<SpecimensDs />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import BtnsSpecimensPage from "./BtnsSpecimensPage.vue"
import SpecimensDs from "./components/mainDashboard/SpecimensDs.vue"
import SpecimensGrid from "./components/SpecimensGrid.vue"

export default {
	components: {
		BtnsSpecimensPage,
		SpecimensDs,
		SpecimensGrid,
	},
	data: () => ({
		tab: 0,
		numTab: {
			available: 0,
			not_available: 0,
		},
	}),
	computed: {},
	methods: {
		changeTab(event) {
			this.tab = event
			this.getSpecimensSeparatedCounter()
		},
		async getSpecimensSeparatedCounter() {
			const { data } = await axios.get("/api/specimens/counter-specimens-by-cat")
			data.map((it) => {
				if (it.in_galpon == 1) this.numTab.available = it.quantity
				if (it.in_galpon == 0) this.numTab.not_available = it.quantity
			})
			// this.counter = data[0].counter
		},
	},
}
</script>

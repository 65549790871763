<template>
	<div>
		<b-card class="p-1 mb-0" no-body>
			<b-row>
				<b-col>
					<b-input-group size="" prepend="Año" style="max-width: 15rem">
						<b-form-select
							text-field="years"
							value-field="years"
							:options="yearOpts"
							@input="getCounter"
							v-model="pYear"
						/>
					</b-input-group>
				</b-col>
				<b-col class="d-flex justify-content-end align-items-center">
					<div style="font-size: 1.4rem" class="d-flex align-center">
						<p class="mb-0">Ejemplares disponibles:</p>
						<p class="ml-05 mb-0 d-flex align-center" style="font-weight: 600">
							{{ counter || "---" }}
						</p>
					</div>
				</b-col>
			</b-row>
		</b-card>
		<hr />
		<b-row class="mb-2 gap-y-2">
			<b-col cols="12" lg="4">
				<div class="p-1 bg-white shadow rounded">
					<GenderChart :year="year" :info="genderInfo" :count="genderCount" />
				</div>
			</b-col>
			<b-col cols="12" lg="4">
				<div class="p-1 bg-white shadow rounded">
					<OriginChart :year="year" :info="originInfo" :count="originCount" />
				</div>
			</b-col>
			<b-col cols="12" lg="4">
				<div class="p-1 bg-white shadow rounded">
					<StatusChart :year="year" :info="statusInfo" :count="statusCount" />
				</div>
			</b-col>
		</b-row>
		<b-row class="mb-2 gap-y-2">
			<b-col cols="12" lg="6">
				<div class="p-1 bg-white shadow rounded">
					<CategoryChart :year="year" :info="categoryInfo" :count="categoryCount" />
				</div>
			</b-col>
			<b-col cols="12" lg="6">
				<div class="p-1 bg-white shadow rounded">
					<AgeChart :year="year" :info="ageInfo" :count="ageCount" />
				</div>
			</b-col>
		</b-row>
		<b-row class="mb-2 gap-y-2">
			<b-col cols="12" lg="6">
				<div class="p-1 bg-white shadow rounded">
					<DeadChart :year="year" :info="deadInfo" @refresh="getDeadData" :count="deadCount" />
				</div>
			</b-col>
			<b-col cols="12" lg="6">
				<div class="p-1 bg-white shadow rounded">
					<LineChart :year="year" :info="lineInfo" @refresh="getLineData" :count="lineCount" />
				</div>
			</b-col>
		</b-row>
		<b-row class="mb-2 gap-y-2">
			<b-col cols="12" lg="6">
				<div class="p-1 bg-white shadow rounded">
					<RaceChart :year="year" :info="raceInfo" @refresh="getRaceData" :count="raceCount" />
				</div>
			</b-col>
			<b-col cols="12" lg="6">
				<div class="p-1 bg-white shadow rounded">
					<ColorChart :year="year" :info="colorInfo" @refresh="getColorData" :count="colorCount" />
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import specimensService from "@/services/specimens.service"

import GenderChart from "./GenderChart.vue"
import OriginChart from "./OriginChart.vue"
import StatusChart from "./StatusChart.vue"
import CategoryChart from "./CategoryChart.vue"
import AgeChart from "./AgeChart.vue"
import DeadChart from "./DeadChart.vue"
import LineChart from "./LineChart.vue"
import RaceChart from "./RaceChart.vue"
import ColorChart from "./ColorChart.vue"

export default {
	components: {
		CategoryChart,
		GenderChart,
		OriginChart,
		StatusChart,
		AgeChart,
		DeadChart,
		LineChart,
		RaceChart,
		ColorChart,
	},
	data: () => ({
		isLoading: false,
		pYear: null,
		counter: null,
		yearOpts: [],
		//charts data
		genderInfo: null,
		colorInfo: null,
		categoryInfo: [],
		originInfo: [],
		statusInfo: [],
		ageInfo: [],
		deadInfo: null,
		lineInfo: null,
		raceInfo: null,
		//counts for refresh
		genderCount: 0,
		colorCount: 0,
		categoryCount: 0,
		originCount: 0,
		statusCount: 0,
		ageCount: 0,
		deadCount: 0,
		lineCount: 0,
		raceCount: 0,
	}),
	computed: {
		year() {
			const match = this.yearOpts.find((y) => y.years == this.pYear)
			if (!match) return {}
			return match
		},
	},
	methods: {
		async getYearOpts() {
			this.isLoading = true
			const { data } = await specimensService.getMainDsYears()
			this.yearOpts = data
			this.pYear = data[0].years

			this.isLoading = false
		},
		async getCounter() {
			this.genderCount++
			this.colorCount++
			this.categoryCount++
			this.originCount++
			this.statusCount++
			this.ageCount++
			this.deadCount++
			this.lineCount++
			this.raceCount++
			this.isLoading = true
			const { data } = await specimensService.getMainDsCounter({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
			})
			this.counter = data.counter
			this.isLoading = false
			await this.getAllData()
		},
		//data functions *************************************
		async getGenderData() {
			const { data } = await specimensService.getMainDsGender({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
			})
			this.genderInfo = data
		},
		async getColorData({ gender, page }) {
			const { data } = await specimensService.getMainDsColor({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
				gender: gender,
				page: page,
			})
			this.colorInfo = data
		},
		async getCategoryData() {
			const { data } = await specimensService.getMainDsCategory({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
			})
			this.categoryInfo = data
		},
		async getOriginData() {
			const { data } = await specimensService.getMainDsOrigin({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
			})
			this.originInfo = data
		},
		async getStatusData() {
			const { data } = await specimensService.getMainDsStatus({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
			})
			this.statusInfo = data
		},
		async getAgeData() {
			const { data } = await specimensService.getMainDsAge({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
			})
			this.ageInfo = data
		},
		async getDeadData({ page, gender }) {
			const { data } = await specimensService.getMainDsDead({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
				gender: gender,
				page: page,
			})
			this.deadInfo = data
		},
		async getLineData({ gender, percentage, page }) {
			const { data } = await specimensService.getMainDsLine({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
				gender: gender,
				percentage: percentage,
				page: page,
			})

			this.lineInfo = data
		},
		async getRaceData({ gender, percentage, page }) {
			const { data } = await specimensService.getMainDsRace({
				start_date: this.year.start_date,
				end_date: this.year.end_date,
				gender: gender,
				percentage: percentage,
				page: page,
			})

			this.raceInfo = data
		},
		async getAllData() {
			try {
				await Promise.all([
					this.getGenderData(),
					this.getColorData({ gender: 1, page: 1 }),
					this.getCategoryData(),
					this.getOriginData(),
					this.getStatusData(),
					this.getAgeData(),
					this.getDeadData({ page: 1, gender: null }),
					this.getLineData({ gender: null, percentage: 25, page: 1 }),
					this.getRaceData({ gender: null, percentage: 25, page: 1 }),
				])
			} catch (error) {
				console.error("Error loading data", error)
			}
		},
	},
	async created() {
		await this.getYearOpts()
	},
}
</script>

<style scoped></style>

<template>
	<div>
			<!-- <feather-icon
				class="cursor-pointer text-primary"
				@click="openModal"
				style="margin: 0 0 4px 4px"
				v-b-tooltip.hover
				title="Subir video"
			/> -->
            <b-button
                class="btn-icon"
                style="padding: 2px; margin: 0 0 4px 4px"
                variant="outline-primary"
                size="sm"
                title="Subir video"
                v-b-tooltip.hover
                @click="open = true"
				>
                <feather-icon class="cursor-pointer" size="16" icon="PlusIcon" />
            </b-button>

		<b-modal centered no-close-on-backdrop hide-footer size="sm" :title="`Subir video / ${info.local.alias || info.local.plate} vs ${!info.rival? 'Sin rival' : info.rival.name}`" v-model="open" @hide="beforeHidden">
			<b-overlay :show="isLoading">
				<ValidationObserver ref="form">
					<ValidationProvider rules="required">
						<b-form-group class="mt-1" label="Video">
							<b-form-file
								v-model="video.file"
								class="border-primary rounded text-left"
								placeholder="Seleccionar video"
								@input="uploadVideoItem(video.file)"
							/>

							<div class="bar-loading mt-1" v-if="video.file">
								<div
									class="bar"
									:style="{ width: `${video.upload}%` }"
									:class="
										video.upload != 100 ? 'bg-primary' : 'bg-success'
									"
								></div>
								<p
									class="text font-weight-bolder"
									:style="{
										color: video.upload > 50 ? '#fff' : '#7367f0',
									}"
								>
									{{
										video.upload != 100
											? `Subiendo ${video.upload}%`
											: "Subido"
									}}
								</p>
							</div>
						</b-form-group>
					</ValidationProvider>
				</ValidationObserver>
				<div class="d-flex justify-content-end">
					<button @click="send" class="btn btn-primary">Guardar</button>
				</div>
			</b-overlay>
		</b-modal>
	</div>
</template>

<script>

import tournamentService from "@/services/tournament/default.service"

export default {
	name: "UploadFightVideo",
	props: {
		info: Object,
	},
	data() {
		return {
			open: false,
			video: {
				upload: null,
				file: null
			},
			isLoading: false,
			videoUploaded: false
		}
	},
	methods: {
		async beforeHidden(e){
			if(this.videoUploaded){
				e.preventDefault()
				const { isConfirmed } = await this.showConfirmSwal({
						title: "Actualmente existe un video sin guardar",
						text: "¿Deseas salir de todas maneras?"
					})
				if(!isConfirmed){ return }
				this.handleHidden()
			}
			else{
				this.handleHidden()
			}
			// this.$emit("closing")
		},
		handleHidden(){
			this.video = {
				upload: null,
				file: null
			}
			this.videoUploaded = false
			this.isLoading = false
			this.open = false
		},
		async uploadVideoItem(file) {

			if (!file) return
			this.video.upload = 0
			this.isPreloading()

			let videoCounterUpload = 0
			let videoCounterUploadCheck = 0
			let lengthVideo = 0
			let offset = 0
			let chunkSize = 10000000
			if (file) {
				while (offset < file.size) {
					offset += chunkSize
					lengthVideo++
				}
				videoCounterUpload = videoCounterUpload + lengthVideo
			}

			const fn = () => {
				videoCounterUploadCheck++
				this.video.upload = (
					(videoCounterUploadCheck / videoCounterUpload) *
					100
				).toFixed(2)
				if (this.video.upload == 100) {
					this.isPreloading(false)
				}
			}
			const response = await this.uploadVideoLaravel(file, fn)

			if (response.statusResponse == 500) {
				this.showToast(
					"danger",
					"top-right",
					"ERROR AL SUBIR VIDEO",
					"AlertCircleIcon",
					"ALGO SALIO MAL, VUELVA A SELECCIONAR EL VIDEO POR FAVOR"
				)

				this.video.path = null
				this.video.name = null
				this.video.upload = 0
				this.video.file = null
				this.isPreloading(false)

				return
			} else {
				this.isPreloading(false)
				this.video.path = response.url
				this.video.name = response.fileName
				this.videoUploaded = true
			}
		},

		async send(){
			const success = await this.$refs.form.validate();
			if(!success){
				this.showToast(
				"warning",
				"top-right",
				"Videoteca",
				"AlertTriangleIcon",
				"Seleccione un video para continuar"
				)
				return
			}
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se registrara nuevo video para el ${this.info.category.toUpperCase()} <span class="font-weight-bolder">${this.info.local.alias || this.info.local.plate} vs ${this.info.rival.alias || this.info.rival.plate || this.info.rival.name}</span></p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			this.isPreloading()
			const response = await tournamentService.uploadVideoConfrontation([
				{
					path_s3: this.video.path,
					name: this.video.name,
					confrontation_id: this.info.confrontation_id
				}
			])
			this.handleHidden();
			this.$emit("refresh")
			this.isPreloading(false)
			this.showToast(
				"success",
				"top-right",
				"Videoteca",
				"CheckIcon",
				"Se han actualizado los videos exitosamente"
			)
		}


		// async send() {
		// 	if (!(await this.$refs.form.validate())) return

		// 	this.isLoading = true
		// 	const { isConfirmed } = await this.showConfirmSwal({
		// 		html: `
		// 			<p class="mb-0">Se registrara nuevo video para el ejemplar <span class="font-weight-bolder">${this.title}</span></p>`,
		// 	})
		// 	if (!isConfirmed) {
		// 		this.isLoading = false
		// 		return
		// 	}

		// 	const response = await this.uploadVideoLaravel(this.video, () => {})

		// 	if (response.statusResponse == 500) {
		// 		this.showToast(
		// 			"danger",
		// 			"top-right",
		// 			"ERROR AL SUBIR VIDEO",
		// 			"AlertCircleIcon",
		// 			"ALGO SALIO MAL, VUELVA A INTENTAR POR FAVOR"
		// 		)
		// 		this.isLoading = false
		// 		return
		// 	}

		// 	const payload = new FormData()
		// 	payload.set("id", this.info.id)
		// 	payload.set("name", response.fileName)

		// 	if (this.video) payload.set("path_s3", response.url)


		// 	// return;

		// 	this.showToast(
		// 		"success",
		// 		"top-right",
		// 		"Preparacion",
		// 		"AlertCircleIcon",
		// 		`Se esta subiendo el video, espere unos segundos`
		// 	)
		// 	// await preparacionService.insertTope(payload);

		// 	const { status, message } = await preparacionService.storeVideo(payload)


		// 	if (status) {
		// 		this.isLoading = false
		// 		this.open = false
		// 		this.$emit("refresh")
		// 	} else {
		// 		setTimeout(() => {
		// 			this.isLoading = false
		// 			this.open = false
		// 			this.$emit("refresh")
		// 			this.showToast(
		// 				"danger",
		// 				"top-right",
		// 				"Preparacion",
		// 				"AlertCircleIcon",
		// 				`No se pudo subir el video de forma correcta.`
		// 			)
		// 		}, 1200)
		// 	}
		// },
	},
}
</script>
<style lang="scss">
.bar-loading {
	background: #f5f5f5;
	width: 100%;
	height: 20px;
	border-radius: 4px;
	position: relative;
	overflow: hidden;
	.bar {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		// background: var(--primary);
		width: 0;
		transition: 0.4s all ease-in-out;
	}
	.text {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		bottom: 0;
		background: transparent;
		color: var(--primary);
		text-align: center;
		width: 100%;
		font-size: 12px;
		margin: 0;
	}
}
</style>
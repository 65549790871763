<template>
	<b-modal
		ref="modal-list-specimen-weight-tracking"
		hide-footer
		@hidden="$emit('hidden')"
		body-class="p-0"
		:title="`Tracking de Peso de ${specimenObj.plate}
     (${specimenObj.alias ? specimenObj.alias : ''})`"
		size="xlg"
	>
		<b-table
			:items="S_SPECIMEN_TRACKING_WEIGHTS"
			:fields="visibleFields"
			:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
			sticky-header="75vh"
			responsive
		>
			<template #cell(kg_weight)="data">
				<b-form-input
					v-model="data.item.tempKgWeight"
					@input="convertKilosToPounds(data.item)"
					v-if="data.item.isEditing"
					size="sm"
					class="text-center"
				/>
				<p v-else>
					{{ data.item.kg_weight }}
				</p>
			</template>
			<template #cell(pounds_weight)="data">
				<b-form-input
					v-model="data.item.tempPoundsWeight"
					disabled
					v-if="data.item.isEditing"
					size="sm"
					class="text-center"
				/>
				<p v-else>
					{{ data.item.pounds_weight }}
				</p>
			</template>

			<template #cell(type_weight_description)="data">
				<b-form-select
					v-if="data.item.isEditing"
					v-model="data.item.type_weight_id"
					:options="S_TYPE_WEIGHT_OPTIONS"
					value-field="id"
					text-field="description"
					class="text-center"
				/>
				<p v-else>
					{{ data.item.type_weight_description }}
				</p>
			</template>

			<template #cell(date_weight)="data">
				<flat-pickr
					v-if="data.item.isEditing"
					v-model="data.item.date_weight"
					placeholder="Elige una Fecha"
					class="form-control bg-transparent"
					:config="config"
				/>
				<p v-else>
					{{ data.item.date_weight | myGlobalDayWhour }}
				</p>
			</template>

			<template #cell(created_at)="data">
				{{ data.item.name }} {{ data.item.last_name }} <br />
				{{ data.item.created_at | myGlobalDayWhour }}
			</template>
			<template #cell(actions)="data">
				<template v-if="data.item.isEditing">
					<feather-icon
						icon="CheckIcon"
						class="text-success cursor-pointer mr-1"
						@click="updateTracking(data.item)"
					/>
					<feather-icon icon="XIcon" class="text-danger cursor-pointer" @click="closeEditing(data.item)" />
				</template>
				<feather-icon
					v-else-if="!isInvitado"
					icon="Edit2Icon"
					class="text-warning cursor-pointer"
					@click="editItem(data.item)"
				/>
			</template>
		</b-table>
	</b-modal>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import heic2any from "heic2any";
import { mapActions, mapState } from "vuex";
import modalMixin from "@/mixins/modal.js";
import SpecimenService from "@/services/specimens.service";
export default {
	components: { flatPickr },
	props: {
		specimenObj: Object,
	},
	async mounted() {
		this.isPreloading();
		await this.A_GET_SPECIMEN_TRACKING_WEIGHTS({
			specimen_id: this.specimenObj.id,
		});
		if (!this.S_TYPE_WEIGHT_OPTIONS.length) {
			await this.A_GET_TYPE_WEIGHT_OPTIONS();
		}

		if (!this.isAdmin) {
			this.columns[3].visible = false;
		} else {
			this.columns[3].visible = true;
		}
		this.toggleModal("modal-list-specimen-weight-tracking");
		this.isPreloading(false);
	},
	data() {
		return {
			config: {
				altInput: true,
				altFormat: "F j, Y",
				dateFormat: "Y-m-d",
				locale: Spanish,
			},
			columns: [
				{
					key: "kg_weight",
					label: "Peso (Kg)",
					class: "text-center",
					visible: true,
				},
				{
					key: "pounds_weight",
					label: "Peso (Libras)",
					class: "text-center",
					visible: true,
				},
				{
					key: "type_weight_description",
					label: "Motivacion",
					class: "text-center",
					visible: true,
				},
				{
					key: "date_weight",
					label: "Fecha",
					class: "text-center",
					visible: true,
				},
				{
					key: "created_at",
					label: "Ingresado por",
					class: "text-center",
					visible: true,
				},

				{
					key: "actions",
					label: "Acciones",
					class: "text-center",
					visible: true,
				},
			],
		};
	},
	computed: {
		visibleFields() {
			return this.columns.filter((column) => column.visible);
		},
		...mapState("weightSpecimen", ["S_TYPE_WEIGHT_OPTIONS", "S_SPECIMEN_TRACKING_WEIGHTS"]),
	},
	methods: {
		...mapActions("weightSpecimen", [
			"A_GET_TYPE_WEIGHT_OPTIONS",
			"A_SAVE_SPECIMEN_WEIGHT",
			"A_GET_SPECIMEN_TRACKING_WEIGHTS",
			"A_UPDATE_SPECIMEN_WEIGHT",
		]),
		onlyForKilos(event, tracking) {
			let keyCode = event.keyCode ? event.keyCode : event.which;
			// only allow number and one dot
			if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || tracking.tempKgWeight.indexOf(".") != -1)) {
				// 46 is dot
				event.preventDefault();
			}
			// restrict to 2 decimal places
			if (
				tracking.tempKgWeight != null &&
				tracking.tempKgWeight.indexOf(".") > -1 &&
				tracking.tempKgWeight.split(".")[1].length > 2
			) {
				event.preventDefault();
			}
		},
		convertKilosToPounds(tracking) {
			tracking.tempPoundsWeight = (tracking.tempKgWeight * 2.20462).toFixed(3);
		},
		editItem(tracking) {
			tracking.isEditing = true;
			tracking.tempKgWeight = tracking.kg_weight;
			tracking.tempPoundsWeight = tracking.pounds_weight;
		},
		closeEditing(tracking) {
			tracking.isEditing = false;
		},
		findNewDescriptionTypeWeight(typeWeightId) {
			return this.S_TYPE_WEIGHT_OPTIONS.find((typeWeight) => typeWeight.id === typeWeightId).description;
		},
		async updateTracking(tracking) {
			if(!tracking.tempKgWeight || !tracking.date_weight || !tracking.type_weight_id){
				this.showToast("danger",  "top-right", "Tracking de peso", "AlertTriangleIcon", 'Por favor, rellenar los campos faltantes');
				return
			}
			const success = await this.showConfirmSwal();
			if (success.value) {
				try {
					await this.A_UPDATE_SPECIMEN_WEIGHT(tracking);
					tracking.kg_weight = tracking.tempKgWeight;
					tracking.pounds_weight = tracking.tempPoundsWeight;
					tracking.type_weight_description = this.findNewDescriptionTypeWeight(tracking.type_weight_id);
					tracking.isEditing = false;
					this.$emit("update-specimen-weight", tracking.pounds_weight, tracking.kg_weight);
					this.showSuccessToast("Peso actualizado correctamente");
				} catch (error) {
					console.log(error)
				}
			}
		},
	},
	mixins: [modalMixin],
};
</script>

<style></style>
